require ('core-js');
require ('@include/login/globals');
require ('@common/platform.css');
require ('./login.css');

var $ = require ('striata-jquery');
var StriataError = require ('@control/error/striataerror').StriataError;
var BuildPostForm = require ('@utility/form/form');
var CreateFooter = require ('@project/common/footer').CreateFooter;


$(function()
{
	var stash = pageStash;

	CreateFooter ('#bottomContentDiv');

	$('#userName').focus();

	if (stash.sessionExpired)
	{
		$('#loginFormDivContent').addClass ('loginFormDivContentExpired');
	}

	function LoginOnEnter (event)
	{
		if (event.keyCode == 13) //ENTER
		{
			$("#loginFormSubmitButton").trigger ('click');
		}
	}

	$('#userName').on ('keydown', LoginOnEnter);
	$("#password").on ('keydown', LoginOnEnter);

	function LoginSuccess (result)
	{
		var revalidateParameters = $("#revalidate_parameters").val();
		var userName = $("#userName").val();
		var error = new StriataError();
		error.PopulateFromJson (result);
		$(this).ThrobberHide();

		if (error.numErrors == 0)
		{
			var sessionId = result.sessionId;
			$("#loginScreenErrorMessage").html ('&nbsp;');

			BuildPostForm (stash.login_action,
			{
				userName              : userName,
				dologin               : 1,
				newSessionId          : 1,
				sessionId             : sessionId,
				revalidate_parameters : revalidateParameters
			});
		}
		else
		{
			var errorString = error.ToString();

			$('#loginFormSubmitButton').removeClass ("disableLoginButton");
			$("#loginScreenErrorMessage").html (errorString);
			$("#csrf").val(result._csrf);
		}
	}

	function LoginFailure (result)
	{
		$(this).ThrobberHide();
		$('#loginFormSubmitButton').removeClass ("disableLoginButton");

		if (result.status == 0 && result.statusText == "error")
		{
			$("#loginScreenErrorMessage").html ("(500) " + stash.errors['500']);
		}
		else
		{
			$("#loginScreenErrorMessage").html (result.statusText + " " + result.status);
		}
	}

	$("#loginFormSubmitButton").on ('click', function (event)
	{
		// TODO: This is a horrible, we should not have a handler installed
		if ($(this).hasClass("disableLoginButton"))
		{
			return false;
		}

		var userName = $("#userName").val();
		var password = $("#password").val();
		var csrf = $("#csrf").val();
		var revalidateParameters = $("#revalidate_parameters").val();
		$('#loginFormSubmitButton').addClass ("disableLoginButton");

		$(this).Throbber();

		$.ajax (
			{
				type: "POST",
				url: "/platform/login/login/DoLogin",
				data:
				{
					showType: "json",
					userName: userName,
					password: password,
					csrf    : csrf,
					revalidate_parameters: revalidateParameters
				},
				success: LoginSuccess,
				error: LoginFailure
			}
		);
	});

	$("#loginFormForgottenPassword").on ('click', function (event)
	{
		var userName = $("#userName").val() || "";
		BuildPostForm ("/platform/login/login/ResetPassword",
			{
				userName: userName,
			}
		);
	});

	$("#loginFormSwitchUser").on ('click', function (event)
	{
		BuildPostForm ("/platform/login/login/ShowLogin",
			{
				Logout: 1,
				userName: ""
			}
		);
	});
});

