
// Import
var $ = require ('striata-jquery');
var StriataError = require ('@control/error/striataerror').StriataError;
var BuildPostForm = require ('@utility/form/form');
var LoginManager = require ('@login/loginmanager');
var AsyncFormPostRequestDispatchTypeTransport = require ('@control/requestdispatcher/asyncformpostrequestdispatchtype');


function RequestDispatchType()
{
	this.Initialise = function (settings)
	{
		this.data = settings.data;
		this.Success = settings.success;
		this.Error  = settings.error;
		this.context = settings.context;
		this.preventObjectStringification = settings.preventObjectStringification;
	};

	this.Dispatch = function()
	{
		throw new Error ("RequestDispatch.Dispatch is a virtual function that needs to be overriden");
	};
}


function AjaxRequestDispatchType (settings)
{
	this._SuperInitialise = this.Initialise;
	this.Initialise = function (settings)
	{
		this._SuperInitialise (settings);
		this.url = settings.url;
		this.type = settings.postType;
		this.preventObjectStringification = settings.preventObjectStringification;
	};

	this.Dispatch = function()
	{
		var ajaxRequest = $.ajax (
		{
			type        : this.type,
			url         : this.url,
			data        : this.data,
			success     : this.Success,
			error       : this.Error,
			context     : this.context,
			traditional : this.preventObjectStringification
		});

		return ajaxRequest;
	};
}
AjaxRequestDispatchType.prototype = new RequestDispatchType();
AjaxRequestDispatchType.prototype.constructor = AjaxRequestDispatchType;

function KillIframeForm()
{
	//code
	if (typeof (transport) != 'undefined')
	{
		transport.KillCurrentlyPostingIframe();
	}
}

function IframeFormPostRequestDispatchType()
{
	this._SuperInitialise = this.Initialise;
	this.Initialise = function (settings)
	{
		this._SuperInitialise (settings);
		this.url = settings.url; //the destination the form will post to
		this.formId = settings.formId; // id of form to be submitted.
		transport = new AsyncFormPostRequestDispatchTypeTransport();
		transport.Initialise ({formId: this.formId, url: this.url});
	};

	this.Dispatch = function (data)
	{
		Object.assign (this.data, data);
		return transport.Send ({
			formId  : this.formId,
			url     : this.url,
			data    : this.data,
			success : this.Success,
			error   : this.Error,
			context : this.context
		});
	};
}

IframeFormPostRequestDispatchType.prototype = new RequestDispatchType();
IframeFormPostRequestDispatchType.prototype.constructor = IframeFormPostRequestDispatchType;

function RequestDispatcher (settings)
{
	$.extend (settings.data,
	{
		'csrf'   : window.csrf,
		'pageId' : window.pageId,
	});

	this.SetRequestDispatchType = function (type)
	{
		if (type instanceof RequestDispatchType)
		{
			this.requestDispatchType = type;
		}
		else
		{
			throw new Error ("SetRequestDispatchType: type must an instance or descendant of RequestDispatchType");
		}
	};

	this.GetSessionExpired = function()
	{
		return this.sessionExpired;
	};

	this.SetSessionExpired = function (sessionExpired)
	{
		this.sessionExpired = sessionExpired;
	};

	this.GetRequestType = function()
	{
		return this.requestDispatchType;
	};

	function DoRequest()
	{
		if (this.BeforePost)
		{
			this.BeforePost();
		}

		var requestType = this.GetRequestType();
		requestType.Dispatch();
	}

	function DefaultSuccess (data, textStatus, XMLHttpRequest)
	{
		var self = this;
		var error = new StriataError();
		error.PopulateFromJson (data);

		if (error.numErrors  > 0)
		{
			for (var i = 0; i < error.numErrors; i++)
			{
				var currentError = error.errorMessages[i];
				if (currentError.errorCode == -1155)
				{
					var sessionExpired = 1;
					this.SetSessionExpired (sessionExpired);
				}
			}
		}
		if (this.sessionExpired == 1)
		{
			$(".pageHelpModal").hide(); // Need to remove all help because session expired
			$.fn.ThrobberHide();//Do this because of lag in throbbers that might not be hidden in expire.

			if (typeof this.sessionExpiredUrl === 'undefined')
			{
				window._csrf = data._csrf;
				var loginManager = new LoginManager (this.loginManagerDivId);
				if (this.BeforeShowLoginDialog)
				{
					this.BeforeShowLoginDialog();
				}
				loginManager.ShowLogin();
			}
			else
			{
				BuildPostForm (this.sessionExpiredUrl, this.sessionExpiredData);
			}
		}
		else
		{
			if (this.CustomSuccess !== null)
			{
				var customResult = self.CustomSuccess (data, textStatus, XMLHttpRequest);
				return customResult;
			}
		}
	}

	function DefaultError (jqXHR, textStatus, error)
	{
		var errorObject;
		if (jqXHR.status == 0 && textStatus == "error" && window.httpError)
		{
			errorObject = new StriataError();
			errorObject.AddErrorMessage ("1", "500", window.httpError, window.httpError);
			this.CustomError (errorObject);
		}
		else
		{
			if (this.CustomError !== null)
			{
				errorObject = new StriataError();
				errorObject.AddErrorMessage (1, jqXHR.status, error, error);
				this.CustomError (errorObject);
			}
		}
	}

	this.type = settings.type;
	this.url = settings.url;
	this.data = settings.data;
	this.loginManagerDivId = 'loginModal';

	this.preventObjectStringification = settings.preventObjectStringification || false;

	this.CustomSuccess = settings.success;
	this.CustomError = settings.error;
	this.BeforeShowLoginDialog = settings.beforeShowLoginDialog;
	this.BeforePost = settings.beforePost;

	this.sessionExpiredUrl = settings.sessionExpiredUrl;
	this.sessionExpiredData = settings.sessionExpiredData;
	this.sessionExpired = settings.sessionExpired || 0;

	this.DoRequest = DoRequest;
	this.Success = DefaultSuccess;
	this.Error = DefaultError;

	if (!((settings.defer) && (settings.defer == 1)))
	{
		var requestType = new AjaxRequestDispatchType();
		requestType.Initialise (
		{
			postType                     : this.type,
			preventObjectStringification : this.preventObjectStringification,
			url                          : this.url,
			data                         : this.data,
			success                      : this.Success,
			error                        : this.Error,
			context                      : this,
			sessionExpiredUrl            : this.sessionExpiredUrl,
			sessionExpiredData           : this.sessionExpiredData,
			sessionExpired               : this.sessionExpired
		});
		this.SetRequestDispatchType (requestType);
		this.DoRequest();
	}
}


// Export
module.exports.RequestDispatcher = RequestDispatcher;
module.exports.IframeFormPostRequestDispatchType = IframeFormPostRequestDispatchType;
module.exports.KillIframeForm = KillIframeForm;

