function RegisterComponent (templateElement, options)
{
	var componentContainer = options && options.parentElement ?
			options.parentElement :
			templateElement.parentElement;

	if (templateElement && !templateElement.content)
	{
		var templateNodes = templateElement.childNodes;
		var templateFragment = document.createDocumentFragment();

		while (templateNodes[0])
		{
			templateFragment.appendChild (templateNodes[0]);
		}

		templateElement.content = templateFragment;
	}

	if (templateElement && templateElement.content)
	{
		var templateElementClone = templateElement.cloneNode (true);

		if (templateElementClone && templateElementClone.content)
		{
			templateElement.parentElement.appendChild (templateElementClone);
			var component = templateElementClone.content.cloneNode (true);

			if (typeof componentContainer === "string")
			{
				componentContainer = document.getElementById (componentContainer);
			}

			componentContainer.appendChild (component);

			if (options && options.componentClass && component.children && component.children[0])
			{
				if (typeof options.componentClass == 'string')
				{
					component.children[0].classList.add (options.componentClass);
				}
				else if (Array.isArray (options.componentClass))
				{
					for (var className in options.componentClass)
					{
						component.children[0].classList.add (options.componentClass[className]);
					}
				}
			}
		}
	}
}

module.exports.RegisterComponent = RegisterComponent;
