
// Import
require ('./tags.css');

var TagElement = {
	selectOptions      : [],
	values             : [],
	readonly           : undefined,
	tagContainer       : undefined,
	tagInputElement    : undefined,
	selectTagElement   : undefined,
	showAddButton      : false,

	phrases          :
	{
		description     : "",
		editDescription : "",
	},

	Draw : function (config)
	{
		var tagLimit = config.limit || 0;
		var tagContainer = document.createElement ("div");
		tagContainer.setAttribute ("type", "tags");
		var tagContainerCount = document.querySelectorAll ("[name='tagValues]").length + 1;
		tagContainer.setAttribute ("id", "tagValues" + tagContainerCount);
		tagContainer.setAttribute ("name", "tagValues");
		tagContainer.setAttribute ("value", this.values.toString());
		tagContainer.setAttribute ("taglimit", tagLimit);
		tagContainer.style.overflow = "auto";

		if (config.name){ tagContainer.setAttribute ("tagname", config.name) }

		this.parentElement = config.parentElement;
		this.tagContainer = tagContainer;
		this.parentElement.appendChild (this.tagContainer);

		var currentSelectOptions = this.selectOptions;
		var placeholderText = this.phrases.description;

		if (!this.readonly)
		{
			if (this.parentElement.hasAttribute ("showAddButton"))
			{
				var addButton = document.createElement ("span");
				addButton.classList.add ("addTagButton");
				var addButtonContent = document.createElement ("span");
				addButtonContent.classList.add ("addTagDescription");
				addButtonContent.innerHTML = "+";
				addButton.appendChild (addButtonContent);
				this.parentElement.insertBefore (addButton, this.tagContainer);

				addButton.addEventListener ("click", function (event)
				{
					var currentTagContainer = event.target.parentElement.querySelectorAll ("[name='tagValues']")[0];

					if (currentTagContainer)
					{
						TagElement.DrawSelectElement (currentTagContainer, currentSelectOptions, placeholderText);
					}
				});
			}

			var tagInputElement = document.createElement ("input");
			tagInputElement.type = "text";

			if (TagElement.GetTagValues (tagContainer).length == 0)
			{
				tagInputElement.setAttribute ("placeholder", placeholderText);
			}

			tagInputElement.classList.add ("tagInputElement");
			tagInputElement.classList.add ("disabledFormCompare");

			this.tagInputElement = tagInputElement;
			this.tagContainer.appendChild (this.tagInputElement);
			TagElement.UpdateTagInputElement (this.tagInputElement);

			tagInputElement.addEventListener ("input", function (event)
			{
				if (event.target)
				{
					TagElement.DrawSelectElement (event.target, currentSelectOptions, placeholderText);
				}
			});

			tagContainer.addEventListener ("click", function (event)
			{
				var currentTagContainer = event.target.querySelector ("[name='tagValues']") ||
						event.target.closest ("[name='tagValues']");

				if (currentTagContainer)
				{
					TagElement.DrawSelectElement (currentTagContainer, currentSelectOptions, placeholderText);
				}
			});

			tagInputElement.addEventListener ("blur", function (event)
			{
				TagElement.RemoveSelectElement (event.target);
			});
		}

		document.addEventListener ("mousedown", function (event)
		{
			if (event.target.className !== "tagSelectorGroupOptions")
			{
				TagElement.RemoveSelectElement (event.target);
			}
		});


		for (var value in this.values)
		{
			if (this.values[value])
			{
				TagElement.DrawTagElement (config.parentElement, this.values[value], this.values[value], placeholderText);
			}
		}

		// Emulate 'change' event.
		var tagsObserver = new MutationObserver (function (mutations)
		{
			mutations.forEach (function (mutation)
			{
				if (mutation.type == 'childList' || mutation.type == 'attributes')
				{
					var nodeUpdated = false;

					for (var node in mutation.addedNodes)
					{
						if (mutation.addedNodes[node].nodeType == 1)
						{
							nodeUpdated = true;
						}
					}

					for (node in mutation.removedNodes)
					{
						if (mutation.removedNodes[node].nodeType == 1)
						{
							nodeUpdated = true;
						}
					}

					if (nodeUpdated)
					{
						var changeEvent = new Event ('change');
						mutation.target.dispatchEvent (changeEvent);
					}
				}
			});
		});

		var observerConfiguration = {attributes: true, childList: true, characterData: true};
		tagsObserver.observe (this.tagContainer, observerConfiguration);

		return this.tagContainer;
	},

	DrawSelectElement : function (targetElement, selectOptions, placeholderText)
	{
		var parentElement = targetElement.getAttribute ("name") == "tagValues" ? targetElement :
				targetElement.querySelector ("[name='tagValues']") || targetElement.closest ("[name='tagValues']");

		var selectTagLimit = parseInt (parentElement.getAttribute ("taglimit"));

		if (TagElement.GetTagValues (parentElement).length < selectTagLimit || selectTagLimit == 0)
		{
			var currentTagContainer = parentElement;
			var currentTagInputElement = parentElement.querySelector (".tagInputElement");

			if (!TagElement.selectOptions.length)
			{
				TagElement.selectOptions = selectOptions;
			}

			selectOptions = currentTagInputElement.value ?
					selectOptions.filter (option => option.name.toLowerCase().includes (currentTagInputElement.value.toLowerCase())) :
					selectOptions;

			TagElement.RemoveSelectElement();

			if (selectOptions.length > 0)
			{
				var containerElement;
				var selectElementGroup;
				var selectElementOption;

				var selectElement = document.createElement ("div");
				selectElement.classList.add ("tagSelector");
				this.selectTagElement = selectElement;
				currentTagContainer.parentElement.appendChild (selectElement);

				selectElement.addEventListener ("mouseover", function (event)
				{
					if (event.target.classList.contains ("tagSelectorOption"))
					{
						var groupOptions = document.querySelectorAll (".tagSelectorGroupOptions");

						for (var optionIndex = 0; optionIndex < groupOptions.length; ++optionIndex)
						{
							groupOptions[optionIndex].style.display = "none";
						}
					}
				});

				for (var optionIndex in selectOptions)
				{
					if (selectOptions[optionIndex].group)
					{
						var selectElementGroupOptionsName = selectOptions[optionIndex].group.name;
						selectElementGroup = selectElement.querySelector ("[name='" + selectElementGroupOptionsName + "']");

						if (!selectElementGroup)
						{
							selectElementGroup = document.createElement ("div");
							selectElementGroup.classList.add ("tagSelectorGroup");
							selectElementGroup.setAttribute ("name", selectElementGroupOptionsName);
							selectElementGroup.innerText = selectOptions[optionIndex].group.description;
						}

						var selectElementGroupOptions = selectElement.parentElement.querySelectorAll ("[name='" + selectElementGroupOptionsName + "Options']")[0];

						if (!selectElementGroupOptions)
						{
							selectElementGroupOptions = document.createElement ("div");
							selectElementGroupOptions.classList.add ("tagSelectorGroupOptions");
							selectElementGroupOptions.setAttribute ("name", selectElementGroupOptionsName + "Options");
							selectElementGroupOptions.style.top = selectElement.offsetTop + "px";
							selectElementGroupOptions.style.display = "none";
							selectElementGroupOptions.style.left = selectElement.offsetLeft + selectElement.offsetWidth + "px";
							selectElement.parentElement.appendChild (selectElementGroupOptions);
						}

						containerElement = selectElementGroupOptions;

						selectElementGroup.addEventListener ("mouseover", function (event)
						{
							var groupOptions = document.querySelectorAll (".tagSelectorGroupOptions");

							for (var optionIndex = 0; optionIndex < groupOptions.length; ++optionIndex)
							{
								groupOptions[optionIndex].style.display = "none";
							}

							var selectElementGroupName = event.target.getAttribute ("name");

							groupOptions = document.querySelectorAll ("[name='" + selectElementGroupName + "Options']")[0];
							event.target.style.backgroundColor = "#DDDDDD";

							if (groupOptions)
							{
								groupOptions.style.display = "inline-block";
								groupOptions.style.top = selectElement.offsetTop + event.target.offsetTop + "px";
							}
						});

						selectElementGroup.addEventListener ("mouseout", function (event)
						{
							event.target.style.backgroundColor = "#EEEEEE";
						});

						document.addEventListener ("mousedown", function (event)
						{
							var groupOptions = document.querySelectorAll (".tagSelectorGroupOptions");

							if (event.target.className !== "tagSelectorGroupOptions")
							{
								for (var optionIndex = 0; optionIndex < groupOptions.length; ++optionIndex)
								{
									groupOptions[optionIndex].parentElement.removeChild (groupOptions[optionIndex]);
								}
							}
						});

						selectElementGroup.style.cursor = "pointer";
						selectElement.appendChild (selectElementGroup);

						selectElementOption = document.createElement ("div");
						selectElementOption.classList.add ("tagSelectorOption");
						selectElementOption.setAttribute ("group", selectElementGroupOptionsName);
						selectElementOption.innerText = selectOptions[optionIndex].name;
						selectElementOption.value = selectOptions[optionIndex].value;

						TagElement.AddSelectEventListeners (currentTagContainer, selectElementOption, currentTagInputElement, placeholderText);
						containerElement.appendChild (selectElementOption);
					}
					else
					{
						selectElementOption = document.createElement ("div");
						selectElementOption.classList.add ("tagSelectorOption");
						selectElementOption.innerText = selectOptions[optionIndex].name;
						selectElementOption.value = selectOptions[optionIndex].value;

						TagElement.AddSelectEventListeners (currentTagContainer, selectElementOption, currentTagInputElement, placeholderText);
						selectElement.appendChild (selectElementOption);
					}
				}

				if (document.body.scrollHeight <= selectElement.offsetTop + selectElement.offsetHeight)
				{
					var selectElementHeight = (selectElement.offsetTop + selectElement.offsetHeight) - document.body.scrollHeight;
					selectElement.style.top = selectElement.offsetTop - selectElementHeight - currentTagContainer.offsetHeight + "px";
				}
			}
		}
	},

	AddSelectEventListeners : function (currentTagContainer, selectElementOption, currentTagInputElement, placeholderText)
	{
		if (currentTagContainer && TagElement.GetTagValues (currentTagContainer) &&
		((TagElement.GetTagValues (currentTagContainer).indexOf (selectElementOption.value) >= 0) === false))
		{
			selectElementOption.addEventListener ("mouseover", function (event)
			{
				event.target.style.backgroundColor = "#DDDDDD";
			});

			selectElementOption.addEventListener ("mouseleave", function (event)
			{
				event.target.style.backgroundColor = "#EEEEEE";
			});

			selectElementOption.addEventListener ("mousedown", function (event)
			{
				TagElement.DrawTagElement (currentTagContainer, event.target.innerText, event.target.value, placeholderText);
				currentTagContainer.setAttribute ("value", TagElement.GetTagValues (currentTagContainer));
				currentTagInputElement.value = "";
			});

			selectElementOption.style.cursor = "pointer";
		}
		else
		{
			selectElementOption.style.color = "#AAAAAA";
		}
	},

	RemoveSelectElement : function (targetElement)
	{
		var currentSelectElement = document.body.querySelectorAll (".tagSelector")[0];

		if (currentSelectElement && !currentSelectElement.contains (targetElement))
		{
			currentSelectElement.parentElement.removeChild (currentSelectElement);
		}
	},

	DrawTagElement : function (targetElement, name, value, placeholderText)
	{
		var currentTagContainer = targetElement.querySelector ("[name='tagValues']");
		var currentTagInputElement = targetElement.querySelector (".tagInputElement");

		if (currentTagInputElement)
		{
			currentTagInputElement.setAttribute ("value", "");
		}

		if (TagElement.GetTagValues (currentTagContainer) &&
				((TagElement.GetTagValues (currentTagContainer).indexOf (targetElement.value) >= 0) === false))
		{
			var tagValue = value || targetElement.value;
			var tagName = name;
			var colour = "#DADADA";

			for (var option in TagElement.selectOptions)
			{
				if (this.selectOptions[option].value == tagValue)
				{
					if (TagElement.selectOptions[option].group && TagElement.selectOptions[option].group.colour)
					{
						colour = TagElement.selectOptions[option].group.colour;
					}

					tagName = TagElement.selectOptions[option].name;
				}
			}

			var tagElement = document.createElement ("div");
			tagElement.classList.add ("tagElement");
			tagElement.setAttribute ("value", tagValue);
			tagElement.setAttribute ("type", "tag");

			if (colour)
			{
				tagElement.style.backgroundColor = colour;
			}

			var tagDescription = document.createElement ("span");
			tagDescription.classList.add ("tagDescription");
			tagDescription.innerText = tagName;
			tagElement.appendChild (tagDescription);

			if (!this.readonly)
			{
				var tagButton = document.createElement ("span");
				tagButton.classList.add ("tagButton");

				tagButton.addEventListener ("click", function (event)
				{
					var clickTagContainer = event.target.querySelector ("[name='tagValues']") ||
					event.target.closest ("[name='tagValues']");

					var currentTagInputElement = clickTagContainer.parentElement.querySelector (".tagInputElement") ||
					event.target.closest (".tagInputElement");

					clickTagContainer.removeChild (event.target.parentElement);
					clickTagContainer.setAttribute ("value", TagElement.GetTagValues (clickTagContainer));

					if (currentTagInputElement)
					{
						currentTagInputElement.focus();
					}

					if (TagElement.GetTagValues (clickTagContainer).length > 0)
					{
						currentTagInputElement.setAttribute ("placeholder", "");
					}
					else
					{
						currentTagInputElement.setAttribute ("placeholder", placeholderText);
					}

					TagElement.UpdateTagInputElement (currentTagInputElement);
				});

				tagElement.appendChild (tagButton);
			}

			if (this.selectTagElement && this.selectTagElement.parentElement)
			{
				this.selectTagElement.parentElement.removeChild (this.selectTagElement);
			}

			if (currentTagInputElement)
			{
				currentTagInputElement.parentElement.insertBefore (tagElement, currentTagInputElement);
				TagElement.UpdateTagInputElement (currentTagInputElement);
			}
			else
			{
				currentTagContainer.appendChild (tagElement);
			}
		}

		if (currentTagInputElement)
		{
			currentTagInputElement.setAttribute ("placeholder", "");
		}
	},

	UpdateTagInputElement : function (targetElement)
	{
		(function()
		{
			if (!this.readonly)
			{
				ExpandTagInputElement();
			}
		})();

		function ExpandTagInputElement()
		{
			var leftMargin = 0;
			var targetTagContainer = targetElement.querySelectorAll ("[name='tagValues']")[0] || targetElement;

			if (targetTagContainer)
			{
				var targetInputElement = targetTagContainer.querySelectorAll (".tagInputElement")[0];

				for (var child in targetTagContainer.children)
				{
					if (targetTagContainer.children[child].nodeType == 1 &&
							targetTagContainer.children[child].getAttribute ("type") == "tag" ||
							(targetTagContainer.children[child].classList))
					{
						leftMargin = leftMargin + targetTagContainer.children[child].offsetWidth + 5;
					}
				}

				if (targetInputElement)
				{
					var rightMargin = targetTagContainer.offsetWidth - leftMargin > 0 ?
							targetTagContainer.offsetWidth - leftMargin : 10;

					targetInputElement.style.width = rightMargin + "px";
				}
			}
		}
	},

	GetTagValues : function (element)
	{
		var tagValues = [];
		if (!element){ return tagValues }

		element = element.getAttribute ("name") == "tagValues" ? element :
				element.querySelector ("[name='tagValues']") || element.closest ("[name='tagValues']");

		for (var node in element.childNodes)
		{
			if (element.childNodes[node] && element.childNodes[node].nodeType == 1)
			{
				if (element.childNodes[node].getAttribute ("value"))
				{
					tagValues.push (element.childNodes[node].getAttribute ("value"));
				}
			}
		}

		return tagValues;
	}
};


// Export
module.exports = TagElement;

