
// FIXME: Introduce globals for legacy code that isn't bundled yet!
require ('@control/blockheading/blockheading');
require ('@control/dialog/jquery.modal');
require ('@control/inlinenotification/inlinenotification');
require ('@help/helpbuttons');

window.$ = require ('striata-jquery');
window.BuildPostForm = require ('@utility/form/form');
window.MonitorFormChange = require ('@control/formchangemanager/jquery.formchangemanager').MonitorFormChange;
window.RequestDispatcher = require ('@control/requestdispatcher/requestdispatcher').RequestDispatcher;
window.StriataError = require ('@control/error/striataerror').StriataError;
window.UpdateFormDetails = require ('@control/formchangemanager/jquery.formchangemanager').UpdateFormDetails;

