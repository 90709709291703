
// Import
if (typeof module !== 'undefined' && module.exports)
{
	var $ = require ('striata-jquery');
	var glossary_help = require ('./glossaryvalues');
}

$(function()
{
	$('body').append ("<span id='glossaryTip' style='display:none;'></span>");
});

// Bind mouseenter & mouseleave events to all glossaryText classed elements
// and cause a tooltip to display when the mouse enters.
function BindEventsForGlossary()
{
	$('.glossaryText').on ('mouseenter', function(e)
	{
		var glossaryId = $(this).attr('id');
		var id = $(this).attr('name');
		var text = glossary_help[id];

		var position = $('#' + glossaryId).offset();
		$('#glossaryTip').show();
		$('#glossaryTip').html(text);

		$('#glossaryTip').css(
		{
			'position': 'absolute',
			'top' : position.top + 14,
			'left': position.left + 12
		});

		$('#glossaryTip').css ({'width': 'auto'});
		if ($('#glossaryTip').width() > 300)
		{
			$('#glossaryTip').css ({'width': '300px'});
		}
	});

	$('.glossaryText').on ('mouseleave', function(e)
	{
		$('#glossaryTip').hide();
	});
}


// Export
if (typeof module !== 'undefined' && module.exports)
{
	module.exports = BindEventsForGlossary;
}

