// Import
var $ = require ('striata-jquery');


function ConfirmationModal (options)
{
	this.modalOptions = {
		id:           'confirmationModal',
		heading:      null,
		icon:         null,
		width:        null
	};

	Object.assign (this.modalOptions, options);

	if (document.getElementById (this.modalOptions.id))
	{
		var modal = document.getElementById (this.modalOptions.id);
		modal.parentNode.removeChild (modal);
	}

	CreateConfirmationModal (this.modalOptions);

	$("#mainDiv").Modal ("#"+this.modalOptions.id,"#confirmModalCloseCancel, #confirmModalCloseCreateXButton",
	{
		"position":    "right",
		"offsetTop":   0,
		"offsetSides": 0
	});

	function CreateConfirmationModal (options)
	{
		var modalDiv = document.createElement ("div");
		modalDiv.id = options.id;
		modalDiv.style.width = options.width;
		document.getElementById ("mainDiv").appendChild (modalDiv);

		var modalHeader = document.createElement ("div");
		modalHeader.className = "modalHeader";
		document.getElementById (modalDiv.id).appendChild (modalHeader);

		var modalHeaderImage = document.createElement ("img");
		modalHeaderImage.src = options.icon;
		modalHeader.appendChild (modalHeaderImage);

		var modalTitle = document.createElement ("span");
		modalTitle.className = "modalTitle";
		modalTitle.innerHTML = options.heading;
		modalTitle.style.padding = "0px 0px 0px 10px";
		modalHeader.appendChild (modalTitle);

		var modalHeaderClose = document.createElement ("div");
		modalHeaderClose.id = "confirmModalCloseCreateXButton";
		modalHeaderClose.className = "modalClose resetPage";
		modalHeader.appendChild (modalHeaderClose);

		var modalContainer = document.createElement ("div");
		modalContainer.className = "modalContent";
		modalContainer.style.padding = "10px 10px 10px 10px";

		var modalNoticeContainer = document.createElement ("div");
		modalNoticeContainer.id = options.id + "NoticeContainer";
		modalContainer.appendChild (modalNoticeContainer);

		var modalFooterContainer = document.createElement ("div");
		modalFooterContainer.style.height = "50px";

		var modalButtonContainer = document.createElement ("div");
		modalButtonContainer.id = options.id + "NoticeButtonContainer";
		modalButtonContainer.style.margin = "20px 0px 10px 0px";
		modalButtonContainer.style.float = "left";
		modalFooterContainer.appendChild (modalButtonContainer);
		modalContainer.appendChild (modalFooterContainer);

		document.getElementById (modalDiv.id).appendChild (modalContainer);
	}
}



ConfirmationModal.prototype.AddButton = function (options)
{
	this.buttonOptions = {
		id       : null,
		value    : '',
		enabled  : false,
		theme    : null,
		callback : function(){}
	};

	Object.assign (this.buttonOptions, options);

	var modalButton = document.createElement ("input");
	modalButton.type = "button";
	modalButton.id = this.buttonOptions.id;
	modalButton.value = this.buttonOptions.value;
	modalButton.style.margin = "0px 5px 0px 0px";

	if (this.buttonOptions.enabled == true)
	{
		modalButton.className = "enabledButton " + this.buttonOptions.theme;
	}
	else
	{
		modalButton.className = "disabledButton " + this.buttonOptions.theme;
	}

	document.getElementById (this.modalOptions.id + "NoticeButtonContainer").appendChild (modalButton);
	$("#" + modalButton.id).on ('click', this.buttonOptions.callback);
	$("#" + modalButton.id).on ('keydown', function (event)
		{
			if (event.keyCode == 13)
			{
				this.buttonOptions.callback();
			}
		}
	);
};


// Export
module.exports = ConfirmationModal;

