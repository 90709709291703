
// Import
var $ = require ('striata-jquery');
var StriataError = require ('@control/error/striataerror').StriataError;
var UpdateFormDetails = require ('@control/formchangemanager/jquery.formchangemanager.js').UpdateFormDetails;
var ChangeButtonStateWhenFormChanges = require ('@control/formchangemanager/jquery.formchangemanager.js').ChangeButtonStateWhenFormChanges;
var GetFormCheckListing = require ('@control/formchangemanager/jquery.formchangemanager.js').GetFormCheckListing;
var GetControlsInElement = require ('@control/form/control').GetControlsInElement;
var RequestDispatcher = require ('@control/requestdispatcher/requestdispatcher').RequestDispatcher;
var IframeFormPostRequestDispatchType = require ('@control/requestdispatcher/requestdispatcher').IframeFormPostRequestDispatchType;



function FormInteractionManager (configOptions)
{
	var self = this;

	var postType = configOptions.postType || "POST";
	var postUrl = configOptions.postUrl;
	var useIframePost = configOptions.useIframePost || false;
	var useSimulatedPost = configOptions.useSimulatedPost || false;

	var isModal = configOptions.isModal || false;
	var enableButtonStateChange = configOptions.enableButtonStateChange || false;

	var form = configOptions.formElement;
	var formSubmit = configOptions.formSubmitElement;
	var notificationContainer = configOptions.notificationElement;

	var formId = configOptions.formId;
	var formSubmitButtonId = configOptions.formSubmitButtonId;
	var notificationContainerId = configOptions.notificationContainerId;

	var notificationContainerEnabled = configOptions.notificationContainerId || false;

	var discardPhrase = configOptions.discardPhrase || false;

	var systemErrorPhrase = configOptions.systemErrorPhrase;
	var revalidate = configOptions.revalidate;

	var defaultButtonState;
	var formElement = form ? $(form) : $('#' + formId);
	var formSubmitButton = formSubmit ? $(formSubmit) : $("#" + formSubmitButtonId);
	var notificationElement = notificationContainer ? $(notificationContainer) : $("#" + notificationContainerId);

	var formReference = form ? form : formId;
	var formSubmitButtonReference = formSubmit ? formSubmit : formSubmitButtonId;

	var buttonCollection = formElement.find (":submit");
	buttonCollection = $.merge (buttonCollection, formElement.find (":button"));

	if (typeof revalidate == 'undefined')
	{
		revalidate = 1;
	}

	if (formSubmitButton.hasClass ("enabledButton"))
	{
		defaultButtonState = "enabled";
	}

	// Convert permission value passed by the substitution engine (ie. $subst) into a boolean value.
	var hasEditPermission = false;
	if (configOptions.hasEditPermission == "1")
	{
		hasEditPermission = true;
	}

	formElement.on ('submit', function()
	{
		for (var buttonIndex = 0; buttonIndex < buttonCollection.length; ++buttonIndex)
		{
			if (!$(buttonCollection[buttonIndex]).prop ("disabled"))
			{
				$(buttonCollection[buttonIndex]).trigger ('click');
			}
		}

		return false;
	});

	// Handle the leave form action
	$("#YesSaveButton").on ('click', function()
	{
		if (formSubmitButton.hasClass ("soloButton"))
		{
			formSubmitButton.removeClass ('enabledButton');
			formSubmitButton.addClass ('disabledButton');
			formSubmitButton.prop ('disabled', true);
		}
	});

	/*
	TODO: Not the right layer!
	$(".divVisibilityManagerNavButton").on ("click", function()
	{
		if (!$(this).hasClass ('disabledButton'))
		{
			HideNotification();
		}
	});
	*/

	// Setup the form compare to enable/disable the save button based on changes to the form
	// Only initialize form compare once per form instance.

	if (!GetFormCheckListing())
	{
		$(window).InitFormCompare();
	}

	if (discardPhrase)
	{
		$(window).AddFormCompare (formReference, discardPhrase);
	}

	if (!isModal || enableButtonStateChange)
	{
		var HideCallBack = function(){};

		if (!notificationContainerEnabled)
		{
			HideCallBack = HideNotification;
		}

		ChangeButtonStateWhenFormChanges (formReference, formSubmitButtonReference, HideCallBack);
	}



	function HideNotification (buttonElement)
	{
		// hide the inline notification icon
		if (notificationElement)
		{
			notificationElement.inlineNotification ("hide", {"delayTime": ""});
		}
	}



	this.ValidateForm = function ()
	{
		//run validations on the form.
		var validated = true;
		var formIsValidated = $('form[id="' + formId + '"]').validationEngine ("validate");
		var formElement = document.forms[formId];
		var controls = GetControlsInElement (formElement);

		if (formIsValidated == false)
		{
			validated = false;
		}

		controls.forEach (function (item)
			{
				if (!item.Validate())
				{
					validated = false;
				}
			}
		);

		return validated;
	};



	// Modify the url as required
	this.SetUrl = function (newUrl)
	{
		postUrl = newUrl;
	};

	this.GetUrl = function()
	{
		return postUrl;
	};



	// Modify the useIframePost as required
	this.SetUseIframe = function (useIframeValue)
	{
		useIframePost = useIframeValue;
	};

	this.GetUseIframe = function()
	{
		return useIframePost;
	};



	// This method handles posting of forms based on whether an iframe is used or not.
	this.SubmitForm = function (submitOptions)
	{
		var fileInputElements = document.getElementById (formId).querySelectorAll ("input[type='file']");
		useIframePost = fileInputElements.length > 0 ? true : useIframePost;

		var submitPermitted = false;

		for (var buttonIndex = 0; buttonIndex < buttonCollection.length; ++buttonIndex)
		{
			if (!submitPermitted && $(buttonCollection[buttonIndex]).hasClass ("enabledButton"))
			{
				submitPermitted = true;
			}
		}

		if (!submitPermitted)
		{
			return false;
		}

		if (useSimulatedPost === true)
		{
			this.SubmitSimulatedForm (submitOptions);
		}
		else if (useIframePost === true)
		{
			this.SubmitUploadForm (submitOptions);
		}
		else
		{
			this.SubmitAjaxForm (submitOptions);
		}

		return true;
	};



	// This method handles posting of forms that contain an upload component
	this.SubmitUploadForm = function (submitOptions)
	{
		// Validate form before posting.
		$.fn.ThrobberHide();

		if (!this.ValidateForm())
		{
			return false;
		}

		if (submitOptions.data)
		{
			Object.assign (submitOptions.data, {csrf: window.csrf});
			Object.assign (submitOptions.data, {pageId: window.pageId});
		}

		var submitUploadFormCustomCallbackObject = self.InitializeCustomCallbacks (submitOptions);
		var submitUploadFormObject =
		{
			url    : postUrl,
			formId : formId,
			data   : submitOptions.data
		};

		Object.assign (submitUploadFormObject, submitUploadFormCustomCallbackObject);

		if (hasEditPermission)
		{
			if (submitOptions.submitEvent && submitOptions.submitEvent.target)
			{
				formSubmitButtonId = submitOptions.submitEvent.target.id;
			}

			self.EnableFormSaveButton (formSubmitButton, false);

			if (notificationElement)
			{
				// Show the loading spinner icon before the rest of the code executes.
				notificationElement.inlineNotification ("show", {"status": "loading"});
			}

			// Submit the form.
			if (revalidate ==  1)
			{
				new RequestDispatcher (
				{
					type                  : "POST",
					url                   : "/platform/login/login/RevalidateLogin",
					data                  :
					{
						showType : 'json'
					},
					success               : function (data, textStatus, XMLHttpRequest)
					{
						$.fn.ThrobberHide();

						var error = new StriataError();
						error.PopulateFromJson (data);

						if (error.numErrors > 0)
						{
							error.DisplayInlineNotificationError (notificationContainerId);
							self.EnableFormSaveButton (formSubmitButton, true);
							$.fn.ThrobberHide();
						}
						else
						{
							self.DisableButtonsOnPost (true);

							var requestType = new IframeFormPostRequestDispatchType();
							requestType.Initialise (submitUploadFormObject);
							var dispatcher = new RequestDispatcher ({defer: 1});
							dispatcher.SetRequestDispatchType (requestType);
							dispatcher.DoRequest();
						}
					},
					error                 : function (data, textStatus, XMLHttpRequest)
					{
						$.fn.ThrobberHide();

						var error = new StriataError();
						error.PopulateFromJson (data);

						error.DisplayInlineNotificationError (notificationContainerId);
						self.EnableFormSaveButton (formSubmitButton, true);
						$.fn.ThrobberHide();
						return submitUploadFormCustomCallbackObject.error (data, textStatus, XMLHttpRequest);
					},
					beforeShowLoginDialog : function()
					{
						// Turn on the save buttons
						self.EnableFormSaveButton (formSubmitButton, true);
						$.fn.ThrobberHide();

						// Remove the busy spinner
						if (notificationElement)
						{
							notificationElement.inlineNotification ("hide", {"delayTime": ""});
						}
					}
				});
			}
			else
			{
				var requestType = new IframeFormPostRequestDispatchType();
				requestType.Initialise (submitUploadFormObject);
				var dispatcher = new RequestDispatcher ({defer: 1});
				dispatcher.SetRequestDispatchType (requestType);
				dispatcher.DoRequest();
			}
		}

		// Method to execute once the AJAX process has completed/exited.
		formSubmitButton.ajaxComplete (function (event, xhr, settings)
		{
			// Handle session expiry.
			self.CheckSessionExpired (xhr);
		});

		//TODO: make an ajaxComplete type event for this.
		return true;
	};


	// This method handles posting of ajax forms.
	this.SubmitAjaxForm = function (submitOptions)
	{
		if (!this.ValidateForm())
		{
			return false;
		}

		if (submitOptions.data)
		{
			Object.assign (submitOptions.data, {showType: "json"});
			Object.assign (submitOptions.data, {csrf: window.csrf});
			Object.assign (submitOptions.data, {pageId: window.pageId});
		}

		var submitAjaxFormCustomCallbackObject = self.InitializeCustomCallbacks (submitOptions);
		var submitAjaxFormObject =
		{
			type: postType,
			url: postUrl,
			data: submitOptions.data
		};
		Object.assign (submitAjaxFormObject, submitAjaxFormCustomCallbackObject);

		// Submit the form.
		if (revalidate ==  1)
		{
			new RequestDispatcher (
			{
				type                  : "POST",
				url                   : "/platform/login/login/RevalidateLogin",
				data                  :
				{
					showType : 'json'
				},
				success               : function (data, textStatus, XMLHttpRequest)
				{
					$.fn.ThrobberHide();

					var error = new StriataError();
					error.PopulateFromJson (data);

					if (error.numErrors > 0)
					{
						error.DisplayInlineNotificationError (notificationContainerId);
						self.EnableFormSaveButton (formSubmitButton, true);
						$.fn.ThrobberHide();
					}
					else
					{
						if (hasEditPermission)
						{
							if (submitOptions.submitEvent && submitOptions.submitEvent.target)
							{
								formSubmitButtonId = submitOptions.submitEvent.target.id;
							}

							self.EnableFormSaveButton (formSubmitButton, false);

							var submitFormRequestDispatcher = new RequestDispatcher (submitAjaxFormObject);

							// Method to execute once the AJAX process has completed/exited.
							$(document).ajaxComplete (function (event, msg)
							{
								if (submitFormRequestDispatcher.sessionExpired == 1)
								{
									self.DisableButtonsOnPost (false);
								}
							});

							self.DisableButtonsOnPost (true);
							$.fn.ThrobberHide();
						}
					}
				},
				error                 : function (data, textStatus, XMLHttpRequest)
				{
					$.fn.ThrobberHide();

					var error = new StriataError();
					error.PopulateFromJson (data);

					error.DisplayInlineNotificationError (notificationContainerId);
					self.EnableFormSaveButton (formSubmitButton, true);
					$.fn.ThrobberHide();
				},
				beforeShowLoginDialog : function()
				{
					// Turn on the save buttons
					self.EnableFormSaveButton (formSubmitButton, true);
					$.fn.ThrobberHide();

					// Remove the busy spinner
					if (notificationElement)
					{
						notificationElement.inlineNotification ("hide", {"delayTime": ""});
					}
				}
			});
		}
		else
		{
			if (hasEditPermission)
			{
				if (submitOptions.submitEvent && submitOptions.submitEvent.target)
				{
					formSubmitButtonId = submitOptions.submitEvent.target.id;
				}

				self.EnableFormSaveButton (formSubmitButton, false);

				var submitFormRequestDispatcher = new RequestDispatcher (submitAjaxFormObject);

				// Method to execute once the AJAX process has completed/exited.
				$(document).ajaxComplete (function (event, msg)
				{
					if (submitFormRequestDispatcher.sessionExpired == 1)
					{
						self.DisableButtonsOnPost (false);
					}
				});

				self.DisableButtonsOnPost (true);
				$.fn.ThrobberHide();
			}
		}

		return true;
	};


	// This method simulates posting of ajax forms.
	this.SubmitSimulatedForm = function (submitOptions)
	{
		if (!this.ValidateForm())
		{
			return false;
		}

		var submitSimulatedFormObject = self.InitializeCustomCallbacks (submitOptions);

		if (hasEditPermission)
		{
			if (submitOptions.submitEvent && submitOptions.submitEvent.target)
			{
				formSubmitButtonId = submitOptions.submitEvent.target.id;
			}

			self.EnableFormSaveButton (formSubmitButton, false);

			self.DisableButtonsOnPost (true);
			submitSimulatedFormObject.success ({numErrors : null});
		}

		return true;
	};


	// Initialize and configure custom callbacks.
	this.InitializeCustomCallbacks = function (submitOptions)
	{
		var customBeforePostInitCallback = submitOptions.beforePostInit || function(){};
		var customSuccessInitCallback = submitOptions.successInit || function(){};
		var customErrorInitCallback = submitOptions.errorInit || function(){};

		var customBeforePostCallback = submitOptions.beforePost || function(){};
		var customSuccessCallback = submitOptions.success || function(){};
		var customErrorCallback = submitOptions.error || function(){};

		var submitFormCustomCallbackObject =
		{
			beforePost : function()
			{
				customBeforePostInitCallback(); // Custom function to call before default code execution.
				self.DefaultBeforePost();
				return customBeforePostCallback(); // Custom function to call after default code execution.
			},
			success: function (msg, textStatus, XMLHttpRequest)
			{
				customSuccessInitCallback (msg, textStatus, XMLHttpRequest); // Custom function to call before default code execution.
				self.DefaultSuccess (msg, textStatus, XMLHttpRequest);
				return customSuccessCallback (msg, textStatus, XMLHttpRequest); // Custom function to call after default code execution.
			},
			error: function (msg, textStatus, XMLHttpRequest)
			{
				customErrorInitCallback (msg, textStatus, XMLHttpRequest); // Custom function to call before default code execution.
				self.DefaultError (msg, textStatus, XMLHttpRequest);
				return customErrorCallback (msg, textStatus, XMLHttpRequest); // Custom function to call after default code execution.
			}
		};

		return submitFormCustomCallbackObject;
	};



	this.DefaultBeforePost = function()
	{
		// If the form is in a modal show a page spinner.
		if (isModal == true)
		{
			$(this).Throbber();
		}

		// Show the loading spinner icon before the rest of the code executes.
		if (notificationElement)
		{
			notificationElement.inlineNotification ("show", {"status": "loading"});
		}

		this.EnableFormSaveButton (formSubmitButton, false);
		formElement.validationEngine ('hideAll');
	};



	this.DefaultSuccess = function (msg, textStatus, XMLHttpRequest)
	{
		$.fn.ThrobberHide();

		var error = new StriataError();
		error.PopulateFromJson (msg);

		var handleAsWarning = false;

		if (error.errorMessages.length == 1 && error.errorMessages[0].errorCode == "-3373") // file size warning.
		{
			handleAsWarning = true;
		}

		var errorListMessages = "<ul>";

		// Draw up a list of errors to display in the inline notification.
		for (var i = 0; i < error.numErrors; i++)
		{
			// Create a list of error messages.
			var currentError = msg.errorMessages[i];
			var currentErrorCode = msg.errorMessages[i].errorCode;
			var currentErrorDescription = currentError.errorDescription || currentError.errorNarrative;
			var currentErrorService = "";

			if (msg.errorMessages[i].errorParameter && msg.errorMessages[i].errorParameter.service)
			{
				currentErrorService = " (" + msg.errorMessages[i].errorParameter.service + ")";
			}

			currentError = "(" + currentErrorCode + ") " + currentErrorDescription + currentErrorService;
			errorListMessages += "<li>" + currentError + "</li>";
		}

		errorListMessages += "</ul>";

		if (error.numErrors > 0 && !handleAsWarning)
		{
			this.EnableFormSaveButton (formSubmitButton, true);
			$.fn.ThrobberHide();
			self.DisableButtonsOnPost (false);

			// Show the inline notification error icon
			if (notificationElement)
			{
				notificationElement.inlineNotification ("show", {"status": "error", "message": errorListMessages});
				self.EnableFormSaveButton (formSubmitButton, true);
				$.fn.ThrobberHide();
			}
		}
		else
		{
			if (discardPhrase){ UpdateFormDetails (formReference) }

			// Show the success icon.
			if (notificationElement)
			{
				if (handleAsWarning)
				{
					notificationElement.inlineNotification ("show", {"status": "warning", "message": errorListMessages});
				}
				else
				{
					notificationElement.inlineNotification ("show", {"status": "success"});
				}

				var currentForm = formReference.id ? formReference : document.getElementById (formReference);

				currentForm.addEventListener ('formchanged', function()
				{
					notificationElement.inlineNotification ("hide", {"delayTime": ""});
				});
			}

			this.EnableFormSaveButton (formSubmitButton, false);
			self.DisableButtonsOnPost (false);
		}

		if (defaultButtonState == "enabled")
		{
			formSubmitButton.removeClass ('disabledButton');
			formSubmitButton.addClass ('enabledButton');
			formSubmitButton.prop ('disabled', false);
		}
	};



	this.DefaultError = function (msg, textStatus, XMLHttpRequest)
	{
		var errorMessage;
		self.DisableButtonsOnPost (false);

		// Show the error icon after the code has executed.
		if (notificationElement && msg.ToHtml)
		{
			if (systemErrorPhrase)
			{
				errorMessage = "<ul><li>" + systemErrorPhrase + "</li><li>" + msg.ToHtml() + "</li></ul>";
			}
			else
			{
				errorMessage = msg.ToHtml();
			}

		}
		else if (msg.errorLevel && msg.errorCode && msg.errorDescription)
		{
			errorMessage = "(" + msg.errorCode + ") " + msg.errorDescription;
		}

		notificationElement.inlineNotification ("show", {"status": "error", "message": errorMessage});
		self.EnableFormSaveButton (formSubmitButton, true);
		$.fn.ThrobberHide();
	};



	this.CheckSessionExpired = function (msg)
	{
		// Ensure that the AJAX response is a valid JSON object.
		try
		{
			JSON.parse (msg.responseText);
		}
		catch (jsonError)
		{
			return false;
		}

		var sessionExpiredError = JSON.parse (msg.responseText);

		if (sessionExpiredError.numErrors  > 0)
		{
			for (var i = 0; i < sessionExpiredError.numErrors; i++)
			{
				var currentError = sessionExpiredError.errorMessages[i];
				if (currentError.errorCode == -1155) //Error code for session expiry.
				{
					if (notificationElement)
					{
						notificationElement.inlineNotification ("show", {"status": "error", "message": currentError.errorNarrative});
					}
					this.EnableFormSaveButton (formSubmitButton, true);
					$.fn.ThrobberHide();
					self.DisableButtonsOnPost (false);
					return false;
				}
			}
		}
	};



	// Receive a boolean value and enable/disable the save button depending on that value.
	this.EnableFormSaveButton = function (submitButton, isEnabled)
	{
		if (hasEditPermission == true && isEnabled == true)
		{
			// On modals make the POST button style consistent with the buttons in its group.
			if (isModal == true)
			{
				submitButton.removeClass ('disabledButton');
				submitButton.addClass ('enabledButton');
				submitButton.prop ('disabled', false);
			}
			else
			{
				submitButton.removeClass ('temporarilyDisabledMarker');
				submitButton.removeClass ('disabledButton');
				submitButton.addClass ('enabledButton');
				submitButton.prop ('disabled', false);
			}
		}
		else
		{
			submitButton.removeClass ('enabledButton');
			submitButton.addClass ('disabledButton');
			submitButton.prop ('disabled', true);
		}
	};



	// Receive a boolean value and enable/disable grouped navigation buttons depending on that value.
	this.DisableButtonsOnPost = function (disableButtonsOnPost)
	{
		if (disableButtonsOnPost == true)
		{
			$('.groupButton').each (function (index)
			{
				if (!$(this).hasClass ('enabledButton'))
				{
					if (!isModal)
					{
						$(this).addClass ('temporarilyDisabledMarker');
					}

					$(this).addClass ('disabledButton');
					$(this).addClass ('persistDisabled');
				}
				else
				{
					if (!isModal)
					{
						$(this).addClass ('temporarilyDisabledMarker');
					}

					$(this).addClass ('disabledButton');
				}
			});
		}
		else
		{
			$('.groupButton').each (function()
			{
				if ($(this).hasClass ('enabledButton'))
				{
					$(this).removeClass ('disabledButton');
					$(this).prop ('disabled', false);

					if (!isModal)
					{
						$(this).removeClass ('temporarilyDisabledMarker');
					}
				}

				if ($(this).hasClass ('disabledButton'))
				{
					$(this).removeClass ('enabledButton');
					$(this).removeClass ('persistDisabled');

					if (!isModal)
					{
						$(this).removeClass ('temporarilyDisabledMarker');
					}

					$(this).addClass ('disabledButton');
				}

				if (!$(this).hasClass ('persistDisabled'))
				{
					$(this).prop ('disabled', false);
				}
			});
		}
	};
}


// Export
module.exports = FormInteractionManager;

