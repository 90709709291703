//OPTIONS
/*
ajax: if it is bound to the ajax event
delay: time delay before displaying
image: the actual throbber to display
modal: must we pop up a modal overlay
display: center (Middle of the page), id(display the throbber in an div id), location (top, left, right, bottom)
elementId: this is the element to bind the throbber to
offsetSides: used with specific positioning
offsetTop: used with specific positioning
opacity: Here you have the ability to control the modal window over a elements opcaity
*/

// Import
var jQuery = require ('striata-jquery');
var HighestZIndex = require ('@utility/utility').HighestZIndex;

var bigSpinner = require ('./bigspinner.png').default;


(function ($)
{
	var displayElement;
	var spinnerInterval;

	var defaultOptions = {
		ajax            : false,
		delay           : 0,
		image           : bigSpinner,
		modal           : false,
		display         : null,
		elementId       : "",
		backgroundColor : "#333333",
		opacity         : "0.4",
		lastSpinner     : false
	};

	var preloadedSpinnerImage = document.createElement ("img");
	preloadedSpinnerImage.src = defaultOptions.image;

	var ThrobberShow = function (options)
	{
		var element = options.display;
		var spinnerImage = preloadedSpinnerImage;

		spinnerImage.className = "spinnerImage";
		spinnerImage.style.position = "absolute";
		spinnerImage.style.opacity = "0.6";
		spinnerImage.style.width = "66px";
		spinnerImage.style.height = "66px";

		if (options.ajax && $.active == 0)
		{
			//here we said its true on ajax but ajax loaded faster than we could display the throbber
			return false;
		}

		if (element)
		{
			if (element instanceof Object)
			{
				displayElement = $(element);
			}
			else if (element.indexOf ("id:") == 0)
			{
				displayElement = $('#' + element.substr (3));
			}
			else
			{
				displayElement = $('#' + element);
			}
		}
		else
		{
			displayElement = $('body');
		}

		if (displayElement.find (".throbber").length > 0)
		{
			return false;
		}

		//here we might have a body tag option
		if (typeof (options.bodyId) != 'undefined' && options.bodyId != '')
		{
			$("body").attr ("id", options.bodyId);
		}

		//here we are displaying over we need to get the z-index of the object we showing over
		var overlayWidth = displayElement.width();
		var overlayHeight = displayElement.height();
		var overlayPosition = displayElement.offset();
		var offsetParent = displayElement.offsetParent();
		var offsetParentPosition = {top: 0, left: 0};
		var docElem = (offsetParent.ownerDocument || window.document).documentElement;
		if ($.contains (docElem, offsetParent[0]))
		{
			offsetParentPosition = offsetParent.offset();
			overlayPosition.top = overlayPosition.top - offsetParentPosition.top;
			overlayPosition.left = overlayPosition.left - offsetParentPosition.left;
		}

		var spinnerOverlay = document.createElement ("div");
		spinnerOverlay.className = "throbber";

		if (displayElement.prop ('nodeName') == "BODY")
		{
			var zIndex = HighestZIndex();
			spinnerOverlay.style.zIndex = zIndex + 100;

			spinnerOverlay.style.width = "100%";
			spinnerOverlay.style.height = "100%";

			spinnerOverlay.style.top = "0px";
			spinnerOverlay.style.left = "0px";

			spinnerOverlay.style.position = "fixed";
			spinnerOverlay.style.overflow = "hidden";
			spinnerOverlay.style.backgroundColor = options.backgroundColor;
			spinnerOverlay.style.opacity = options.opacity;
		}
		else
		{
			spinnerOverlay.style.width = overlayWidth + "px";
			spinnerOverlay.style.height = overlayHeight + "px";
			spinnerOverlay.style.position = "absolute";
			spinnerOverlay.style.overflow = "hidden";
			spinnerOverlay.style.backgroundColor = "#777777";
			spinnerOverlay.style.opacity = "0.2";
		}

		if (displayElement.css ("position") == "static")
		{
			var borderLeft = (displayElement.outerWidth() - displayElement.innerWidth()) / 2;
			var paddingLeft = (displayElement.innerWidth() - displayElement.width()) / 2;

			var borderTop = (displayElement.outerHeight() - displayElement.innerHeight()) / 2;
			var paddingTop = (displayElement.innerHeight() - displayElement.height()) / 2;

			var overlayLeft = overlayPosition.left + borderLeft + paddingLeft;
			var overlayTop = overlayPosition.top + borderTop + paddingTop;

			spinnerOverlay.style.left = overlayLeft + "px";
			spinnerOverlay.style.top = overlayTop + "px";

			var overlayVisibleHeight = overlayHeight;

			if (overlayVisibleHeight > window.innerHeight)
			{
				overlayVisibleHeight = window.innerHeight - (displayElement.offset().top - $(window).scrollTop());
			}

			spinnerImage.style.left = overlayLeft + (overlayWidth / 2) - ($(spinnerImage).width() / 2) + "px";
			spinnerImage.style.top = overlayTop + (overlayVisibleHeight / 2) - ($(spinnerImage).height() / 2) + "px";
		}
		else
		{
			spinnerOverlay.style.left = "0px";
			spinnerOverlay.style.top = "0px";

			spinnerImage.style.left = (overlayWidth / 2) - ($(spinnerImage).height() / 2) + "px";
			spinnerImage.style.top = (overlayHeight / 2) - ($(spinnerImage).height() / 2) + "px";
		}

		if (spinnerImage)
		{
			var spinDegree = 0;

			if (displayElement.prop ('nodeName') == "BODY")
			{
				$(spinnerImage).css ("position", "fixed");
			}

			spinnerInterval = setInterval (function()
			{
				$(".spinnerImage").css (
				{
					"transform" : "rotate("+ spinDegree + "deg)"
				});

				spinDegree += 6;

				if (spinDegree >= 360)
				{
					spinDegree = 0;
				}

			}, 20);
		}

		displayElement.append (spinnerOverlay);
		displayElement.append (spinnerImage);
	};

	// Add throbbers to a set of elements
	$.fn.Throbber = function (event, options)
	{
		if (typeof event == "undefined")
		{
			// Called as throbber()
			event = "click";
			options = {};
		}
		else if (typeof event == "object")
		{
			// Called as throbber(options)
			options = event;
			event = "click";
		}
		else if (typeof options == "undefined")
		{
			// Called as throbber(event)
			options = {};
		}

		options = Object.assign ({}, defaultOptions, options);

		$(function()
		{
			ThrobberShow (options);
		});

		return $(this);
	};

	$.fn.ThrobberHide = function()
	{
		$(function()
		{
			var throbberParentElement = displayElement || $("body");
			throbberParentElement.find (".throbber").remove();
			throbberParentElement.find (".spinnerImage").remove();

			if (spinnerInterval)
			{
				clearInterval (spinnerInterval);
			}
		});
	};

	$.fn.ThrobberHideSpecific = function (element)
	{
		$(function()
		{
			if (element instanceof Object)
			{
				element = $(element);
			}
			else if (element.indexOf ("#") == 0)
			{
				element = $(element);
			}
			else
			{
				element = $('#' + element);
			}

			if (element)
			{
				element.find (".throbber").remove();
				element.find (".spinnerImage").remove();

				if (spinnerInterval)
				{
					clearInterval (spinnerInterval);
				}
			}
		});
	};
})(jQuery);

