// Import
var $ = require ('striata-jquery');
var HandleHiddenSections = require ('@utility/form/generateforms').HandleHiddenSections;

function ModalGenerator (options)
{
	this.modalOptions = {
		id            : null,
		heading       : '',
		icon          : '',
		helpTextId    : null,
		width         : '500px',
		hasForm       : true,
		closeCallBack : function(){}
	};

	this.hiddenSections = [];

	Object.assign (this.modalOptions, options);

	var modal = document.getElementById (this.modalOptions.id);
	if (modal)
	{
		modal.parentNode.removeChild (modal);
	}

	CreateModal (this.modalOptions);

	function CreateModal (options)
	{
		var modalDiv = document.createElement ("div");
		modalDiv.id = options.id;
		modalDiv.className = "generatedModal";
		modalDiv.style.width = options.width;
		document.getElementById ("mainDiv").appendChild (modalDiv);

		var modalHeader = document.createElement ("div");
		modalHeader.className = "modalHeader";
		document.getElementById (options.id).appendChild (modalHeader);

		var modalHeaderImage = document.createElement ("img");
		modalHeaderImage.src = options.icon;
		modalHeaderImage.style.verticalAlign = "middle";
		modalHeader.appendChild (modalHeaderImage);

		var modalTitle = document.createElement ("span");
		modalTitle.className = "modalTitle";
		modalTitle.innerHTML = options.heading;
		modalTitle.style.padding = "0px 0px 0px 10px";
		modalHeader.appendChild (modalTitle);

		var modalHeaderClose = document.createElement ("div");
		modalHeaderClose.id = "modalCloseCreateXButton" + options.id;
		modalHeaderClose.className = "modalClose resetPage";
		modalHeader.appendChild (modalHeaderClose);

		modalHeaderClose.onclick = function (event)
		{
			options.closeCallBack (event);
		};

		if (options.helpTextId)
		{
			var helpTextIcon;

			if (document.getElementById (options.helpTextId))
			{
				helpTextIcon = $("#" + options.helpTextId).clone (true);
				$("#" + options.helpTextId).id = options.helpTextId + "_hidden";
			}
			else
			{
				helpTextIcon = $("#" + options.helpTextId + "_hidden").clone (true);
				helpTextIcon.id = options.helpTextId;
			}

			$(modalHeader).append (helpTextIcon);
		}

		var modalContainer = document.createElement ("div");
		modalContainer.className = "modalContentNoPadding";
		modalContainer.style.padding = "10px 10px 10px 10px";

		if (options.hasForm)
		{
			var modalForm = document.createElement ("form");
			modalForm.id = options.id + "Form";
			modalForm.enctype = "multipart/form-data";
			modalForm.method = "post";

			modalForm.appendChild (modalContainer);
		}

		var modalFormContainer = document.createElement ("div");
		modalFormContainer.id = options.id + "Container";
		modalContainer.appendChild (modalFormContainer);

		var modalFooterContainer = document.createElement ("div");
		modalFooterContainer.style.minHeight = "1%";
		modalFooterContainer.style.marginTop = "20px";
		modalFooterContainer.style.overflow = "hidden";
		modalFooterContainer.style.display = "inline-block";
		modalFooterContainer.style.verticalAlign = "middle";

		var modalButtonContainer = document.createElement ("div");
		modalButtonContainer.id = "modalButtonContainer" + options.id;
		modalButtonContainer.style.float = "left";
		modalButtonContainer.style.display = "inline";
		modalFooterContainer.appendChild (modalButtonContainer);

		var modalNotificationContainer = document.createElement ("div");
		modalNotificationContainer.id = "notificationContainer" + options.id;
		modalNotificationContainer.style.display = "inline";
		modalFooterContainer.appendChild (modalNotificationContainer);

		modalContainer.appendChild (modalFooterContainer);

		if (options.hasForm)
		{
			document.getElementById (options.id).appendChild (modalForm);
		}
		else
		{
			document.getElementById (options.id).appendChild (modalContainer);
		}
	}
}



ModalGenerator.prototype.AddSection = function (options)
{
	this.sectionOptions = {
		id       : null,
		heading  : '',
		parentId : null
	};

	Object.assign (this.sectionOptions, options);

	var modalSection = document.createElement ("div");
	modalSection.id = this.sectionOptions.id;

	var parentElementId = this.sectionOptions.parentId ? this.sectionOptions.parentId : this.modalOptions.id + "Container";

	document.getElementById (parentElementId).appendChild (modalSection);

	if (this.sectionOptions.heading)
	{
		$("#" + this.sectionOptions.id).headerizer ({heading: this.sectionOptions.heading, baseClass: 'headerizerHeadingLight'});
	}

	if (this.sectionOptions.conditions)
	{
		this.hiddenSections.push (
		{
			section     : modalSection,
			conditions  : this.sectionOptions.conditions
		});

		HandleHiddenSections (this.hiddenSections, { parent : document.getElementById (this.modalOptions.id + "Container")});
	}

	return $("#" + this.sectionOptions.id)[0];
};



ModalGenerator.prototype.AddButtonGroup = function (options)
{
	this.buttonGroupOptions = {
		id    : null,
		float : "left"
	};

	$.extend (this.buttonGroupOptions, options);

	var newModalFooterContainer = document.createElement ("div");
	newModalFooterContainer.style.minHeight = "1%";
	newModalFooterContainer.style.marginTop = "20px";
	newModalFooterContainer.style.overflow = "hidden";
	newModalFooterContainer.style.display = "inline-block";
	newModalFooterContainer.style.verticalAlign = "middle";
	newModalFooterContainer.style.float = this.buttonGroupOptions.float;

	var modalButtonContainer = document.createElement ("div");
	modalButtonContainer.id = "modalButtonContainer" + this.buttonGroupOptions.id;
	modalButtonContainer.style.display = "inline";
	newModalFooterContainer.appendChild (modalButtonContainer);

	var modalNotificationContainer = document.createElement ("div");
	modalNotificationContainer.id = "notificationContainer" + this.buttonGroupOptions.id;
	modalNotificationContainer.style.display = "inline";
	newModalFooterContainer.appendChild (modalNotificationContainer);

	var modalContainer = document.getElementById (this.modalOptions.id).querySelectorAll (".modalContentNoPadding")[0];
	modalContainer.appendChild (newModalFooterContainer);
};



ModalGenerator.prototype.RemoveSection = function (options)
{
	this.sectionOptions = {
		id		: null,
		heading	: ''
	};

	Object.assign (this.sectionOptions, options);

	if (document.getElementById (this.sectionOptions.id))
	{
		document.getElementById (this.sectionOptions.id).remove();
	}
};



ModalGenerator.prototype.AddButton = function (options)
{
	this.buttonOptions = {
		id      : null,
		value	: '',
		enabled	: false,
		group   : null,
		callback: function(){}
	};

	Object.assign (this.buttonOptions, options);

	var modalButton = document.createElement ("input");
	modalButton.type = "button";
	modalButton.id = this.buttonOptions.id;
	modalButton.value = this.buttonOptions.value;
	modalButton.style.margin = "0px 5px 0px 0px";

	if (this.buttonOptions.enabled == true)
	{
		modalButton.className = "groupButton enabledButton";
	}
	else
	{
		modalButton.className = "groupButton disabledButton";
	}

	var buttonGroup = document.getElementById ("modalButtonContainer" + this.modalOptions.id);

	if (this.buttonOptions.group)
	{
		buttonGroup = document.getElementById ("modalButtonContainer" + this.buttonOptions.group);
	}

	buttonGroup.appendChild (modalButton);

	if (this.buttonOptions.callback)
	{
		$("#" + modalButton.id).on ('click', this.buttonOptions.callback);
		$("#" + modalButton.id).on ('keydown', function (event)
			{
				if (event.keyCode == 13)
				{
					this.buttonOptions.callback();
				}
			}
		);
	}

	return modalButton;
};



ModalGenerator.prototype.Generate = function (options)
{
	var closeEvents;

	this.generateOptions =
	{
		disableCloseEvents: false
	};

	Object.assign (this.generateOptions, options);

	if (!this.generateOptions.disableCloseEvents)
	{
		closeEvents = "#modalCloseCancel" + this.modalOptions.id + ", #modalCloseCreateXButton" + this.modalOptions.id;
	}
	else
	{
		closeEvents = "";
	}

	$("#mainDiv").Modal ("#" + this.modalOptions.id, closeEvents,
	{
		"position"		: "right",
		"offsetTop" 	: 0,
		"offsetSides" 	: 0
	});
};



ModalGenerator.prototype.Remove = function()
{
	$("#" + this.modalOptions.id).remove();
};



ModalGenerator.prototype.ParseModalFormData = function()
{
	var dataObject = {};

	if (this.modalOptions)
	{
		var formData = $("#" + this.modalOptions.id + " :input").serializeArray();

		for (var i = 0; i < formData.length; ++i)
		{
			var formDataKey = formData[i].name;
			dataObject[formDataKey] = formData[i].value;
		}
	}

	return dataObject;
};


// Export
module.exports = ModalGenerator;

