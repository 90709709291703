// Import
var $ = require ('striata-jquery');
var Calendar = require ('@external/jscalendar/calendar');
var HighestZIndex = require ('@utility/utility').HighestZIndex;

function ConvertTimestamp (timestamp)
{
	//getFullYear, getDate , getSeconds etc all return LocalTime converted numbers, month 0-11
	var date = new Date (timestamp * 1000);
	var string = '';
	string += date.getFullYear();
	var month = date.getMonth() + 1;
	string += '-' + ((month > 9)?month:"0" + month);
	var day = date.getDate();
	string += '-' + ((day > 9)?day:"0" + day);
	var hours = date.getHours();
	string += ' ' + ((hours > 9)?hours:"0" + hours);
	var minutes = date.getMinutes();
	string += ':' + ((minutes > 9)?minutes:"0" + minutes);
	return string;
}
//the following function wants dateStr in "%Y-%m-%d %H:%M:%S" format

function ConvertDateStringToTimeStamp (dateString)
{
	var testPattern = /^\d\d\d\d-\d\d-\d\d\s\d\d:\d\d:?(\d\d)?/;
	if ( undefined == dateString.match(testPattern) )
	{
		throw new Error ("control/date/date.js::ConvertDateStringToTimeStamp(): invalid date format");
	}
	var datePart = dateString.split (" ")[0];

		var year	= datePart.split ("-")[0];
		var month	= datePart.split ("-")[1];
		var day		= datePart.split ("-")[2];

		var timePart= dateString.split (" ")[1];

		var hours	= timePart.split (":")[0];
		var minutes	= timePart.split (":")[1];

		var date = new Date();
		date.setFullYear (year);
		date.setMonth (month - 1);
		date.setDate (day);
		date.setHours (hours);
		date.setMinutes (minutes);

		date.setSeconds (0);
		date.setMilliseconds (0);

	var timeStamp = Math.round (date.valueOf() /1000);
	return timeStamp;

}

function IsTimeInPast (timestamp, currentTime)
{
	var now = Date.parse (new Date (currentTime) || new Date());
	var testTime = Date.parse (new Date (timestamp*1000));

	return (testTime < now);
}

function FireEvent (element,event)
{
	var evt;
	if (document.createEventObject)
	{
		// dispatch for IE
		evt = document.createEventObject();
		return element.fireEvent('on'+event,evt);
	}

	// dispatch for firefox + others
	evt = document.createEvent("HTMLEvents");
	evt.initEvent(event, true, true ); // event type,bubbling,cancelable
	return !element.dispatchEvent(evt);
}

function CalendarWrapper (id, CalendarDateChanged, CalendarCloseHandler, popupId, format, showsTime, showsOtherMonths)
{
	this.CalendarDateChanged = function (calendar, date)
	{
		calendar.sel.value = date;
		FireEvent (calendar.sel, 'change');
		$('#' + id).change();
	};

	this.CalendarCloseHandler = function (calendar)
	{
		calendar.hide();
	};

	this.CalendarDateChanged = (typeof (CalendarDateChanged) == "undefined") ? this.CalendarDateChanged : CalendarDateChanged;
	this.CalendarCloseHandler = (typeof (CalendarCloseHandler) == "undefined") ? this.CalendarCloseHandler : CalendarCloseHandler;
	this.id = id;

	this.format = (typeof (format) == "undefined") ? '%Y-%m-%d %H:%M' : format;
	this.showsTime = (typeof (showsTime) == "undefined") ? '24' : showsTime;
	this.showsOtherMonths = (typeof (showsOtherMonths) == "undefined") ? true : showsOtherMonths;
	this.popupId = (typeof (popupId) == "undefined") ? 'filterPopup' : popupId;

	this.Create = function()
	{
		var element = document.getElementById (this.id);

		if (this.calendar)
		{
			this.calendar.hide();
		}
		else
		{
			this.calendar = new Calendar (1, null, this.CalendarDateChanged, this.CalendarCloseHandler);

			if (typeof this.showsTime === "string")
			{
				this.calendar.showsTime = true;
				this.calendar.time24 = (this.showsTime == "24");
			}

			if (this.showsOtherMonths)
			{
				this.calendar.showsOtherMonths = true;
			}

			this.calendar.setRange (1900, 2070);                      // min/max year allowed.
			this.calendar.create();
		}

		this.calendar.setDateFormat (this.format);         // set the specified date format

		if (element)
		{
			this.calendar.parseDate (element.value || "");      // try to parse the text in field
			this.calendar.sel = element;                 // inform it what input field we use
		}

		this.calendar.popupId = this.popupId;

		return true;
	};

	this.Create();

	this.Draw = function ()
	{
		this.DrawCalendarEventHandler (this.calendar);

		this.Create();

		var element = document.getElementById(this.id);

		this.calendar.showAtElement (element);	// show the calendar

		$(".calendar").each (function()
		{
			$(this).css ("z-index", HighestZIndex() + 1);

			$(this).on ('click', function (event)
			{
				event.stopPropagation();
			});
		});

		return false;
	};

	this.ShowCalendarEventHandler = function(){};

	this.MouseUpEventHandler = function(){};

	this.DrawCalendarEventHandler = function (calendarObject){};

	this.SetFormat = function (format)
	{
		this.format = format;
	};

	this.GetFormat = function ()
	{
		return this.format;
	};

	this.SetCalendarDateChanged = function (calendarDateChanged)
	{
		this.CalendarDateChanged = calendarDateChanged;
	};

	this.GetCalendarDateChanged = function ()
	{
		return this.CalendarDateChanged;
	};

	this.SetCalendarCloseHandler = function (calendarCloseHandler)
	{
		this.CalendarCloseHandler = calendarCloseHandler;
	};

	this.GetTime = function ()
	{
		return Math.floor( this.calendar.date.getTime()/1000 );
	};

	this.SetEpochDate = function (date)
	{
		var dateObj = new Date(date * 1000);
		return this.calendar.setDate(dateObj);
	};

	this.ParseDate = function (dateString)
	{
		return this.calendar.parseDate (dateString);
	};


	this.GetCalendarCloseHandler = function ()
	{
		return this.CalendarCloseHandler;
	};

	this.SetId = function (id)
	{
		this.id = id;
	};

	this.GetId = function ()
	{
		return this.id;
	};

	this.SetShowsTime = function (showsTime)
	{
		this.showsTime = showsTime;
	};

	this.GetShowsTime = function()
	{
		return this.showsTime;
	};

	this.SetShowsOtherMonths = function (showsOtherMonths)
	{
		this.showsOtherMonths = showsOtherMonths;
	};

	this.GetShowsOtherMonths = function()
	{
		return this.showsOtherMonths;
	};

	this.SetPopupId = function (popupId)
	{
		this.popupId = popupId;
	};

	this.GetPopupId = function()
	{
		return this.popupId;
	};
}


// Export
module.exports.ConvertTimestamp = ConvertTimestamp;
module.exports.ConvertDateStringToTimeStamp = ConvertDateStringToTimeStamp;
module.exports.IsTimeInPast = IsTimeInPast;
module.exports.CalendarWrapper = CalendarWrapper;

