
// Import
var jQuery = require ('striata-jquery');
var assert = require ('assert');
require ('./blockheading.css');


(function($)
{
	function CreateImageTag (src)
	{
		var image = $('<img/>');
		image.attr ('src', src);
		return image;
	}

	function CreateCollapseImageTag (option)
	{
		if (!option || !option.subTag)
		{
			return CreateImageTag (require ('./collapse.png').default);
		}
		else
		{
			return CreateImageTag (require ('./subcollapse.png').default);
		}
	}

	function CreateExpandImageTag (option)
	{
		if (!option || !option.subTag)
		{
			return CreateImageTag (require ('./expand.png').default);
		}
		else
		{
			return CreateImageTag (require ('./subexpand.png').default);
		}
	}

	var defaults =
	{
		heading: null,
		baseClass: 'headerizerHeading',
		collapsible: false,
		collapsibleId: null,
		open: false,
		controlBarContent: null,
	};

	$.fn.extend ({
		headerizer: function (options)
		{
			var obj = $(this);

			assert (obj.length == 1, "Element does not exist");
			assert (obj.attr ('id'), "Element has no id");
			assert (!options.collapsible || (options.collapsibleId && $('#' + options.collapsibleId).length == 1), "Collapsible id does not exist");
			assert (!options.controlBarContent || $(options.controlBarContent).length > 0, "No controlbar content");

			options = Object.assign ({}, defaults, options);

			if (options.collapsible)
			{
				if (options.open)
				{
					$("#" + options.collapsibleId).show();
				}
				else
				{
					$("#" + options.collapsibleId).hide();
				}
			}

			var clickId = obj.attr ('id') + '_click';
			if ($(obj).find ('#' + clickId).length > 0)
			{
				// Already headerized
				return;
			}

			if (options.heading)
			{
				var heading = $('<div id="'+ clickId +'"><div>' + options.heading +'</div></div>');
				heading.addClass (options.baseClass);
				obj.prepend (heading);
			}

			$('#' + clickId + '> div').addClass ('headerizerHeadingTitle');

			if (options.controlBarContent)
			{
				var controlBar = $('<div class="headerizerHeadingControlBar"></div>');
				controlBar.append ($(options.controlBarContent));
				$('#' + clickId).append (controlBar);
			}

			if (options.collapsible)
			{
				var clickDivId = options.collapsibleId + '_div';
				var isSubHeading = options.baseClass == "headerizerHeadingSubHeading" ? true : false;

				$('#' + clickId).prepend ('<div id="'+ clickDivId +'"></div>');
				$('#' + clickDivId).addClass ('headerizerHeadingCollapsibleContent');
				$("#" + clickId).addClass ("headerizerHeadingCollapsible");
				$('#' + clickId).on ('click', function()
					{
						// Remove any existing content
						var clickDiv = '#' + clickDivId;
						$(clickDiv).empty();

						// Show the appropriate + or - image
						if ($("#" + options.collapsibleId).is (":hidden"))
						{
							$(clickDiv).append (CreateCollapseImageTag ({subTag: isSubHeading}));
						}
						else
						{
							$(clickDiv).append (CreateExpandImageTag ({subTag: isSubHeading}));
						}

						// Finally toggle the collapsible section
						$("#" + options.collapsibleId).toggle();
					}
				);

				$('#' + clickDivId).append (options.open ?
					CreateCollapseImageTag ({subTag: isSubHeading}) :
					CreateExpandImageTag ({subTag: isSubHeading}));
			}
		}
	});

})(jQuery);
