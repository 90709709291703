
require ('./notice.css');


function NoticeManager (noticeOptions)
{
	this.noticeOptions =
	{
		id            : null,
		name          : null,
		iconSrc       : null,
		parentId      : null,
		parent        : {},
		theme         : null,
		phrases       :
		{
			headingText : null,
			messageText : null
		},
		iconPixelDimensions:
		{
			height : "20",
			width  : "20"
		}
	};

	Object.assign (this.noticeOptions, noticeOptions);

	this.DrawMessage = function()
	{
		var noticeContainer = document.createElement ('div');
		noticeContainer.className = "noticeContainer " + this.noticeOptions.theme;
		noticeContainer.id = this.noticeOptions.id;

		var noticeIconContainer = document.createElement ('div');
		noticeIconContainer.className = "noticeIconContainer " + this.noticeOptions.theme;
		noticeIconContainer.width = this.noticeOptions.iconPixelDimensions.width;
		noticeIconContainer.height = this.noticeOptions.iconPixelDimensions.height;

		var noticeIcon = document.createElement ('img');
		noticeIcon.className = "noticeIcon " + this.noticeOptions.theme;
		noticeIcon.src = this.noticeOptions.iconSrc;
		noticeIcon.width = this.noticeOptions.iconPixelDimensions.width;
		noticeIcon.height = this.noticeOptions.iconPixelDimensions.height;
		noticeIconContainer.appendChild (noticeIcon);

		var noticeHeadingContainer = document.createElement ('div');
		noticeHeadingContainer.className = "noticeHeadingContainer " + this.noticeOptions.theme;

		var noticeHeading = document.createElement ('span');
		noticeHeading.className = "noticeHeading " + this.noticeOptions.theme;
		noticeHeading.innerHTML = this.noticeOptions.phrases.headingText;
		noticeHeadingContainer.appendChild (noticeHeading);

		var noticeMessageContainer = document.createElement ('div');
		var noticeMessageList = document.createElement ('ul');
		noticeMessageList.className = "noticeMessageList " + this.noticeOptions.theme;
		noticeMessageContainer.appendChild (noticeMessageList);

		var index;
		var noticeMessageListItem;
		if (Array.isArray (this.noticeOptions.phrases.messageText))
		{
			for (index in this.noticeOptions.phrases.messageText)
			{
				noticeMessageListItem = document.createElement ('li');
				noticeMessageListItem.innerHTML = this.noticeOptions.phrases.messageText[index];
				noticeMessageList.appendChild (noticeMessageListItem);
			}
		}
		else if (this.noticeOptions.phrases.messageText)
		{
			noticeMessageListItem = document.createElement ('li');
			noticeMessageListItem.innerHTML = this.noticeOptions.phrases.messageText;
			noticeMessageList.appendChild (noticeMessageListItem);
		}

		noticeContainer.appendChild (noticeIconContainer);
		noticeContainer.appendChild (noticeHeadingContainer);
		noticeContainer.appendChild (noticeMessageContainer);

		if (Array.isArray (this.noticeOptions.parentId))
		{
			for (index = 0; index < this.noticeOptions.parentId.length; ++index)
			{
				if (typeof (this.noticeOptions.parentId) === 'object')
				{
					document.getElementById (this.noticeOptions.parentId[index]).appendChild (noticeContainer.cloneNode (true));
				}
			}
		}
		else if (this.noticeOptions.parentId)
		{
			if (typeof (this.noticeOptions.parentId) === 'object')
			{
				this.noticeOptions.parentId.appendChild (noticeContainer);
			}
			else if (document.getElementById (this.noticeOptions.parentId))
			{
				document.getElementById (this.noticeOptions.parentId).appendChild (noticeContainer);
			}
		}
	};
}


// Export
module.exports = NoticeManager;

