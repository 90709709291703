require ('core-js');
require ('@include/login/globals');
require ('@common/platform.css');
require ('./login.css');

var $ = require ('striata-jquery');
var StriataError = require ('@control/error/striataerror').StriataError;
var BuildPostForm = require ('@utility/form/form');
var CreateFooter = require ('@project/common/footer').CreateFooter;


$(function()
{
	var stash = pageStash;

	CreateFooter ('#bottomContentDiv');

	$('#userName').focus();

	if (stash.sessionExpired)
	{
		$('#loginFormDivContent').addClass ('loginFormDivContentExpired');
	}

	function LoginOnEnter (event)
	{
		if (event.keyCode == 13) //ENTER
		{
			$("#loginFormSubmitButton").trigger ('click');
		}
	}
	function SubmitRealmOnEnter (event)
	{
		if (event.keyCode == 13)
		{
			$("#continueSsoModalButton").trigger ('click');
		}
	}

	$('#userName').on ('keydown', LoginOnEnter);
	$("#password").on ('keydown', LoginOnEnter);

	function LoginSuccess (result)
	{
		var revalidateParameters = $("#revalidate_parameters").val();
		var userName = $("#userName").val();
		var error = new StriataError();
		error.PopulateFromJson (result);
		$(this).ThrobberHide();

		if (error.numErrors == 0)
		{
			var sessionId = result.sessionId;
			$("#loginScreenErrorMessage").html ('&nbsp;');

			BuildPostForm (stash.login_action,
			{
				userName              : userName,
				dologin               : 1,
				newSessionId          : 1,
				sessionId             : sessionId,
				revalidate_parameters : revalidateParameters
			});
		}
		else
		{
			var errorString = error.ToString();

			$('#loginFormSubmitButton').removeClass ("disableLoginButton");
			$("#loginScreenErrorMessage").html (errorString);
			$("#csrf").val (result._csrf);
		}
	}

	function LoginFailure (result)
	{
		$(this).ThrobberHide();
		$('#loginFormSubmitButton').removeClass ("disableLoginButton");

		if (result.status == 0 && result.statusText == "error")
		{
			$("#loginScreenErrorMessage").html ("(500) " + stash.errors['500']);
		}
		else
		{
			$("#loginScreenErrorMessage").html (result.statusText + " " + result.status);
		}
	}

	$("#loginFormSubmitButton").on ('click', function (event)
	{
		// TODO: This is a horrible, we should not have a handler installed
		if ($(this).hasClass ("disableLoginButton"))
		{
			return false;
		}

		var userName = $("#userName").val();
		var password = $("#password").val();
		var csrf = $("#csrf").val();
		var revalidateParameters = $("#revalidate_parameters").val();
		$('#loginFormSubmitButton').addClass ("disableLoginButton");

		$(this).Throbber();

		$.ajax (
			{
				type: "POST",
				url: "/platform/login/login/DoLogin",
				data:
				{
					showType: "json",
					userName: userName,
					password: password,
					csrf    : csrf,
					revalidate_parameters: revalidateParameters
				},
				success: LoginSuccess,
				error: LoginFailure
			}
		);

		return true;
	});

	$("#loginFormForgottenPassword").on ('click', function (event)
	{
		var userName = $("#userName").val() || "";
		BuildPostForm ("/platform/login/login/ResetPassword",
			{
				userName: userName,
			}
		);
	});

	$("#loginFormSwitchUser").on ('click', function (event)
	{
		BuildPostForm ("/platform/login/login/ShowLogin",
			{
				Logout: 1,
				userName: ""
			}
		);
	});

	$("#launchSSO").on ('click', function (event)
	{
		var ModalGenerator = require ('@control/dialog/modalgenerator');
		var ssoModal = new ModalGenerator (
		{
			id      : 'ssoModal',
			heading : pageStash.text.Single_Sign_On,
			icon    : '',
			width   : '700px',
			hasForm : false
		});

		ssoModal.AddButton (
		{
			id       : 'continueSsoModalButton',
			value    : pageStash.text.Continue,
			enabled  : true,
			callback : function()
			{
				if (!$("#realmName").validationEngine ('validate'))
				{
					const params = new URLSearchParams (window.location.search);
					let firstLoginUserId = params.get ('userId');
					let firstLoginUserRealm = params.get ('realm');

					let postData =
					{
						realmName : $('#realmName').val(),
					};

					if (firstLoginUserRealm && firstLoginUserId && firstLoginUserRealm.length && firstLoginUserId.length)
					{
						postData.firstLoginUserId = firstLoginUserId;
						postData.firstLoginUserRealm = firstLoginUserRealm;
					}

					$(this).Throbber ({display: "id:ssoModal"});

					$.ajax (
					{
						type    : 'POST',
						url     : '/platform/login/login/BuildOpenIdUrl',
						data    : postData,
						success : function (response)
						{
							if (response.uri)
							{
								$('#realmNameContainer').remove();
								$('#ssoIframe').remove();
								$('#continueSsoModalButton').remove();

								$('#ssoModalIframeSection').append ('<iframe id="ssoIframe"></iframe>');
								$('#ssoModalIframeSection .headerizerHeadingTitle').html (pageStash.text.Please_Complete_Sign_In_Details);
								document.getElementById ('ssoIframe').src = response.uri;
								$('#ssoModal').ResetModal();
							}
							else
							{
								$('#realmName').val ('');
								$('#realmMessageContainer').html (`<div id='realmMessage'>${pageStash.text.Realm_Not_Found}</div>`);
							}

							$(this).ThrobberHide();
						}
					});
				}
			}
		});

		ssoModal.AddButton (
		{
			id       : 'closeCancelSsoModalButton',
			value    : pageStash.text.Cancel,
			enabled  : true,
			callback : function()
			{
				$(this).CloseModalWindow ("#ssoModal");
			}
		});

		ssoModal.AddSection ({id: 'ssoModalIframeSection', heading: pageStash.text.Please_Enter_Your_Single_Sign_On_Realm});

		$('#ssoModalIframeSection').append (`
			<div id='realmNameContainer'>
				<div class='realmNameInput'>
					<input id='realmName' type='text' class='createDetailsFormInputText validate[required]' />
					<span id='realmNameHelp'></span>
				</div>
				<div id='realmMessageContainer'></div>
			</div>`);

		$("#realmNameHelpText").clone (true).show().appendTo ($('#realmNameHelp'));

		$('#realmName').on ('click', function()
		{
			$('#realmName').validationEngine ('hide');
			$('#realmMessage').remove();
		});
		$('#realmName').on ('keydown', SubmitRealmOnEnter);

		ssoModal.Generate();
	});

	const params = new URLSearchParams (window.location.search);
	let firstLoginUserId = params.get ('userId');
	if ((firstLoginUserId) && (firstLoginUserId.length > 0))
	{
		$("#launchSSO").click();
	}
});

