var jQuery = require ('jquery');
require ('jquery-ui-dist/jquery-ui.js');
require ('jquery-ui-dist/jquery-ui.css');
require ('@jqueryexternal/validationengine/js/jquery.validationEngine.js');
require ('@jqueryexternal/validationengine/js/jquery.validationEngine-en.js');
require ('./external/validationengine/css/validationEngine.jquery.css');
require ('./external/validationengine/css/template.css');

module.exports = jQuery;

