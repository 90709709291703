// Import

var $ = require ('striata-jquery');
var CalendarWrapper = require ('@control/date/date').CalendarWrapper;
var ConvertTimestamp = require ('@control/date/date').ConvertTimestamp;
var UploadFileInputControl = require ('@control/fileupload/fileupload');
var ServerDateTimeCalendarWrapper = require ('@control/date/serverdatetimecalendarwrapper');
var TagElement = require ('./tags');

const FormElement = {};

FormElement.Text = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	var field = document.createElement ('input');
	field.name = attributes.name;
	field.id = attributes.id;
	field.setAttribute ("generatedField", "");

	if (attributes.placeholderText)
	{
		field.setAttribute ("placeholder", attributes.placeholderText);
	}

	if (attributes.disabled == false)
	{
		field.className = "textField " + attributes.validationRule;
	}
	else
	{
		field.className = "textFieldDisabled";

		if (attributes.forceValidate)
		{
			field.className += " " + attributes.validationRule;
		}
	}

	field.type = "text";
	field.value = attributes.value;

	if (attributes.disabled)
	{
		field.setAttribute ("readonly", "readonly");
	}
	else
	{
		if (attributes.inputOptions)
		{
			field.addEventListener ("focus", function (event)
			{
				FormElement.DrawInputOptions (event.target, attributes.inputOptions);
			});
		}
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Hidden = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	var field = document.createElement ('input');
	field.name = attributes.name;
	field.id = attributes.id;
	field.type = "hidden";
	field.value = attributes.value;
	field.setAttribute ("generatedField", "");

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.TextArea = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	var mandatoryColumn = targetElement.getElementsByClassName ("mandatoryColumn")[0];
	var descriptionColumn = targetElement.getElementsByClassName ("descriptionColumn")[0];

	if (!attributes.description)
	{
		mandatoryColumn.parentNode.removeChild (mandatoryColumn);
		descriptionColumn.parentNode.removeChild (descriptionColumn);
		fieldParent.colSpan = 3;
	}

	var hideEditButton = attributes.hideEditButton || false;

	var textAreaButtonContainer = document.createElement ('div');
	fieldContainer.appendChild (textAreaButtonContainer);

	var field = document.createElement ('textarea');
	field.name = attributes.name;
	field.id = attributes.id;
	field.value = attributes.value || "";
	field.setAttribute ("generatedField", "");
	field.style.height = attributes.height;

	field.className = hideEditButton && !attributes.disabled ? "textAreaField" : "textAreaFieldDisabled";
	field.readOnly = hideEditButton && !attributes.disabled ? false : true;

	if (attributes.hideDisabled)
	{
		field.style.display = "none";
	}

	field.SetDisabled = function()
	{
		if (hideEditButton)
		{
			this.className = "textAreaFieldDisabled";
			this.readOnly = true;
		}
	};

	field.SetEnabled = function()
	{
		if (hideEditButton)
		{
			this.className = "textAreaField " + attributes.validationRule;
			this.readOnly = false;
		}
	};

	field.AddButton = function (options)
	{
		if (attributes.disabled == false)
		{
			var buttonOptions = {
				id            : "",
				text          : "",
				positionIndex : null,
				iconSource    : require ('./edit.png').default,
				callBack      : function(){}
			};

			$.extend (buttonOptions, options);

			var textAreaButton = document.createElement ('div');
			textAreaButton.style.display = "inline-block";
			textAreaButton.style.marginBottom = "10px";
			textAreaButton.style.marginLeft = "10px";
			textAreaButton.style.cursor = "pointer";

			textAreaButton.onclick = buttonOptions.callBack;

			var textAreaButtonImage = document.createElement ('img');
			textAreaButtonImage.src = buttonOptions.iconSource;
			textAreaButtonImage.style.marginRight = "5px";
			textAreaButton.appendChild (textAreaButtonImage);

			var textAreaButtonText = document.createElement ('span');
			textAreaButtonText.id = buttonOptions.id + "Button";
			textAreaButtonText.style.color = "blue";
			textAreaButtonText.style.textDecoration = "underline";
			textAreaButtonText.style.fontSize = "11px";
			textAreaButtonText.innerHTML = buttonOptions.text;

			textAreaButton.appendChild (textAreaButtonText);
			textAreaButtonContainer.appendChild (textAreaButton);

			var buttonPosition;

			if (buttonOptions.positionIndex >= 0)
			{
				if (buttonOptions.positionIndex > $(textAreaButtonContainer).children().length - 1)
				{
					buttonPosition = $(textAreaButtonContainer).children().length - 1;
				}
				else
				{
					buttonPosition = buttonOptions.positionIndex;
				}

				$(textAreaButtonContainer).children().eq (buttonPosition).before (textAreaButton);
			}
		}
	};

	if (hideEditButton == false && field)
	{
		field.AddButton (
		{
			id            : attributes.id,
			text          : attributes.editSource || attributes.phrases.editSourceText || 'Edit',
			iconSource    : require ('./edit.png').default,
			callBack      : attributes.editOnClickOverride || function()
			{
				targetElement.querySelectorAll ("#" + attributes.id)[0].style.display = "block";
				targetElement.querySelectorAll ("#" + attributes.id)[0].readOnly = false;
				targetElement.querySelectorAll ("#" + attributes.id)[0].className = "textAreaField " + attributes.validationRule;
			}
		});
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Image = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	var helpContainer = targetElement.getElementsByClassName ("helpColumn")[0];
	helpContainer.style.width = "0px";

	fieldParent.colSpan = 4;
	fieldParent.style.height = "0px";
	fieldParent.style.backgroundImage = require ('./previewbackground.png').default;

	var mandatoryColumn = targetElement.getElementsByClassName ("mandatoryColumn")[0];
	var descriptionColumn = targetElement.getElementsByClassName ("descriptionColumn")[0];

	mandatoryColumn.parentNode.removeChild (mandatoryColumn);
	descriptionColumn.parentNode.removeChild (descriptionColumn);

	var field = document.createElement ('img');
	$(field).hide();

	field.src = attributes.value.src || '';
	field.id = attributes.id;
	field.name = attributes.name;
	field.setAttribute ("generatedField", "");

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	if (attributes.value.src)
	{
		if (attributes.value.src.indexOf ("?") == -1)
		{
			field.src = attributes.value.src + "?" + new Date().getTime();
		}
		else
		{
			field.src = attributes.value.src + "&" + new Date().getTime();
		}
	}

	$(field).on ('load', function()
	{
		$(field).show();

		var parentWidth = fieldParent.offsetWidth;

		var naturalWidth = field.naturalWidth;
		var naturalHeight = field.naturalHeight;
		var constrainWidth = 0;
		var constrainHeight = 0;

		if (attributes && attributes.value)
		{
			if (attributes.value.constrainWidth && attributes.value.constrainHeight)
			{
				constrainWidth = parseInt ("" + attributes.value.constrainWidth, 10);
				constrainHeight = parseInt ("" + attributes.value.constrainHeight, 10);
			}
		}
		naturalWidth = constrainWidth > 0 ? constrainWidth : naturalWidth;
		naturalHeight = constrainHeight > 0 ? constrainHeight : naturalHeight;

		if (naturalWidth < parentWidth)
		{
			field.style.width = naturalWidth + "px";
			field.style.height = naturalHeight + "px";
		}
		else
		{
			var aspectRatio = naturalWidth / naturalHeight;
			field.style.width = fieldParent.parentNode.parentNode.parentNode.offsetWidth + "px";
			field.style.height = (fieldParent.parentNode.parentNode.parentNode.offsetWidth / aspectRatio) + "px";
		}

		field.className = "imageField " + attributes.validationRule;
		field.style.border = "1px dashed rgba(121,121,121,0)";
		field.style.verticalAlign = "middle";
		field.style.maxWidth = "100%";

		$(window).ResetModal();
		$("[name='" + attributes.value.dimensionsElement + "']").val (naturalWidth + "px x " + naturalHeight + "px");

		if (attributes.constraints)
		{
			attributes.form.AddValidationRule ("iconSizeConstraint" + attributes.constraints.width + "By" + attributes.constraints.height,
			{
				"func": function()
				{
					if (attributes.constraints.width != field.naturalWidth && attributes.constraints.height != field.naturalHeight)
					{
						return false;
					}

					return true;
				},
				alertText: pageStash.text.Image_Does_Not_Have_The_Expected_Dimensions + " (" + attributes.constraints.width + "x" + attributes.constraints.height + "px)"
			});
		}
	});

	$(fieldParent.parentNode.parentNode).on ('mouseenter',
	function()
	{
		var item = $(field);
		$({alpha:0}).animate({alpha:1}, {
			duration: 500,
			step: function()
			{
				item.css('border-color','rgba(121,121,121,'+this.alpha+')');
			}
		});
	}).on ('mouseleave',
	function()
	{
		var item = $(field);
		$({alpha:1}).animate({alpha:0}, {
			duration: 500,
			step: function()
			{
				item.css('border-color','rgba(121,121,121,'+this.alpha+')');
			}
		});
	});

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Upload = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	if (attributes.disabled)
	{
		var field = document.createElement ('input');
		field.name = attributes.name;
		field.id = attributes.id;
		field.value = attributes.value;
		field.setAttribute ("generatedField", "");
		field.className = "textFieldDisabled";
		field.setAttribute ("readonly", "readonly");
	}
	else
	{
		field = document.createElement ('div');
		field.name = attributes.id;
		field.id = attributes.id;
		field.value = attributes.value;
		field.setAttribute ("generatedField", "");
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	$(document).ready (function()
	{
		if (!attributes.disabled)
		{
			var uploadControl = RenderUploadControl (
			{
				id                  : attributes.id,
				name                : attributes.name,
				value               : attributes.value,
				allowedExtensions   : attributes.allowedExtensions,
				validationRule      : attributes.validationRule,
				validationErrorText : attributes.validationErrorText,
				sizeLimitErrorText  : attributes.sizeLimitErrorText,
				sizeLimitWarnText   : attributes.sizeLimitWarnText,
				buttonState         : attributes.buttonState,
				selectOptions       : attributes.selectOptions,
				sizeWarningLimit    : attributes.sizeWarningLimit,
				sizeErrorLimit      : attributes.sizeErrorLimit,
				phrases             :
				{
					buttonText : attributes.phrases.buttonText,
					selectText : attributes.phrases.selectText

				},
				successCallBack     : function (fileName)
				{
					$("[name='" + attributes.name + "']").validationEngine ('validate');

					if (attributes.successCallBack)
					{
						attributes.successCallBack (fileName);
					}
				},
				onLoad              : function (fileName)
				{
					var uploadElement = document.querySelectorAll ("[name='" + attributes.name + "']")[0];
					if (uploadElement)
					{
						uploadElement.setAttribute ("value", fileName);
					}

					if (attributes.onLoad)
					{
						attributes.onLoad();
					}
				}
			});

			field.GetControl = function()
			{
				return uploadControl;
			};

			field.querySelector ("[name='" + attributes.name + "']").classList.add ("validate[custom[invalidExtension]]");
			var dummyUploadField = document.getElementById ("savedFilename_" + attributes.id);

			if (dummyUploadField){ dummyUploadField.classList.add ("validate[custom[invalidExtension]]") }

			if (attributes.form)
			{
				attributes.form.AddValidationRule ("invalidExtension",
				{
					regex     : /^.*(\.|\/)(?!(exe|bat|com|sh|vbs|asp|php|tiff|bmp|iso)$)(?![^./]*(\.|\/))/i,
					alertText : attributes.phrases.fileNotPermitted
				});

				attributes.form.AddValidationRule ("sizeLimitExceeded",
				{
					"func": function()
					{
						var thisControl = field.GetControl();

						if (attributes.sizeWarningLimit != 0 && thisControl.fileSize > attributes.sizeErrorLimit)
						{
							return false;
						}

						return true;
					},
					alertText: attributes.sizeLimitErrorText
				});
			}
		}
	});

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Freeform = function (targetElement, attributes)
{
	var parentCell = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	parentCell.innerHTML = attributes.freeform;

	if (attributes.successCallBack)
	{
		attributes.successCallBack();
	}

	ApplyFormAttributes (parentCell, attributes.attributes);

	return parentCell;
};

FormElement.Block = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.id = attributes.name;
	fieldContainer.name = attributes.name;
	fieldContainer.className = attributes.name;

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	if (attributes.greedy)
	{
		var fieldColumns = targetElement.getElementsByClassName ("fieldParent");

		for (var startIndex = fieldColumns.length - 1; startIndex > 0; --startIndex)
		{
			fieldColumns[startIndex].parentElement.removeChild (fieldColumns[startIndex]);
		}

		fieldParent.setAttribute ("colspan", "4");
	}

	var block = document.createElement ('div');
	block.name = attributes.name + "Block";
	block.id = attributes.name + "Block";
	fieldContainer.appendChild (block);
	fieldParent.appendChild (fieldContainer);

	return block;
};

FormElement.Icon = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var icon = document.createElement ('img');
	icon.name = attributes.name;
	icon.id = attributes.id;
	icon.className = "selectField " + attributes.validationRule;
	icon.value = attributes.value;
	icon.setAttribute ("generatedField", "");

	fieldContainer.appendChild (icon);
	fieldParent.appendChild (fieldContainer);

	ApplyFormAttributes (icon, attributes.attributes);

	return icon;
};

FormElement.Select = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('select');
	field.name = attributes.name;
	field.id = attributes.id;
	field.className = "selectField " + attributes.validationRule;
	field.value = attributes.value;
	field.setAttribute ("generatedField", "");

	if (attributes.disabled)
	{
		field.setAttribute ("disabled", "true");
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	for (var option in attributes.options)
	{
		var selectOption = document.createElement ('option');
		selectOption.value = attributes.options[option].value;
		selectOption.text = attributes.options[option].label;

		if (attributes.options[option].selected)
		{
			selectOption.setAttribute ("selected", "");
		}

		if (attributes.options[option].disabled)
		{
			selectOption.setAttribute ("disabled", "");
		}

		field.appendChild (selectOption);
	}

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.MultiSelect = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('select');
	field.name = attributes.name;
	field.id = attributes.id;
	field.className = "selectField " + attributes.validationRule;
	field.value = attributes.value;
	field.setAttribute ("generatedField", "");
	field.setAttribute ("multiple", "");
	field.style.height = "auto";

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	for (var option in attributes.options)
	{
		var selectOption = document.createElement ('option');
		selectOption.value = attributes.options[option].value;
		selectOption.text = attributes.options[option].label;

		if (attributes.options[option].selected)
		{
			selectOption.setAttribute ("selected", "");
		}

		if (attributes.options[option].disabled)
		{
			selectOption.setAttribute ("disabled", "");
		}

		field.appendChild (selectOption);
	}

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Checkbox = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('input');
	field.name = attributes.name;
	field.id = attributes.id;
	field.className = "checkboxField " + attributes.validationRule;
	field.value = attributes.value;
	field.setAttribute ("type", "checkbox");
	field.setAttribute ("generatedField", "");

	if (attributes.disabled)
	{
		field.setAttribute ("disabled", "true");
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	for (var option in attributes.options)
	{
		if (attributes.options[option].selected === true)
		{
			field.setAttribute ("checked", "true");
		}
		else
		{
			field.removeAttribute ("checked");
		}
	}

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Radio = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	// Check if radio group already exists in the form.
	if (attributes.display == "inline" && targetElement.parentElement.querySelectorAll ("input[name='" + attributes.name + "']")[0])
	{
		fieldParent = targetElement.parentElement.querySelectorAll ("input[name='" + attributes.name + "']")[0].parentElement;
	}

	var field = document.createElement ('input');
	field.name = attributes.name;
	field.id = attributes.id;
	field.className = "radioField " + attributes.validationRule;
	field.setAttribute ("type", "radio");
	field.setAttribute ("generatedField", "");
	field.setAttribute ("value", attributes.options[0].value);

	var radioLabel = document.createElement ('label');
	radioLabel.className = "labelField";
	radioLabel.htmlFor = attributes.id;
	radioLabel.appendChild (document.createTextNode (attributes.options[0].label));

	if (attributes.disabled)
	{
		field.setAttribute ("disabled", "true");
		radioLabel.className = "labelFieldDisabled";
	}

	fieldContainer.appendChild (field);
	fieldContainer.appendChild (radioLabel);
	fieldParent.appendChild (fieldContainer);

	for (var option in attributes.options)
	{
		if (attributes.options[option].selected === true)
		{
			field.checked = true;
		}
	}

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Range = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('input');
	field.name = attributes.name;
	field.id = attributes.id;
	field.value = attributes.value;
	field.className = "rangeField " + attributes.validationRule;
	field.setAttribute ("type", "range");
	field.setAttribute ("generatedField", "");

	if (attributes.disabled)
	{
		field.setAttribute ("disabled", "true");
	}

	if (attributes.options[0])
	{
		field.setAttribute ("min", attributes.options[0].min);
		field.setAttribute ("max", attributes.options[0].max);
		field.setAttribute ("step", attributes.options[0].step);
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.String = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('span');
	field.name = attributes.name;
	field.id = attributes.id;
	field.className = "stringField";
	field.innerText = attributes.value;
	field.setAttribute ("generatedField", "");

	if (attributes.disabled)
	{
		field.className = "stringFieldDisabled";
	}

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Template = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('input');
	field.name = "selectedTemplateName";
	field.id = attributes.id;
	field.className = "templateField textFieldDisabled " + attributes.validationRule;
	field.style.width = "353px";
	field.style.float = "left";
	field.style.cursor = "pointer";
	field.readOnly = true;
	field.value = attributes.value;
	field.onclick = function()
	{
		if (attributes.successCallBack)
		{
			attributes.successCallBack();
		}
	};
	field.onfocus = function()
	{
		this.blur();
	};

	fieldContainer.appendChild (field);

	var fieldButton = document.createElement ('input');
	fieldButton.className = "browseButton enabledButton";
	fieldButton.type = "button";
	fieldButton.value = "Select";
	fieldButton.name = "templateFieldButton";
	fieldButton.onclick = function()
	{
		if (attributes.successCallBack)
		{
			attributes.successCallBack();
		}
	};

	fieldContainer.appendChild (fieldButton);

	field = document.createElement ('input');
	field.type = "hidden";
	field.name = "selectedTemplateId";
	field.id = attributes.id + "TemplateId";
	field.className = "templateField";
	field.setAttribute ("generatedField", "");

	fieldContainer.appendChild (field);
	fieldParent.appendChild (fieldContainer);

	var templateIdObserver = new MutationObserver (function (mutations)
	{
		mutations.forEach (function (mutation)
		{
			if (mutation.attributeName == 'value')
			{
				$(field).trigger ("change");

				if (attributes.changeCallBack)
				{
					attributes.changeCallBack (field.value);
				}
			}
		});
	});

	templateIdObserver.observe (field.parentElement, {attributes: true, childList: true, subtree: true});
	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Calendar = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";

	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var field = document.createElement ('input');
	field.className = "calendarField";
	field.name = attributes.name;
	field.id = attributes.name;
	field.setAttribute ("generatedField", "");

	if (attributes.validationRule)
	{
		field.classList.add (attributes.validationRule);
	}

	if (attributes.value)
	{
		field.value = !isNaN (attributes.value) ? ConvertTimestamp (attributes.value) : attributes.value;
	}

	if (attributes.placeholderText)
	{
		field.setAttribute ("placeholder", attributes.placeholderText);
	}

	var calendarIcon = document.createElement ('img');
	calendarIcon.setAttribute ("src", require ('./cal.gif').default);
	calendarIcon.id = attributes.name + "Button";
	calendarIcon.style.float = "left";
	calendarIcon.style.marginTop = "5px";

	fieldContainer.appendChild (field);
	fieldContainer.appendChild (calendarIcon);

	fieldParent.appendChild (fieldContainer);

	setTimeout (function ()
	{
		$(document).ready (function()
		{
			RenderCalendarControl (field, {
				name    : attributes.name,
				phrases : attributes.phrases
			});
		});

	}, 5);

	field.setAttribute ("calendar", "true");
	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.Tags = function (targetElement, attributes)
{
	var fieldContainer = document.createElement ('span');
	fieldContainer.className = attributes.name + "FieldContainer";
	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	var tags = new Object (TagElement);

	if (attributes.attributes.showAddButton)
	{
		fieldContainer.setAttribute ("showAddButton", "true");
	}

	tags.selectOptions = attributes.selectOptions;
	tags.values = attributes.attributes.values;
	tags.readonly = attributes.disabled ? true : false;
	tags.phrases.description = attributes.phrases.description ? attributes.phrases.description : pageStash.text.Click_To_Add_A_Tag;
	fieldParent.appendChild (fieldContainer);

	var tagsContainer = tags.Draw (
	{
		name          : attributes.name,
		parentElement : fieldContainer,
		limit         : attributes.taglimit ? attributes.taglimit : attributes.selectOptions.length
	});

	ApplyFormAttributes (tagsContainer, attributes.attributes);

	return tagsContainer;
};

FormElement.Submit = function (targetElement, formAttributes, fieldAttributes)
{
	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;
	var buttonGroup;

	if (fieldAttributes.buttonGroup && targetElement.parentElement.querySelectorAll ("[name='" + fieldAttributes.buttonGroup + "']")[0])
	{
		buttonGroup = targetElement.parentElement.querySelectorAll ("[name='" + fieldAttributes.buttonGroup + "']")[0];
		targetElement.parentElement.removeChild (targetElement);
	}
	else
	{
		buttonGroup = document.createElement ('div');
		buttonGroup.className = "submitButtonGroup";
		buttonGroup.style.float = "left";
		fieldParent.appendChild (buttonGroup);
	}

	var field = document.createElement ('input');
	field.setAttribute ("type", "submit");
	field.value = fieldAttributes.value;
	field.name = fieldAttributes.name;
	field.id = fieldAttributes.id;
	field.style.marginRight = "5px";

	if (fieldAttributes.buttonState == "enabled")
	{
		field.className = "soloButton enabledButton";
		field.removeAttribute ("disabled");
	}
	else
	{
		field.className = "soloButton disabledButton";
		field.setAttribute ("disabled", "disabled");
	}

	buttonGroup.setAttribute ("name", fieldAttributes.buttonGroup || fieldAttributes.name + "ButtonGroup");
	buttonGroup.appendChild (field);

	var notification = document.createElement ('div');
	notification.id = fieldAttributes.id + "Notification";
	notification.className = notification.className + " notificationContainer";
	fieldParent.appendChild (notification);

	ApplyFormAttributes (field, fieldAttributes.attributes);

	return field;
};

FormElement.Details = function (targetElement, attributes)
{
	var field;
	var fieldParent = targetElement.getElementsByClassName ("fieldParent")[0] || targetElement;

	var mandatoryColumn = targetElement.getElementsByClassName ("mandatoryColumn")[0];

	if (mandatoryColumn)
	{
		mandatoryColumn.parentNode.removeChild (mandatoryColumn);
	}

	var descriptionColumn = targetElement.getElementsByClassName ("descriptionColumn")[0];

	if (descriptionColumn)
	{
		descriptionColumn.parentNode.removeChild (descriptionColumn);
	}

	targetElement.style.verticalAlign = "top";

	for (var i = 0; i < attributes.options.details.length; i++)
	{
		var fieldtargetElement = document.createElement ('div');

		var fieldtargetElementDescription = document.createElement ('span');
		fieldtargetElementDescription.className = "detailsDescriptionField";
		fieldtargetElementDescription.innerHTML = attributes.options.details[i].description;

		var fieldtargetElementValue = document.createElement ('span');
		fieldtargetElementValue.className = "detailsValueField";
		fieldtargetElementValue.innerHTML = attributes.options.details[i].fieldValue;

		fieldtargetElement.appendChild (fieldtargetElementDescription);
		fieldtargetElement.appendChild (fieldtargetElementValue);

		fieldParent.appendChild (fieldtargetElement);
		field = fieldtargetElement;
	}

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

FormElement.DrawInputOptions = function (field, inputOptions)
{
	var inputSelector = field.parentElement.querySelectorAll (".inputSelector")[0];

	if (!inputOptions || inputOptions.length == 0 || this.readonly || inputSelector)
	{
		return false;
	}

	var parentElement = field.parentElement;
	var selectInput = document.createElement ("div");
	selectInput.classList.add ("inputSelector");
	parentElement.appendChild (selectInput);

	for (var optionIndex in inputOptions)
	{
		var selectInputOption = document.createElement ("div");
		selectInputOption.classList.add ("inputSelectorOption");
		selectInputOption.innerText = inputOptions[optionIndex];

		selectInputOption.addEventListener ("mouseover", function (event)
		{
			event.target.style.backgroundColor = "#DDDDDD";
		});

		selectInputOption.addEventListener ("mouseleave", function (event)
		{
			event.target.style.backgroundColor = "#EEEEEE";
		});

		selectInputOption.addEventListener ("mousedown", function (event)
		{
			field.value = event.target.innerText;
			var formUpdatedEvent = document.createEvent ('Event');
			formUpdatedEvent.initEvent ('formupdated', true, true);
			event.target.setAttribute ("formupdated", "true");
			event.target.dispatchEvent (formUpdatedEvent);

			var inputSelectors = document.querySelectorAll (".inputSelector");

			for (var index = 0; index < inputSelectors.length; ++index)
			{
				inputSelectors[index].parentElement.removeChild (inputSelectors[index]);
			}
		});

		field.addEventListener ("focusout", function (event)
		{
			var inputSelectors = document.querySelectorAll (".inputSelector");

			for (var index = 0; index < inputSelectors.length; ++index)
			{
				inputSelectors[index].parentElement.removeChild (inputSelectors[index]);
			}
		});

		selectInputOption.style.cursor = "pointer";
		selectInput.appendChild (selectInputOption);
	}

	var fieldBounds = field.getBoundingClientRect();
	selectInput.style.top = fieldBounds.top + window.scrollY + field.offsetHeight + "px";
	selectInput.style.left = fieldBounds.left + window.scrollX + "px";
	selectInput.style.width = fieldBounds.width + "px";

	if (document.body.scrollHeight <= selectInput.offsetTop + selectInput.offsetHeight)
	{
		selectInput.style.top = fieldBounds.top + window.scrollY - selectInput.offsetHeight - 5 + "px";
	}

	ApplyFormAttributes (field, attributes.attributes);

	return field;
};

function ApplyFormAttributes (targetElement, attributes)
{
	if (attributes)
	{
		for (var attribute in attributes)
		{
			targetElement.setAttribute (attribute, attributes[attribute]);
		}
	}
}

function RenderUploadControl (options)
{
	var uploadOptions = {
		id                  : null,
		name                : null,
		value               : null,
		allowedExtensions   : [],
		validationRule      : null,
		validationErrorText : null,
		sizeLimitErrorText  : null,
		sizeLimitWarnText   : null,
		required            : true,
		buttonState         : null,
		selectOptions       : {},
		sizeWarningLimit    : 0,
		sizeErrorLimit      : 0,
		successCallBack     : function(){},
		onLoad              : function(){},
		phrases             :
		{
			buttonText : null,
			selectText : null
		}
	};

	$.extend (uploadOptions, options);

	var uploadFieldControl = new UploadFileInputControl();
	var validationRule = uploadOptions.validationRule;

	if (uploadOptions.sizeErrorLimit > 0)
	{
		validationRule += " validate[custom[sizeLimitExceeded]]";
	}

	uploadFieldControl.Initialise (
		uploadOptions.id,
		{
			name                : uploadOptions.name,
			value               : uploadOptions.value,
			required            : uploadOptions.required,
			enabled             : true,
			allowedExtensions   : uploadOptions.allowedExtensions,
			validationRule      : validationRule,
			validationErrorText : uploadOptions.validationErrorText,
			sizeLimitErrorText  : uploadOptions.sizeLimitErrorText,
			sizeLimitWarnText   : uploadOptions.sizeLimitWarnText,
			buttonText          : uploadOptions.phrases.buttonText,
			selectText          : uploadOptions.phrases.selectText,
			buttonState         : uploadOptions.buttonState,
			selectOptions       : uploadOptions.selectOptions,
			sizeWarningLimit    : uploadOptions.sizeWarningLimit,
			sizeErrorLimit      : uploadOptions.sizeErrorLimit,
			onChange            : function (eventData)
			{
				var validated = uploadFieldControl.Validate();
				var errors = uploadFieldControl.GetLastValidationErrors();
				var activeErrorNotifications = $('#' + uploadFieldControl.id).data ('activeErrorNotifications') || [];

				//warnonly errors return true on validation, but populates the error array with the validation error
				if (errors.length > 0)
				{
					for (var i = 0 ; i < errors.length; i++)
					{
						var error = errors[i];

						if (!validated)
						{
							if (error.type == uploadFieldControl.ErrorType.INVALID_EXTENSION)
							{
								$(error.context.fieldId).validationEngine ("showPrompt", uploadOptions.validationErrorText, "warn", "topRight", true);
								activeErrorNotifications.push ({id: error.context.fieldId, type: error.type});
							}

							if (error.type == uploadFieldControl.ErrorType.SIZE_LIMIT_EXCEEDED)
							{
								$(error.context.fieldId).validationEngine ("showPrompt", uploadOptions.sizeLimitErrorText, "warn", "topRight", true);
								activeErrorNotifications.push ({id: error.context.fieldId, type: error.type});
							}
						}

						if (error.type == uploadFieldControl.ErrorType.SIZE_LIMIT_WARNING)
						{
							$(error.context.fieldId).validationEngine ("showPrompt", uploadOptions.sizeLimitWarnText, "warn", "topRight", true);
							activeErrorNotifications.push ({id: error.context.fieldId, type: error.type});
						}
					}

					$('#' + uploadFieldControl.id).data ('activeErrorNotifications', activeErrorNotifications);
				}

				if (validated && errors.length == 0)
				{
					if (activeErrorNotifications.length > 0)
					{
						for (i = 0 ; i < activeErrorNotifications.length; i++)
						{
							var id = activeErrorNotifications[i].id;
							$(id).validationEngine ('hideAll');
						}
					}

					$('#' + uploadFieldControl.id).data ('activeErrorNotifications', []);
				}

				if (validated)
				{
					uploadOptions.successCallBack (uploadFieldControl.value);
				}
			},
			onLoad            : function()
			{
				$(document).ready (function()
				{
					uploadOptions.onLoad (uploadFieldControl.value);
				});
			}
		}
	);

	uploadFieldControl.SetValue (uploadOptions.value);
	return uploadFieldControl;
}

var calendarObject = {};

function RenderCalendarControl (inputElement, options)
{
	this.calendarOptions = {
		name : null
	};

	$.extend (this.calendarOptions, options);

	if (!calendarObject[options.name])
	{
		calendarObject[options.name] = new CalendarWrapper (options.name);
	}

	$("#" + this.calendarOptions.name).on ("click", function (event)
	{
		$('#' + event.target.id).data ('edited', true);
		$('#' + event.target.id).change();
	});

	$("#" + this.calendarOptions.name).on ("keydown", function (event)
	{
		$('#' + event.target.id).data ('edited', true);
		$('#' + event.target.id).change();
	});

	$.validationEngineLanguage.allRules.future.alertText = options.phrases.futureDate;

	function FormCalendar (inputElement, calendarObject)
	{
		new ServerDateTimeCalendarWrapper (
		{
			calendarObject          : calendarObject,
			calendarInputElement    : inputElement,
			getServerTimeRequestUrl : "/platform/common/calendar/GetServerDateTime",
			validateTimeRequestUrl  : "/platform/common/calendar/ValidateDateTime"
		});
	}

	FormCalendar (inputElement, calendarObject[this.calendarOptions.name]);

	calendarObject[options.name].SetEpochDate (0);
	calendarObject[options.name].Create();

	var calendarName = options.name;

	$("#" + options.name + "Button").click (function (event)
	{
		calendarObject[calendarName].Draw();
	});
}


// Export
module.exports.FormElement = FormElement;
module.exports.RenderCalendarControl = RenderCalendarControl;
module.exports.RenderUploadControl = RenderUploadControl;
