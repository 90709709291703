
// Import
var $ = require ('striata-jquery');
var assert = require ('assert');
var CookieJar = require ('@control/cookies/cookiejar');
var StriataError = require ('@control/error/striataerror').StriataError;
var BuildPostForm = require ('@utility/form/form');


function LoginManager (loginDivId)
{
	var div = $('#' + loginDivId);
	assert (div.length == 1, "loginDivId " + loginDivId + " does not exist");

	this.loginDivId = loginDivId;

	this.passwordField = div.find ('input:password[name="password"]');
	this.userNameField = div.find ('input:text[name="userName"]');
	this.loginButton = div.find ('input:button[name="loginButton"]');

	this.loginButton.off ("click.loginmanager");
	this.loginButton.on ("click.loginmanager", HandleLogin.bind (this));

	this.passwordField.off ('keydown.loginmanager');
	this.passwordField.on ('keydown.loginmanager', (event) =>
		{
			if (event.keyCode == 13) // Enter key
			{
				HandleLogin.call (this);
			}
		}
	);

	$("#loginFormForgottenPassword").off ('click.loginmanager');
	$("#loginFormForgottenPassword").on ('click.loginmanager', (event) =>
		{
			var userName = this.userNameField.val() || '';
			$(window).SetFormCompare (BuildPostForm,
				[
					"/platform/login/login/ResetPassword/",
					{
						userName: userName
					}
				]
			);
		}
	);

	$('#loginFormSwitchUser').off ('click.loginmanager');
	$('#loginFormSwitchUser').on ('click.loginmanager', () =>
		{
			$(window).SetFormCompare (BuildPostForm,
				[
					"/platform/login/login/ShowLogin",
					{
						Logout: 1
					}
				]
			);
		}
	);
}



LoginManager.prototype.ShowLogin = function()
{
	$('#loginModalError').hide();
	$(window).Modal ('#loginModal', '#loginButtonCancel, #loginModalCloseXButton', {});
};



LoginManager.prototype.HideLogin = function()
{
	$.fn.ThrobberHide();
	$.fn.CloseModalWindow ('#'+this.loginDivId);
};



function HandleLogin()
{
	// Show the throbber before the AJAX request
	$("#modalContent").Throbber({display: "id:loginModal"});

	var userName = this.userNameField.val();
	var password = this.passwordField.val();

	//clear the password field so it wont be pre-filled when the modal is re-shown
	this.passwordField.val ("");

	$.ajax ({
		type: "POST",
		url: "/platform/login/login/DoLogin",
		data:
		{
			userName: userName,
			password: password,
			pageId :  window.pageId,
			zoneId:   window.selectedZoneId,
			csrf: window._csrf,
			showType: "json",
		},
		success: (data) =>
		{
			if (data.sessionId)
			{
				window.sessionId = data.sessionId;
				window.csrf = data.csrf;
				window.pageId = data.pageId;

				this.HideLogin();
			}
			else
			{
				var error = new StriataError;
				error.PopulateFromJson (data);
				$('#loginModalError').html (error.errorMessages[0].errorNarrative + ' (' + error.errorMessages[0].errorCode + ')');
				$('#loginModalError').show();
			}

			$(this).ThrobberHide();
		},
		error: () =>
		{
			$(this).ThrobberHide();
		},
	});
}


// Export
module.exports = LoginManager;

