var $ = require ('striata-jquery');

function ServerDateTimeCalendarWrapper (options)
{
	this.data = {
		calendarObject				: options.calendarObject,
		calendarInputElement		: options.calendarInputElement,
		getServerTimeRequestUrl		: options.getServerTimeRequestUrl,
		validateTimeRequestUrl		: options.validateTimeRequestUrl,
		getServerTimeRequestData	: options.getServerTimeRequestData || "",
		validateTimeRequestData		: options.validateTimeRequestData || "",
		ajaxAsyncRequest			: options.ajaxAsyncRequest || 1,
		ajaxContentType				: options.ajaxContentType || 'application/x-www-form-urlencoded',
		fetchServerDateTimeOnLoad	: options.fetchServerDateTimeOnLoad || 0
	};

	var thisObject = this;
	var thisObjectData = this.data;
	this.currentServerDateTime = null;

	this.Initialize = function (thisObject, data)
	{
		data.calendarObject.MouseUpEventHandler = function()
		{
			thisObject.ValidateCalendarDateTime (thisObject, data);
		};

		if ((!data.calendarInputElement.value) || (data.fetchServerDateTimeOnLoad == 1))
		{
			data.calendarObject.ShowCalendarEventHandler = function()
			{
				thisObject.GetServerDateTime();
			};
		}

		data.calendarInputElement.onblur = function()
		{
			if (data.calendarInputElement.value)
			{
				$(data.calendarInputElement).validationEngine ('hide');
				thisObject.ValidateInputElementDateTime (thisObject, data);
			}
		};

		thisObject.currentServerDateTime = thisObject.GetServerDateTime();
	};

	options.calendarObject.GetCurrentDateTime = function()
	{
		return thisObject.GetServerDateTime();
	};


	this.GetServerDateTime = function (GetTimeCallBack)
	{
		thisObjectData.getServerTimeRequestData = "dateTimeString=null" + thisObjectData.getServerTimeRequestData;
		thisObject.CalendarAjaxRequest (thisObjectData, thisObjectData.getServerTimeRequestUrl, thisObjectData.getServerTimeRequestData, function (response)
			{
				if (window._dynarch_popupCalendar)
				{
					window._dynarch_popupCalendar.parseDate (response.serverDateTime, "%Y-%m-%d %H:%M");
				}

				thisObject.currentServerDateTime = response.serverDateTime;
				if (GetTimeCallBack)
				{
					GetTimeCallBack();
				}
			}
		);

		return thisObject.currentServerDateTime;
	};


	this.ValidateInputElementDateTime = function (thisObject, data)
	{
		var inputElementValue = data.calendarInputElement.value;

		if (!inputElementValue.match (/^\s*((\$subst\('.+?'\)))/g))
		{
			if (!inputElementValue.match (/\d\d:\d\d:?(\d\d)?/g))
			{
				inputElementValue += " 00:00";
			}

			var dateTimeString = new Date (inputElementValue);

			dateTimeString = dateTimeString.getFullYear() + "," +
				(dateTimeString.getMonth() + 1) + "," +
				dateTimeString.getDate() + "," +
				dateTimeString.getHours() + "," +
				dateTimeString.getMinutes() + "," +
				dateTimeString.getSeconds();

			data.validateTimeRequestData = "dateTimeString=" + dateTimeString + data.validateTimeRequestData;

			thisObject.CalendarAjaxRequest (data, data.validateTimeRequestUrl, data.validateTimeRequestData, function (response)
			{
				if (response.dateTimeString == "error")
				{
					$(data.calendarInputElement).validationEngine ('showPrompt', pageStash.text.Invalid_Date_Format, '', 'topRight', true);
				}
				else
				{
					if (response.dateTimeString)
					{
						var validatedDateTimeString = response.dateTimeString.slice (0, -3);
						data.calendarInputElement.value = validatedDateTimeString;
					}

					if (window._dynarch_popupCalendar)
					{
						if (!window._dynarch_popupCalendar.params)
						{
							var inputElement = window._dynarch_popupCalendar.params.inputField;
							triggerEvent (inputElement, 'change');
						}
					}
				}
			});
		}
	};


	this.ValidateCalendarDateTime = function (thisObject, data)
	{
		var dateTimeString = window._dynarch_popupCalendar.date.print ('%Y,%m,%d,%H,%M,%S');

		data.validateTimeRequestData = "dateTimeString=" + dateTimeString + data.validateTimeRequestData;

		thisObject.CalendarAjaxRequest (data, data.validateTimeRequestUrl, data.validateTimeRequestData, function (response)
			{
				var validatedDateTimeString = response.dateTimeString.slice (0, -3);
				window._dynarch_popupCalendar.parseDate (response.dateTimeString, "%Y-%m-%d %H:%M");

				if (window._dynarch_popupCalendar.params)
				{
					var inputElement = window._dynarch_popupCalendar.params.inputField;
					triggerEvent (inputElement, 'change');
				}
				else
				{
					data.calendarInputElement.value = validatedDateTimeString;
				}
			}
		);
	};


	this.CalendarAjaxRequest = function (data, requestUrl, requestData, requestCallback)
	{
		ShowHideOverlay (1);

		var ajaxAsyncRequest = "false";
		if (data.ajaxAsyncRequest == 1)
		{
			ajaxAsyncRequest = "true";
		}

		var ajaxRequest = new XMLHttpRequest();
		ajaxRequest.open ("POST", requestUrl, ajaxAsyncRequest);
		ajaxRequest.setRequestHeader ("Content-type", data.ajaxContentType);
		ajaxRequest.send (requestData);

		ajaxRequest.onreadystatechange = function()
		{
			if (ajaxRequest.readyState == 4 && ajaxRequest.status == 200)
			{
				var response = JSON.parse (ajaxRequest.responseText);
				requestCallback (response);
				ShowHideOverlay (0);
			}
		};
	};


	function ShowHideOverlay (displayOverlay)
	{
		var calendarElementIndex = 0;
		var overlayElement;

		var calendarElements = document.querySelectorAll (".calendar");
		if (displayOverlay == 1)
		{
			for (calendarElementIndex = 0; calendarElementIndex < calendarElements.length; ++calendarElementIndex)
			{
				var overlay = document.createElement("div");
				overlay.style.position = "absolute";
				overlay.style.display = "inline";
				overlay.style.backgroundColor = "#ffffff";
				overlay.style.width = "100%";
				overlay.style.height = "100%";
				overlay.style.top = "0px";
				overlay.style.opacity = "0.7";
				overlay.style.filter = "alpha(opacity=70)";
				overlay.className = "calendarOverlay";

				overlayElement = calendarElements[calendarElementIndex].querySelectorAll (".calendarOverlay");
				if (overlayElement.length == 0)
				{
					calendarElements[calendarElementIndex].appendChild (overlay);
				}
			}
		}
		else
		{
			for (calendarElementIndex = 0; calendarElementIndex < calendarElements.length; ++calendarElementIndex)
			{
				overlayElement = document.querySelectorAll (".calendarOverlay");
				for (var overlayElementIndex = 0; overlayElementIndex < overlayElement.length; ++overlayElementIndex)
				{
					overlayElement[overlayElementIndex].parentNode.removeChild (overlayElement[overlayElementIndex]);
				}
			}
		}
	}


	function triggerEvent (calendarInputElement, event)
	{
		var newEvent;
		if (document.createEventObject)
		{
			newEvent = document.createEventObject();
			return calendarInputElement.fireEvent ('on' + event, newEvent);
		}

		newEvent = document.createEvent ("HTMLEvents");
		newEvent.initEvent (event, false, false);
		return calendarInputElement.dispatchEvent (newEvent);
	}


	this.Initialize (this, this.data);
}


// Exports
module.exports = ServerDateTimeCalendarWrapper;

