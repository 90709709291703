
// Import
var $ = require ('striata-jquery');
var _IsDefined = require ('@utility/utility')._IsDefined;
var _DefaultInit = require ('@utility/utility')._DefaultInit;
var _RequiredInStruct = require ('@utility/utility')._RequiredInStruct;


//ValidationErrorTypes are constructed in the control ErroType Properties on control construction

function ValidationErrorType (name, description, code)
{
	this.name = name;
	this.description = description;
	this.code = code;
}

function ValidationError (type, contextData)
{
	//Use a  predefined Validation Error Type as stored in the FOrmControl Base class
	this.type = type;

	//contextData is just a hash with helpful info regarding the error. Use this t pass info
	//needed for notification ie offending field names etc.
	this.context = contextData;
}

function GetControlsInElement (element)
{
	var controls = [], t = $(element).find("div").filter (
		function()
		{
			if (!$.hasData (this))
			{
				return false;
			}

			var data = $(this).data("control");
			if (typeof(data) !== "undefined")
			{
				return true;
			}

			return false;
		}
	);

	t.each(function()
		{
			controls.push ($.data(this,"control"));
		}
	);

	return controls;
}


function FormControl (id, data)
{
	this.RegisterWithParentDiv = function(id)
	{
		$("#"+id).data("control", this);
	};


	this.Render = function () {};
	this.OnReady = function () {};
	this.RunOnReady = function (value) { this.runOnReady = value };
	this.Clear = function () {};
	this.Reset = function () {};
	this.Draw = function () {};

	//validation
	//this.Validate() should return true or false, and call SetLastValidationErrors with an array of ValidationError Objects.
	this.Validate = function ()
	{
		//Validation code here.
		throw new Error ("FormControl.Validate is a virtual function that needs to be overloaded.");
	};

	this.IsRequired = function ()
	{
		return this.required;
	};

	//here we will store possible error types so we can easily go this.Error.REQUIRED for instance.
	this.ErrorType=
	{
			REQUIRED: new ValidationErrorType("Required", "Required field not supplied", 1),
	};

	this.AddControlErrorTypes = function (errorTypes)
	{
		Object.assign (this.ErrorType, errorTypes);
	};

	this.lastValidationErrors = [];
	this.GetLastValidationErrors = function () { return this.lastValidationErrors };
	this.SetLastValidationErrors = function (value) { this.lastValidationErrors = value };

	this.Initialise = function (id, data)
	{
		if (!_IsDefined(id))
		{
			throw new Error ("FormControl: Id is an required argument");
		}

		_RequiredInStruct(["name"], data, "UploadFieldInputControl");
		this.id = id;
		this.name              = data.name;
		this.required          = _DefaultInit(data.required, false);
		this.OnChange          = _DefaultInit(data.onChange, function () {});
		this.RegisterWithParentDiv(id);
	};


}


// Export
module.exports.FormControl = FormControl;
module.exports.ValidationErrorType = ValidationErrorType;
module.exports.ValidationError = ValidationError;
module.exports.GetControlsInElement = GetControlsInElement;

