
// Import
var $ = require ('striata-jquery');
var _DefaultInit = require ('@utility/utility')._DefaultInit;
var FormControl = require ('@control/form/control').FormControl;
var ValidationError = require ('@control/form/control').ValidationError;


function UploadFileInputControl (id, settingsData)
{
	function RemoveFilePath (fileName)
	{
		if (fileName)
		{
			var uploadFilenamePath = fileName.lastIndexOf ("\\");
			fileName = fileName.substring (uploadFilenamePath+1);
		}

		return fileName;
	}

	var thisControl = this;
	this._SuperInitialise = this.Initialise;
	this.Initialise = function (id, data)
	{
		//DO the super
		this._SuperInitialise (id, data);
		//Do our own initialization
		this.allowedExtensions  = _DefaultInit (data.allowedExtensions, null);
		this.warnOnlyErrorTypes = _DefaultInit (data.warnOnlyErrorTypes, null);
		this.validationRule     = _DefaultInit (data.validationRule, null);
		this.buttonText         = _DefaultInit (data.buttonText, null);
		this.selectText         = _DefaultInit (data.selectText, null);
		this.noFileText         = _DefaultInit (data.noFileText, null);
		this.buttonState        = _DefaultInit (data.buttonState, "visible");
		this.enabled            = _DefaultInit (data.enabled, false);
		this.sizeWarningLimit   = _DefaultInit (data.sizeWarningLimit, "0");
		this.sizeErrorLimit     = _DefaultInit (data.sizeErrorLimit, "0");
		this.fileSize           = _DefaultInit (data.fileSize, null);
		this.files              = _DefaultInit (data.files, null);

		this.selectOptions      = _DefaultInit (data.selectOptions,
		{
			elementType    : null,
			elementIdField : null
		});

		var uploadControl = this.Render();

		this.SetValue (_DefaultInit (data.value, ""));
		this.SetDefaultValue (this.GetValue());

		if (data.onLoad)
		{
			data.onLoad (uploadControl);
		}
	};

	//autorun Init on construction

	function TemporarilyBlockEscape()
	{
		var keyListeningTarget = $('body');
		var mouseOverListeningTarget = document;
		var keyEvent = "keyup";
		var UnBindEvents = function()
		{
			function DelayedUnbind ()
			{
				$(keyListeningTarget).off (keyEvent + ".fileupload");
				$(mouseOverListeningTarget).off ("mouseover.fileupload mousemove.fileupload");
			}
			setTimeout(DelayedUnbind,280);
		};
		var OnKeyEvent = function (event)
		{
			if (event.which == 27)
			{
				event.preventDefault();
				event.stopPropagation();
				UnBindEvents();
			}
			return false;
		};
		$(keyListeningTarget).on (keyEvent + ".fileupload", OnKeyEvent);
		$(mouseOverListeningTarget).on ("mouseover.fileupload mousemove.fileupload", UnBindEvents);
	}

	this.Render = function()
	{
		var id = this.id;
		var name = this.name;
		var renderInElement = document.querySelectorAll ("#" + id)[document.querySelectorAll ("#" + id).length - 1];

		if (renderInElement && !renderInElement.children.length)
		{
			var ghostInputFieldContainer = document.createElement ("div");
			ghostInputFieldContainer.id = "ghostInputFieldDiv_" + id;
			ghostInputFieldContainer.className = "uploadElement";
			ghostInputFieldContainer.style.float = "left";

			renderInElement.appendChild (ghostInputFieldContainer);

			var dummyInput = document.createElement ("input");
			dummyInput.id = "savedFilename_" + id;
			dummyInput.name = "savedFilename_" + id;
			dummyInput.className = "inputDisabled";
			dummyInput.setAttribute ("type", "text");
			dummyInput.setAttribute ("readonly", "readonly");
			dummyInput.style.float = "left";
			dummyInput.placeholder = "No file selected...";

			var validationClass = "validate[required]";

			if (this.validationRule)
			{
				validationClass = this.validationRule;
			}

			if (this.required)
			{
				$(dummyInput).addClass (validationClass);
			}

			ghostInputFieldContainer.appendChild (dummyInput);

			var browseButton = document.createElement ("input");
			browseButton.id = "fileUploadBrowseButton_" + id;
			browseButton.className = "browseButton enabledButton";
			browseButton.setAttribute ("type", "button");
			browseButton.setAttribute ("value", this.buttonText);
			browseButton.style.float = "left";
			browseButton.style.marginLeft = "3px";

			var selectElementButton = document.createElement ("input");
			selectElementButton.id = "selectElementButton_" + id;
			selectElementButton.className = "browseButton enabledButton";
			selectElementButton.setAttribute ("type", "button");
			selectElementButton.setAttribute ("value", this.selectText);
			selectElementButton.style.float = "left";
			selectElementButton.style.display = "none";
			selectElementButton.style.marginLeft = "3px";

			var selectedElementIdField = document.createElement ("input");
			selectedElementIdField.name = "selectedElementId";
			selectedElementIdField.setAttribute ("type", "hidden");

			if (thisControl.selectOptions.elementType)
			{
				thisControl.selectOptions.elementIdField = selectedElementIdField;
				selectElementButton.style.display = "inline-block";

				selectElementButton.onclick = function()
				{
					DrawSelectElement (thisControl.selectOptions);
				};
			}

			ghostInputFieldContainer.appendChild (browseButton);
			ghostInputFieldContainer.appendChild (selectElementButton);
			ghostInputFieldContainer.appendChild (selectedElementIdField);

			var ghostButtonWrapper = document.createElement ("div");
			ghostButtonWrapper.id = "uploadGhostButtonWrapper";
			ghostButtonWrapper.style.position = "absolute";
			ghostButtonWrapper.style.overflow = "hidden";

			ghostInputFieldContainer.appendChild (ghostButtonWrapper);

			var toggleBrowse = document.createElement ("div");
			toggleBrowse.className = "toggleUploadButton";
			toggleBrowse.setAttribute ("buttonState", "hidden");
			toggleBrowse.id = "filePreview" + id;

			ghostInputFieldContainer.appendChild (toggleBrowse);

			var ghostButton = document.createElement ("input");
			ghostButton.id = "uploadGhostButton_" + id;
			ghostButton.name = name;
			ghostButton.className = "uploadGhostButton";
			ghostButton.setAttribute ("type", "file");
			ghostButton.setAttribute ("tabindex", "-1");

			if (this.allowedExtensions && this.allowedExtensions.length)
			{
				var exts = this.allowedExtensions.map (function (ext) { return "." + ext; }).join()
				ghostButton.setAttribute ("accept", exts);
			}

			ghostButton.style.pointer = "cursor";
			ghostButton.style.display = "inline";
			ghostButton.style.opacity = "0";
			ghostButton.style.width = "100%";

			ghostButtonWrapper.appendChild (ghostButton);
			$('#uploadGhostButton_' + id).height ($('#fileUploadBrowseButton_' + id).outerHeight());

			setTimeout (function()
			{
				if (thisControl.enabled)
				{
					thisControl.Enable();
				}
				else
				{
					thisControl.Disable();
				}
			}, 0);

			// Now the internally used events
			$('#uploadGhostButton_' + id).on ('click', function()
			{
				this.value = "";
				thisControl.SetValue ("");
				var element = $('#uploadGhostButton_' + this.id);
				var replacement = element.clone (true);
				element.replaceWith (replacement);
				replacement.on ('click', TemporarilyBlockEscape);
				TemporarilyBlockEscape();
			});

			$('#uploadGhostButton_' + id).on ('change', this._onChange);

			var uploadElement = renderInElement.querySelectorAll (".uploadElement")[0];
			var containerWidth = $(uploadElement.parentElement).outerWidth();

			var browseButtonWidth;
			var toggleButtonWidth;

			if (this.buttonState == "hidden")
			{
				$(browseButton).hide();
				$(ghostButtonWrapper).hide();
				toggleButtonWidth = $(uploadElement.children[5]).outerWidth() + 10;
				browseButtonWidth = 0;
			}
			else
			{
				$(browseButton).show();
				$(ghostButtonWrapper).show();
				$(toggleBrowse).hide();
				browseButtonWidth = $(uploadElement.children[1]).outerWidth() + parseInt (uploadElement.children[1].style.marginLeft, 10);
				toggleButtonWidth = 10;
				if (this.buttonState == "disabled")
				{
					thisControl.Disable();
				}
			}

			dummyInput.style.width = containerWidth - browseButtonWidth - toggleButtonWidth + "px";
			ghostButtonWrapper.style.height = $(uploadElement.children[1]).outerHeight() + "px";

			return ghostInputFieldContainer;
		}
	};

	this.Disable = function()
	{
		var id = this.id;
		$("#fileUploadBrowseButton_" + id).removeClass ("enabledButton");
		$("#fileUploadBrowseButton_" + id).addClass ("disabledButton");
		$("#uploadGhostButton_" +  id).css ({zIndex: -1});
		$("#uploadGhostButton_" +  id).prop ('disabled', true);

		this.enabled = false;
		return true;
	};
	this.Enable = function()
	{
		var id = this.id;
		//alert ('Enabling '+id);
		$("#fileUploadBrowseButton_" + id).addClass ("enabledButton");
		$("#fileUploadBrowseButton_" + id).removeClass ("disabledButton");
		$("#uploadGhostButton_" +  id).css ({zIndex: ""});
		$("#uploadGhostButton_" +  id).prop ('disabled', false);

		this.enabled = true;
	};

	this.SetDefaultValue = function (value)
	{
		this.defaultValue = value;
	};

	this.SetValue = function (value)
	{
		if ($('#savedFilename_' + this.id)[0])
		{
			this.value = RemoveFilePath (value);
			$('#savedFilename_' + this.id).val (value);
			$('#savedFilename_' + this.id)[0].defaultValue = "";
		}
	};

	this.GetValue = function ()
	{
		return this.value;
	};

	this.GetDefaultValue = function()
	{
		return this.defaultValue;
	};

	this.GetFiles = function()
	{
		return this.files;
	};

	this.HasChanged = function()
	{
		return (this.value != this.defaultValue);
	};

	//the private onchange handler
	this._onChange = function (eventData)
	{
		var uploadControl = document.getElementById (thisControl.id);
		thisControl.files = this.files;
		thisControl.fileSize = this.files[0] ? parseInt (this.files[0].size) / 1024 : 0; // bytes to KiB

		if (this.value != "")
		{
			//since we are attaching the following to the onchange event of the DOM file upload field "this" points to that input field
			thisControl.SetValue (RemoveFilePath (this.value));
			thisControl.OnChange (eventData);
		}
		else
		{
			uploadControl.querySelectorAll (".uploadElement")[0].firstChild.value = "";
		}
	};

	//Validation
	this.AddControlErrorTypes(
	{
			INVALID_EXTENSION   : new ValidationError ("ExtensionNotAllowed", "Filename does not have an allowed extension.", 100),
			SIZE_LIMIT_EXCEEDED : new ValidationError ("SizeLimitExceeded", "File size byte limit exceeded.", 101),
			SIZE_LIMIT_WARNING  : new ValidationError ("SizeLimitWarning", "Recommended maximum file size limit exceeded.", 102)
	});

	this.SetExtensions = function (extensionsArray, disableValidations)
	{
		if (!disableValidations)
		{
			this.allowedExtensions = extensionsArray
		};

		if (extensionsArray && extensionsArray.length && $('#uploadGhostButton_' + this.id)[0])
		{
			var exts = extensionsArray.map (function (ext) { return "." + ext; }).join()
			$('#uploadGhostButton_' + this.id)[0].setAttribute ("accept", exts);
		}
	}

	this.Validate = function()
	{
		var errors = [];
		var fileName = this.value;

		if (! this.enabled)
		{
				return true;
		}

		if (fileName)
		{
			var context, error;
			if (fileName.length == 0 && this.required == true)
			{
				context = { fieldId: '#savedFilename_' + thisControl.id };
				error = new ValidationError ( this.ErrorType.REQUIRED, context );
				errors.push (error);
			}

			//Check extensions
			if (fileName.length != 0 && this.allowedExtensions.length != 0)
			{
				var validated = TestFileExtension (fileName, this.allowedExtensions);
				if ( validated != true)
				{
					context = { fieldId: '#savedFilename_' + thisControl.id };
					error = new ValidationError ( this.ErrorType.INVALID_EXTENSION, context );
					errors.push (error);
				}
			}

			//Check file size limits.
			var sizeWarningLimit = parseInt (thisControl.sizeWarningLimit) || 0;
			var sizeErrorLimit = parseInt (thisControl.sizeErrorLimit) || 0;

			if (sizeWarningLimit != 0 && thisControl.fileSize > sizeErrorLimit)
			{
				context = { fieldId: '#savedFilename_' + thisControl.id };
				error = new ValidationError (this.ErrorType.SIZE_LIMIT_EXCEEDED, context);
				errors.push (error);
			}
			else if (sizeWarningLimit != 0 && thisControl.fileSize > sizeWarningLimit)
			{
				this.warnOnlyErrorTypes = Array.isArray (this.warnOnlyErrorTypes) ? this.warnOnlyErrorTypes : [];

				if (!this.warnOnlyErrorTypes.includes (this.ErrorType.SIZE_LIMIT_WARNING))
				{
					this.warnOnlyErrorTypes.push (this.ErrorType.SIZE_LIMIT_WARNING);
				}

				context = { fieldId: '#savedFilename_' + thisControl.id };
				error = new ValidationError (this.ErrorType.SIZE_LIMIT_WARNING, context);
				errors.push (error);
			}
		}

		this.SetLastValidationErrors (errors);

		if (errors.length == 0)
		{
			return true;
		}

		var warnOnlyCount = 0;
		if (typeof (this.warnOnlyErrorTypes) != "undefined" && this.warnOnlyErrorTypes !== null)
		{
			if (this.warnOnlyErrorTypes.length != 0)
			{
				errors.forEach (function (error)
				{
					var warnOnly = false;

					thisControl.warnOnlyErrorTypes.forEach (function (errorType)
					{
						if (error.type == errorType)
						{
							warnOnly = true;
						}
					});

					if (warnOnly)
					{
						warnOnlyCount++;
					}
				});
			}
		}

		// if we only have warn-only errors, return true.
		if (warnOnlyCount == errors.length)
		{
			return true;
		}

		return false;
	};
}

UploadFileInputControl.prototype=new FormControl();
UploadFileInputControl.prototype.constructor = UploadFileInputControl;


function TestFileExtension (fileName, allowedFileExtensions)
{
	if ((typeof (fileName) == "undefined" ) || (fileName == "") || (fileName === null) )
	{
		return false;
	}

	var regSpec = '(.([^.]*$))';
	var regex = new RegExp (regSpec);
	var result = regex.exec (fileName);
	var extension = "";

	try
	{
		extension = result[2];
	}
	catch (error)
	{
		if (fileName === '')
		{
			return false;
		}
	}


	var validated = false;
	for (var i=0; i<allowedFileExtensions.length; i++)
	{
		if (allowedFileExtensions[i].toUpperCase() == extension.toUpperCase() || allowedFileExtensions[i] == "*")
		{
			validated = true;
			break;
		}
	}
	return validated;
}

function DrawSelectElement (options)
{
	var parentElement = options.parentElement;
	var selectPartType = options.partType;

	var selectConfig = options.config;
	selectConfig.elementIdField = options.elementIdField;

	selectConfig.successCallBack = function (data){};

	$(parentElement).Modal ("#select_template", "#modalCloseCreateCancel,#modalCloseCreateXButton,#selectElementClose", options);
	ReloadSelectElementTable (selectPartType, selectConfig);
}


// Export
module.exports = UploadFileInputControl;

