// Import
require ('./form.css');
require ('@control/fileupload/fileupload.css');

var $ = require ('striata-jquery');
var FormElement = require ('@control/form/formelement').FormElement;
var UpdateFormDetails = require ('@control/formchangemanager/jquery.formchangemanager').UpdateFormDetails;


function FormGenerator (formAttributes)
{
	this.formAttributes = {
		id       : null,
		name     : null,
		action   : null,
		method   : "post",
		useXhr   : true,
		useForm  : true,
		target   : null,
		enctype  : "multipart/form-data",
		parentId : document.body,
		parent   : null,
		theme    : null,
		data     : {}
	};

	this.panels = [];

	this.id = 1;
	this.fieldEvents = [];
	this.fields = [];
	this.form = this;

	$.extend (this.formAttributes, formAttributes);

	if (!this.formAttributes.parent)
	{
		this.formAttributes.parent = document.getElementById (formAttributes.parentId);
	}

	var formContainer = this.formElement = CreateFormContainer (this.formAttributes);
	var formPanel = CreateFormPanel (this.formAttributes, this.panels);
	formContainer.appendChild (formPanel);

	var formFragment = document.createDocumentFragment();
	formFragment.appendChild (formContainer);

	$.extend (this.formAttributes, {fragment: formFragment});

	function CreateFormContainer (attributes)
	{
		var formExists = parentFormExists (attributes.parent);
		var container;

		if (!formExists && attributes.useForm)
		{
			container = document.createElement ('form');
			container.enctype = "multipart/form-data";
			container.method = "post";
		}
		else
		{
			container = document.createElement ('fieldset');
		}

		container.id = attributes.name;
		container.style.border = "0px";
		container.style.padding = "0px";
		container.style.marginInlineStart = "0px";
		container.style.marginInlineEnd = "0px";

		return container;
	}

	function CreateFormPanel (attributes, panels)
	{
		var panel = document.createElement ('div');
		panel.id = attributes.name + "Panel_" + panels.length;
		panel.name = attributes.name + "Panel";
		panel.className = attributes.theme + " formPanel";

		var fieldsTable = document.createElement ('table');
		fieldsTable.className = attributes.theme + " formTable";
		fieldsTable.border = "0";

		panel.appendChild (fieldsTable);
		panels.push (panel);

		return panel;
	}

	function parentFormExists (targetElement)
	{
		var parentElement = targetElement.parentNode;

		while (parentElement !== document)
		{
			if (parentElement.nodeName == "FORM")
			{
				return true;
			}

			parentElement = parentElement.parentNode;
		}

		return false;
	}
}



FormGenerator.prototype.AddPanel = function (attributes, panels)
{
	var panel = document.createElement ('div');
	panel.id = attributes.name + "Panel_" + panels.length;
	panel.name = attributes.name + "Panel";
	panel.className = "formPanel";

	var fieldsTable = document.createElement ('table');
	fieldsTable.className = attributes.theme + " formTable";
	fieldsTable.border = "0";

	panel.appendChild (fieldsTable);
	panels.push (panel);

	this.formAttributes.fragment.firstChild.appendChild (panel);

	return panel;
};



FormGenerator.prototype.AddField = function (fieldAttributes)
{
	this.fieldAttributes = {
		form           : this,
		id             : null,
		name           : null,
		inlineGroup    : null,
		hidable        : null,
		type           : null,
		value          : null,
		disabled       : true,
		manditory      : false,
		parentId       : null,
		helpText       : null,
		validationRule : null,
		forceValidate  : false,
		options        : [],
		cssStyle       : null,
		attributes     : null,
		conditions     : null,
		constraints    : {},
		theme          : this.formAttributes.theme,
		phrases        :
		{
			preText  : "",
			postText : ""
		},
	};

	var formFragment;

	$.extend (this.fieldAttributes, fieldAttributes);

	if (this.fieldAttributes.parentId)
	{
		formFragment = this.formAttributes.fragment.querySelector ("#" + this.fieldAttributes.parentId);

		if (formFragment)
		{
			formFragment = formFragment.querySelector (".formTable") || formFragment;
		}
		else
		{
			formFragment = this.formAttributes.fragment.querySelector (".formTable") || formFragment;
		}
	}
	else
	{
		formFragment = this.formAttributes.fragment.querySelector (".formTable");
	}

	if (!formFragment)
	{
		return;
	}

	var row = CreateFieldRow (formFragment, this.fieldAttributes);
	var field;
	var originalId = this.fieldAttributes.id;
	var requiredElement;

	this.fieldAttributes.id = this.fieldAttributes.id + "FieldId" + this.id.toString();

	switch (this.fieldAttributes.type)
	{
		case 'text':
			field = FormElement.Text (row, this.fieldAttributes);
			break;
		case 'textarea':
			field = FormElement.TextArea (row, this.fieldAttributes);
			break;
		case 'image':
			field = FormElement.Image (row, this.fieldAttributes);
			break;
		case 'hidden':
			field = FormElement.Hidden (row, this.fieldAttributes);
			break;
		case 'upload':
			this.fieldAttributes.id = originalId;
			this.formAttributes.useXhr = false;
			field = FormElement.Upload (row, this.fieldAttributes);
			break;
		case 'select':
			field = FormElement.Select (row, this.fieldAttributes);
			break;
		case 'multiselect':
			field = FormElement.MultiSelect (row, this.fieldAttributes);
			break;
		case 'checkbox':
			field = FormElement.Checkbox (row, this.fieldAttributes);
			break;
		case 'radio':
			field = FormElement.Radio (row, this.fieldAttributes);
			break;
		case 'range':
			field = FormElement.Range (row, this.fieldAttributes);
			break;
		case 'string':
			this.fieldAttributes.id = originalId;
			field = FormElement.String (row, this.fieldAttributes);
			break;
		case 'template':
			field = FormElement.Template (row, this.fieldAttributes);
			break;
		case 'details':
			field = FormElement.Details (row, this.fieldAttributes);
			break;
		case 'freeform':
			this.fieldAttributes.id = originalId;
			field = FormElement.Freeform (row, this.fieldAttributes);
			break;
		case 'block':
			this.fieldAttributes.id = originalId;
			field = FormElement.Block (row, this.fieldAttributes);
			break;
		case 'icon':
			this.fieldAttributes.id = originalId;
			field = FormElement.Icon (row, this.fieldAttributes);
			break;
		case 'calendar':
			this.fieldAttributes.id = originalId;
			field = FormElement.Calendar (row, this.fieldAttributes);
			break;
		case 'tags':
			this.fieldAttributes.id = originalId;
			field = FormElement.Tags (row, this.fieldAttributes);
			UpdateFormDetails (this.fieldAttributes.name + "FieldContainer");
			break;
		case 'submit':
			this.fieldAttributes.id = originalId;
			field = FormElement.Submit (row, this.formAttributes, this.fieldAttributes);
			break;
		default:
	}

	this.fields.push (field);
	this.id++;

	// Add element display conditions.
	if (this.fieldAttributes.conditions)
	{
		var conditionElementId = Object.keys (this.fieldAttributes.conditions);

		for (var index = 0; index <= conditionElementId.length - 1; ++index)
		{
			if (conditionElementId)
			{
				requiredElement = row.parentElement.querySelectorAll ("[name='" + conditionElementId[index] + "']")[0];

				var changeAttributes = {
					requiredElement : requiredElement,
					actionElement   : field,
					requiredValues  : this.fieldAttributes.conditions,
					hidable         : this.fieldAttributes.hidable,
					inlineGroup     : this.fieldAttributes.inlineGroup
				};

				this.fieldEvents.push (changeAttributes);
			}
		}
	}

	// Modify element CSS styles
	if (this.fieldAttributes.cssStyle)
	{
		for (var cssRule in this.fieldAttributes.cssStyle)
		{
			if (field)
			{
				field.style[cssRule] = this.fieldAttributes.cssStyle[cssRule];
			}
		}
	}

	// Add text before and after form input field.
	if (this.fieldAttributes.phrases)
	{
		if (this.fieldAttributes.phrases.preText)
		{
			var preText = document.createElement ("span");

			if (this.fieldAttributes.disabled)
			{
				preText.className = "fieldTextDisabled";
			}
			else
			{
				preText.className = "fieldText";
			}

			preText.innerText = this.fieldAttributes.phrases.preText;
			field.parentElement.insertBefore (preText, field);
		}

		if (this.fieldAttributes.phrases.postText)
		{
			var postText = document.createElement ("span");

			if (this.fieldAttributes.disabled)
			{
				postText.className = "fieldTextDisabled";
			}
			else
			{
				postText.className = "fieldText";
			}

			postText.innerText = this.fieldAttributes.phrases.postText;
			field.parentElement.appendChild (postText);
		}
	}

	function CreateFieldRow (parentTable, attributes)
	{
		var row;

		// We need to have a special case for 'radio' inputs, as these elements are grouped.

		var inlineGroup = parentTable.querySelectorAll ("[id='" + attributes.inlineGroup + "']")[0] ||
				parentTable.querySelectorAll ("[name='" + attributes.inlineGroup + "']")[0];

		if (attributes.inlineGroup && inlineGroup)
		{
			row = inlineGroup.parentElement.parentElement.parentElement;
			return row;
		}

		row = document.createElement ('tr');
		parentTable.appendChild (row);

		if (attributes.type != "hidden")
		{
			if (!attributes.greedy)
			{
				var mandatoryColumn = document.createElement ('td');
				mandatoryColumn.className = "mandatoryColumn";
				if (attributes.manditory)
				{
					mandatoryColumn.innerHTML = "*";
				}
				row.appendChild (mandatoryColumn);

				var descriptionColumn = document.createElement ('td');
				descriptionColumn.className = attributes.theme + " descriptionColumn";

				if (attributes.description)
				{
					descriptionColumn.innerHTML = attributes.description;
					FormGenerator.fieldDescription = attributes.description;
				}

				row.appendChild (descriptionColumn);
			}

			var fieldParent = document.createElement ('td');
			fieldParent.className = "fieldParent";
			row.appendChild (fieldParent);

			if (!attributes.greedy)
			{
				var helpColumn = document.createElement ('td');
				helpColumn.className = "helpColumn";
				row.appendChild (helpColumn);

				if (attributes.helpText)
				{
					var helpElement = $("#" + attributes.helpText).clone (true);
					helpElement.prop ("id", attributes.helpText + "_dynamic" + attributes.theme);

					if (helpElement)
					{
						$(helpColumn).append (helpElement);
					}
				}
			}
			else
			{
				fieldParent.setAttribute ("colspan", "4");
			}
		}
		else
		{
			var formDataField = document.createElement ('td');
			formDataField.className = "fieldParent";
			row.appendChild (formDataField);
		}

		return row;
	}

	return field;
};



FormGenerator.prototype.AddValidationRule = function (ruleName, rule)
{
	AddValidationRule (ruleName, rule);
};



FormGenerator.prototype.Generate = function()
{
	var formFragment = this.formAttributes.fragment.querySelector (".formPanel").parentElement;
	var fieldEvents = this.fieldEvents;
	var uploadFragment = this.formAttributes.fragment.querySelector (".formPanel");

	this.formAttributes.parent.appendChild (formFragment);

	for (var fieldEvent in fieldEvents)
	{
		if (fieldEvents[fieldEvent].inlineGroup || fieldEvents[fieldEvent].hidable)
		{
			fieldEvents[fieldEvent].actionElement.parentElement.style.display = "none";
		}
		else
		{
			if (!(fieldEvents[fieldEvent].actionElement instanceof HTMLDivElement))
			{
				fieldEvents[fieldEvent].actionElement.parentElement.style.display = "inline";
				fieldEvents[fieldEvent].actionElement.setAttribute ("disabled", "disabled");
				fieldEvents[fieldEvent].actionElement.classList.add ("inputDisabled");
			}
		}

		for (var condition in fieldEvents[fieldEvent].requiredValues)
		{
			var eventFieldValue;

			var fieldElements = formFragment.querySelectorAll ("select, input");

			for (var conditionalField in fieldElements)
			{
				if (fieldElements[conditionalField].nodeType)
				{
					fieldElements[conditionalField].addEventListener ("change", function (event)
					{
						DrawRequiredElements (event.target, event.target.form.parentElement);
						event.preventDefault();
					});

					DrawRequiredElements (fieldElements[conditionalField], this.formAttributes.parent);
				}
			}
		}
	}

	(function()
	{
		// Observe changes to upload box model.
		var fileFieldObserver = new MutationObserver (function (mutations)
		{
			mutations.forEach (function (mutation)
			{
				if (uploadFragment.querySelectorAll (".uploadElement")[0])
				{
					if (mutation.attributeName == 'class')
					{
						for (var index in document.querySelectorAll ("div[class='uploadElement']"))
						{
							if (document.querySelectorAll (".uploadElement")[index].clientWidth)
							{
								var uploadElement = document.querySelectorAll (".uploadElement")[index];
								var browseButtonWidth = uploadElement.children[1].offsetWidth + parseInt (uploadElement.children[1].style.marginLeft, 10);
								var selectButtonWidth = uploadElement.children[2].offsetWidth + parseInt (uploadElement.children[2].style.marginLeft, 10);
								var toggleButtonWidth = uploadElement.children[5].offsetWidth + 10;

								if (!$(uploadElement.children[0]).hasClass ("formError"))
								{
									uploadElement.style.width = uploadElement.parentElement.clientWidth + "px";
									uploadElement.children[0].style.width = (uploadElement.parentElement.clientWidth - browseButtonWidth - selectButtonWidth - toggleButtonWidth) + "px";
									uploadElement.children[4].style.width = uploadElement.parentElement.clientWidth - selectButtonWidth + "px";
									uploadElement.children[5].style.float = "right";
								}

								uploadElement.children[5].onclick = function (event, browseButtonWidth, selectButtonWidth, toggleButtonWidth)
								{
									var uploadElement = event.target.parentElement;

									if (uploadElement.children[5].getAttribute ("buttonState") == "hidden")
									{
										$(uploadElement.children[1]).show();
										$(uploadElement.children[2]).show();
										$(uploadElement.children[5]).show();

										event.target.setAttribute ("buttonState", "visible");

										var hideImage = require ('@control/fileupload/hideupload.png').default;
										uploadElement.children[5].style.backgroundImage = 'url("' + hideImage + '")';

										browseButtonWidth = uploadElement.children[1].offsetWidth + parseInt (uploadElement.children[1].style.marginLeft, 10);
										selectButtonWidth = uploadElement.children[2].offsetWidth + parseInt (uploadElement.children[2].style.marginLeft, 10);
									}
									else
									{
										$(uploadElement.children[1]).hide();
										$(uploadElement.children[2]).hide();
										$(uploadElement.children[5]).hide();

										event.target.setAttribute ("buttonState", "hidden");

										var showImage = require ('@control/fileupload/showupload.png').default;
										uploadElement.children[5].style.backgroundImage = 'url("' + showImage + '")';

										browseButtonWidth = 0;
										selectButtonWidth = 0;
									}

									uploadElement.children[0].style.width = (uploadElement.parentElement.clientWidth - browseButtonWidth - selectButtonWidth - toggleButtonWidth) + "px";
									uploadElement.children[5].style.width = uploadElement.parentElement.clientWidth - toggleButtonWidth + "px";
								};

								fileFieldObserver.disconnect();
							}
						}
					}
				}
			});
		});

		fileFieldObserver.observe (document, {attributes: true, subtree: true});
	})();

	function DrawRequiredElements (element, parentElement)
	{
		if (element.getAttribute ("type") == "checkbox")
		{
			eventFieldValue = element.checked;
		}
		else if (element.getAttribute ("type") == "radio")
		{
			if (parentElement.querySelectorAll ('input[name="' + element.name + '"]:checked')[0])
			{
				eventFieldValue = parentElement.querySelectorAll ('input[name="' + element.name + '"]:checked')[0].value;
			}
		}
		else
		{
			eventFieldValue = element.value;
		}

		for (fieldEvent in fieldEvents)
		{
			var selectedElementValue;
			var requiredElementNames = Object.keys (fieldEvents[fieldEvent].requiredValues);

			for (var index in requiredElementNames)
			{
				if (fieldEvents[fieldEvent].requiredElement && fieldEvents[fieldEvent].requiredElement.getAttribute ("type") == "checkbox")
				{
					selectedElementValue = fieldEvents[fieldEvent].requiredElement.checked;
				}
				else if (fieldEvents[fieldEvent].requiredElement && fieldEvents[fieldEvent].requiredElement.getAttribute ("type") == "radio")
				{
					if (parentElement.querySelectorAll ('input[name="' + element.name + '"]:checked')[0])
					{
						selectedElementValue = parentElement.querySelectorAll ('input[name="' + element.name + '"]:checked')[0].value;
					}
				}
				else if (fieldEvents[fieldEvent].requiredElement)
				{
					selectedElementValue = fieldEvents[fieldEvent].requiredElement.value;
				}

				var requiredValues = fieldEvents[fieldEvent].requiredValues[requiredElementNames[index]];
				if (Array.isArray (requiredValues) == false)
				{
					requiredValues = [requiredValues];
				}
				for (var valueIndex in requiredValues)
				{
					if (requiredValues[valueIndex] == selectedElementValue)
					{
						if (fieldEvents[fieldEvent].requiredElement && fieldEvents[fieldEvent].requiredElement.getAttribute ("type") == "radio")
						{
							if (parentElement.querySelectorAll ('input[name="' + element.name + '"]:checked')[0].value == selectedElementValue)
							{
								fieldEvents[fieldEvent].actionElement.parentElement.style.display = "inline";
							}
						}
						else
						{
							fieldEvents[fieldEvent].actionElement.parentElement.style.display = "inline";
						}

						if (!fieldEvents[fieldEvent].requiredElement.hasAttribute ("disabled"))
						{
							fieldEvents[fieldEvent].actionElement.removeAttribute ("disabled");
						}
					}
				}
			}

			for (condition in fieldEvents[fieldEvent].requiredValues)
			{
				for (var requiredValue in fieldEvents[fieldEvent].requiredValues)
				{
					var requiredElements = parentElement.querySelectorAll ("[name='" + requiredValue + "']");

					for (index in requiredElements)
					{
						if (requiredElements[index].parentElement && requiredElements[index].nodeName)
						{
							if (requiredElements[index].parentElement.style.display == "none")
							{
								fieldEvents[fieldEvent].actionElement.parentElement.style.display = "none";
							}
						}
					}
				}

				var eventRequiredValues = fieldEvents[fieldEvent].requiredValues[condition];
				if (Array.isArray (eventRequiredValues) == false)
				{
					eventRequiredValues = [eventRequiredValues];
				}

				if (fieldEvents[fieldEvent].requiredElement && element.name == fieldEvents[fieldEvent].requiredElement.name)
				{
					if (fieldEvents[fieldEvent].inlineGroup || fieldEvents[fieldEvent].hidable)
					{
						fieldEvents[fieldEvent].actionElement.parentElement.style.display = "none";

						if (eventRequiredValues.map (String).includes (eventFieldValue))
						{
							fieldEvents[fieldEvent].actionElement.parentElement.style.display = "inline";
						}
					}
					else
					{
						if (!(fieldEvents[fieldEvent].actionElement instanceof HTMLDivElement))
						{
							fieldEvents[fieldEvent].actionElement.setAttribute ("disabled", "disabled");
							fieldEvents[fieldEvent].actionElement.classList.add ("inputDisabled");

							if (eventRequiredValues.map (String).includes (eventFieldValue))
							{
								if (!fieldEvents[fieldEvent].requiredElement.hasAttribute ("disabled"))
								{
									fieldEvents[fieldEvent].actionElement.parentElement.style.display = "inline";
									fieldEvents[fieldEvent].actionElement.removeAttribute ("disabled");
									fieldEvents[fieldEvent].actionElement.classList.remove ("inputDisabled");
								}
							}
						}
						else
						{
							fieldEvents[fieldEvent].actionElement.parentElement.style.display = "inline";
						}
					}
				}
			}
		}
	}
};

FormGenerator.prototype.GetFieldValues = function()
{
	var permittedTypes = ["TEXT", "TEXTAREA", "SELECT"];
	var activeForm = document.getElementById (this.formAttributes.id);
	var activeFormObject = {};

	for (var index = 0; index < activeForm.elements.length; index++)
	{
		if ($.inArray (activeForm.elements[index].tagName, permittedTypes) > 0)
		{
			activeFormObject[activeForm.elements[index].name] = activeForm.elements[index].value;
		}
	}

	return activeFormObject;
};

function AddValidationRule (ruleName, rule)
{
	if ($.validationEngineLanguage.allRules)
	{
		$.validationEngineLanguage.allRules[ruleName] = rule;
	}
}

// Export
module.exports.FormGenerator = FormGenerator;
module.exports.AddValidationRule = AddValidationRule;
