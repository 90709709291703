
// Import
require ('@include/common/help/glossary.css');
require ('@include/common/help/pagehelp.css');

var $ = require ('striata-jquery');
var StriataError = require ('@control/error/striataerror').StriataError;
var RequestDispatcher = require ('@control/requestdispatcher/requestdispatcher').RequestDispatcher;
var HighestZIndex = require ('@utility/utility').HighestZIndex;
var BindEventsForGlossary = require ('@include/common/help/glossary');

var modalList = require ('@control/dialog/jquery.modal').modalList;



var overviewNonDisplayIds = ['Help_PageResetPassword', 'Help_PageUserLoginAccountUpdate', 'Help_LayoutEditor'];



function SetPageHelpId (id)
{
	$('#pageHelpXButton').trigger ('click'); //close any current page help
	$('.mainPageHelp').attr ("id", id);
}



function PrepareContextualHelp()
{
	function ToolTip (currentElement, text)
	{
		//get the positions
		var position = $(currentElement).offset();

		//remove the css attribute
		$('#toolTip').css ({'width': 'auto'});
		$('#toolTip').css ({'z-index': HighestZIndex() + 1});

		$('#toolTip').show();
		$('#toolTip').html(text);

		$('#toolTip').css(
		{
			'position': 'absolute',
			'top' : position.top,
			'left': position.left + 8
		});

		if ($('#toolTip').width() > 300)
		{
			$('#toolTip').css ({'width': '300px'});
		}
	}

	//look in the table component if this is not working here this._AfterDomInjection = function()
	$('.showContextualHelp').on ('mouseenter', function(e)
	{
		var global = $(this).attr ('id');
		//here we need to bind a mouse out
		var globalText = $(this).attr ('helpText');
		$('#' + global).fadeTo ("fast", "1");
		ToolTip (this, globalText);
	});

	$('.showContextualHelp').on ('mouseleave', function(e)
	{
		var global = $(this).attr ('id');
		$('#toolTip').hide();
		$('#' + global).fadeTo ("fast", "0.4");
	});
}



$(function()
{
	$('body').append ("<span id='toolTip'style='display:none;'></span>");

	$(".pageHelpContent").on ('mousedown mouseup', function(e)
		{
			e.stopPropagation();
		}
	);

	function DrawHelpModal (buttonOffset, helpText, buttonId)
	{
		$('.pageHelpContentHtml').html (helpText);

		//here we need to append to the content of the help the overview sections
		var displayOverviewSection = true;
		for (var overviewNonDisplayArrayIndex = 0; overviewNonDisplayArrayIndex < overviewNonDisplayIds.length; ++overviewNonDisplayArrayIndex)
		{
			if (overviewNonDisplayIds[overviewNonDisplayArrayIndex] == buttonId)
			{
				displayOverviewSection = false;
			}
		}

		if (displayOverviewSection == true)
		{
			function CurrentOverviewList()
			{
				var str = '<hr color = "lightgrey">&nbsp;<font style="font-size:11px">Overviews:';
				str += '&nbsp;<font color="blue"><u><a onclick="overViewClick (\'projects\')" class="overViewList">Projects</a></u></font>';
				return str;
			}

			var overviewStr = CurrentOverviewList();
			$('#pageHelpFooterOverViews').html(overviewStr);
		}

		$(this).Modal ('#pageHelpModal', '#pageHelpXButton', {hideBackground: true});
		var helpModalWidth = parseInt($('.pageHelpModal').css ("width"), 10);
		$('.pageHelpModal').offset ({top: (buttonOffset.top + 16), left: (buttonOffset.left - helpModalWidth)});

		//one last thing cause of z-index problems in the Discard changes
		if ($('#leaveform,#changeformsave').is(":visible"))
		{
			//change the help forms z-index: 10004
			$('.pageHelpModal').css ("z-index", "10004");
		}
	}

	function ClearUpjQueryselectorForHelp (id)
	{
		return id.replace(/([;&,.+*~':"!^#$%@[\]()=>|])/g, '\\$1');
	}

	var showHelpClicked = function (button)
	{
		var global = $(button).attr('id');
		global = ClearUpjQueryselectorForHelp (global);
		var buttonOffset = $(button).offset();


		$('#' + global).addClass('throbberLoading');
		$('#' + global).fadeTo("fast", "0.4").attr('disabled', 'disabled');
		$('#' + global).Throbber(
		{
			display: "position:over",
			elementId: global,
			delay: 100,
			offsetTop: -2,
			offsetSides: 0,
			image: require ('./spinner.gif').default,
		});

		var helpTextRequestDispatcher = new RequestDispatcher(
		{
			type: "POST",
			url: "/platform/help/help/ShowPageHelp",
			data: {
				showType: "json",
				helpButtonId: $(button).attr('id'),
				sessionId: window.sessionId
			},
			success: function (data, textStatus, XMLHttpRequest)
			{
				var error = new StriataError();
				error.PopulateFromJson (data);
				$('#' + global).removeClass ('throbberLoading');

				if (error.numErrors > 0)
				{
					error.DisplayErrorMessageOnElement (global, false);
					return false;
				}

				$('#' + global).fadeTo("fast", "1").prop ('disabled', false).ThrobberHide();
				DrawHelpModal (buttonOffset, data.helpText, $(button).attr('id'));
				BindEventsForGlossary();
			},
			error: function (data)
			{
				var error = new StriataError();
				error.PopulateFromJson (data);
				error.DisplayErrorMessageOnElement (global, false);

				$('#' + global).fadeTo("fast", "1").prop ('disabled', false).ThrobberHide();
				$('#' + global).removeClass('throbberLoading');
			}
		});
	};

	$('.showPageHelp').on ({
		click: function()
		{
			showHelpClicked ($(this));
		}
	});

	PrepareContextualHelp();
});

// Export
module.exports.SetPageHelpId = SetPageHelpId;
module.exports.PrepareContextualHelp = PrepareContextualHelp;
