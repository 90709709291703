function CookieJar (name, accessPath, expires)
{
	var i, j;
	this.name = name;
	this.fieldSeparator = "&";
	this.found = false;
	this.expires = expires;
	this.accessPath = accessPath;
	this.rawValue = "";
	this.fields = new Array();
	this.fieldnames = new Array();
	if (arguments.length > 3)
	{
		j = 0;
		for (i = 3; i < arguments.length; i++)
		{
			this.fieldnames[j] = arguments[i];
			j++;
		}
		this.fields.length = this.fieldnames.length;
	}
	this.Read = _Read;
	this.Write = _Write;
	this.Remove = _Delete;
	this.Get = _FieldGet;
	this.Put = _FieldPut;
	this.FieldPosition = _NamePos;
	this.Read();
}


function _FieldGet(fieldname)
{
	var i = this.FieldPosition(fieldname);
	if (i >=0)
	{
		return this.fields[i];
	}

	return null;
}

function _FieldPut(fieldname, fieldval)
{
	var i = this.FieldPosition(fieldname);
	if (i >=0)
	{
		this.fields[i] = fieldval;
		return true;
	}

	this.fieldnames.push (fieldname);
	this.fields.push (fieldval);
	return false;
}

function _NamePos(fieldname)
{
	var i;
	for (i = 0; i < this.fieldnames.length; i++)
	{
		if (fieldname == this.fieldnames[i])
		{
			return i;
		}
	}
	return -1;
}


function _Write()
{
	var cookieText = this.name + "=";
	if (this.fieldnames.length == 0)
	{
		cookieText += encodeURIComponent(this.fields[0]);
	}
	else
	{
		for (var i= 0; i < this.fieldnames.length; i++)
		{
			var thisField = '';
			if (i > 0)
			{
				thisField = this.fieldSeparator;
			}
			thisField += encodeURIComponent(this.fieldnames[i]) + this.fieldSeparator +
				encodeURIComponent(this.fields[i]);
			cookieText = cookieText + thisField;
		}
	}

	// expiry parameter
	if (this.expires || typeof (this.expires) == "number")
	{
		var expires;

		if (typeof (this.expires) == "number")
		{
			// Expiry period in days specified
			var today =new Date();
			var expiredate = new Date();
			expiredate.setTime (today.getTime() + 1000*60*60*24*this.expires);
			expires = expiredate.toGMTString();
		}
		else
		{
			// assume it's a date object
			expires = this.expires.toGMTString();
		}

		cookieText +=  "; expires=" + expires;
	}

	// add path, if specified
	if (this.accessPath)
	{
		cookieText += "; path="+this.accessPath;
	}

	// write cookie
	document.cookie = cookieText;
	return null;
}


function _Read()
{
	var search = this.name + "=";
	var cookieString = document.cookie;
	this.rawValue = null;
	this.found = false;
	if (cookieString.length > 0)
	{
		var offset = cookieString.indexOf(search);
		if (offset != -1)
		{
			offset += search.length;
			var end = cookieString.indexOf(";", offset);
			if (end == -1)
			{
				end = cookieString.length;
			}
			this.rawValue = cookieString.substring(offset, end);
			this.found = true;
		}
	}

	if (this.rawValue !== null)
	{
		var i = 0;

		if (this.rawValue.indexOf(this.fieldSeparator) == -1)
		{
			this.fields[0] = decodeURIComponent(this.rawValue);
		}
		else
		{
			var fieldNameValues = new Array();
			fieldNameValues = this.rawValue.split(this.fieldSeparator);
			for (i = 0; i < fieldNameValues.length; i+=2)
			{
				this.Put (decodeURIComponent(fieldNameValues[i]), decodeURIComponent(fieldNameValues[i+1]));
			}
		}
	}

	return this.found;
}


function _Delete()
{
	this.expires = -10;
	this.Write();
	return this.Read();
}


// Export
module.exports = CookieJar;

