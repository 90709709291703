
// Import
var $ = require ('striata-jquery');
var StriataError = require ('@control/error/striataerror').StriataError;
var FormInteractionManager = require ('@control/forminteractionmanager/forminteractionmanager');
var FormGenerator = require ('@control/form/formgenerator').FormGenerator;
var NoticeManager = require ('@control/noticemanager/noticemanager');
var ConvertDateStringToTimeStamp = require ('@control/date/date').ConvertDateStringToTimeStamp;
var ConfirmationModal = require ('@project/common/confirmationmodal/confirmationmodal');
var RegisterComponent = require ('@utility/component/registercomponent').RegisterComponent;

require ('./generateforms.css');

function GenerateForms (options)
{
	var formOptions = {
		data             : [],
		parent           : null,
		theme            : "page",
		phrases          :
		{
			save           : "",
			systemError    : "",
			discardChanges : "",
			futureDate     : ""
		},
		postUrl          : "",
		postData         : {},
		readonly         : false,
		successCallBack  : function(){},
		errorCallBack    : function(){},
		mutationCallback : function(){}
	};

	Object.assign (formOptions, options);

	var formData = formOptions.data || [];
	var generatedForm;
	var generatedForms = {};
	var currentFormContent;
	var hiddenSections = [];
	var baseClass = "headerizerHeading";
	var parentElement = formOptions.parent;

	for (let field in formData)
	{
		if (formData[field].type == "section")
		{
			if (formData[field].postUrl)
			{
				formOptions.postUrl = formData[field].postUrl;
			}

			if (generatedForm)
			{
				Generate (generatedForm, formData, formOptions);
			}

			parentElement = formData[field].parent ? formData[field].parent : parentElement;

			var formContainer = document.createElement ("div");
			formContainer.id = formData[field].name + "Container";
			formContainer.setAttribute ("name", formData[field].name + "Container");

			var formContent = document.createElement ("div");
			formContent.id = formData[field].name + "Content";
			formContent.name = formData[field].name + "Content";

			formContent.className = "generatedFormContainer";

			if (formOptions.theme == "modal")
			{
				formContent.classList.add ("light");
				baseClass = formData[field].baseClass || "headerizerHeadingLight";
			}
			else if (formOptions.theme == "page")
			{
				baseClass = formData[field].baseClass || "headerizerHeading";
			}
			else
			{
				baseClass = formData[field].baseClass || formOptions.baseClass;
			}

			if (formData[field].cssStyle)
			{
				for (let cssRule in formData[field].cssStyle)
				{
					if (formContainer)
					{
						formContainer.style[cssRule] = formData[field].cssStyle[cssRule];
					}
				}
			}

			formContainer.appendChild (formContent);
			parentElement.appendChild (formContainer);
			currentFormContent = formContent;

			$("#" + formContainer.id).headerizer (
			{
				heading           : formData[field].label,
				baseClass         : baseClass,
				controlBarContent : formData[field].controlBarContent
			});

			if (formData[field].conditions)
			{
				hiddenSections.push (
				{
					section     : formContainer,
					conditions  : formData[field].conditions
				});
			}

			var formParent = formData[field].parentId || formData[field].name + "Content";
			var useForm = formData[field].useForm || true;

			if (!formData[field].postUrl)
			{
				useForm = false;
			}

			generatedForm = new FormGenerator (
			{
				allFields : [],
				name     : formData[field].name,
				parentId : formParent,
				useForm  : useForm,
				theme    : formOptions.theme
			});
		}
		else if (formData[field].type == "submit")
		{
			generatedForm.formButtons = [];

			generatedForm["formButtons"].push ({
				name          : formData[field].name,
				value         : formData[field].value,
				buttonState   : formData[field].buttonState,
				buttonGroup   : formData[field].buttonGroup,
				extraPostData : formData[field].extraPostData,
				attributes    : formData[field].attributes
			});
		}
		else if (formData[field].type == "panel")
		{
			currentFormContent = generatedForm.panels[generatedForm.panels.length - 1];

			$(currentFormContent).headerizer ({heading: formData[field].label, baseClass: 'headerizerHeadingSubHeading'});

			generatedForm.AddPanel (
			{
				name     : generatedForm.formAttributes.name,
				parentId : generatedForm.formAttributes.name + "Content",
				theme    : formOptions.theme

			}, generatedForm.panels);
		}
		else if (formData[field].type == "component")
		{
			currentFormContent = generatedForm.panels[generatedForm.panels.length - 1];

			while (currentFormContent.firstChild)
			{
				currentFormContent.removeChild (currentFormContent.firstChild);
			}

			RegisterComponent (document.body.querySelectorAll ("[name='" + formData[field].name + "']")[0],
			{
				parentElement: generatedForm.formAttributes.parentId
			});

			if (formData[field].value)
			{
				if (!window.pageStash.componentValues)
				{
					window.pageStash.componentValues = {};
				}
				Object.assign (window.pageStash.componentValues, formData[field].value);
			}
		}
		else if (formData[field].type == "confirm")
		{
			for (let condition in Object.keys (formData[field].conditions))
			{
				var requiredFieldName = Object.keys (formData[field].conditions)[condition];
				var requiredFields = generatedForm.formElement.querySelectorAll ("[name='" + requiredFieldName + "']");

				for (let requiredField in requiredFields)
				{
					var fieldInitialValue;
					var fieldName;

					if (requiredFields[requiredField] && requiredFields[requiredField].nodeName)
					{
						requiredFields[requiredField].onmousedown = function (event)
						{
							if (event.target.hasAttribute ("type"))
							{
								if (event.target.getAttribute ("type") == "radio")
								{
									fieldName = event.target.getAttribute ("name");
									fieldInitialValue = document.querySelector ("input[name='" + fieldName + "']:checked").value;
								}
							}
							else
							{
								fieldName = event.target.getAttribute ("name");
								fieldInitialValue = event.target.value;
							}
						};

						requiredFields[requiredField].onchange = function (event)
						{
							if (event.target.value)
							{
								ShowConfirmDialog (fieldName, fieldInitialValue);
							}
						};
					}
				}
			}

			function ShowConfirmDialog (fieldName, fieldInitialValue)
			{
				for (field in formData)
				{
					if (formData[field].type == "confirm")
					{
						var displayConfirmation = true;
						var confirmedState = formData[field].setState;
						var isValidElement = true;
						var setState;

						if (confirmedState)
						{
							for (state in Object.keys (confirmedState))
							{
								if (Object.keys (confirmedState)[state] == fieldName)
								{
									isValidElement = false;
									break;
								}
							}
						}

						if (isValidElement)
						{
							for (condition in Object.keys (formData[field].conditions))
							{
								var requiredFieldName = Object.keys (formData[field].conditions)[condition];
								setState = confirmedState;

								var selectedValue;

								if (document.querySelector ("[name='" + requiredFieldName + "']").hasAttribute ("type"))
								{
									if (document.querySelector ("[name='" + requiredFieldName + "']").getAttribute ("type") == "radio")
									{
										selectedValue = document.querySelector ("input[name='" + requiredFieldName + "']:checked").value;
									}
								}
								else
								{
									selectedValue = document.querySelector ("[name='" + requiredFieldName + "']").value;
								}

								var requiredValue = formData[field].conditions[Object.keys (formData[field].conditions)[condition]];

								if (Array.isArray (requiredValue))
								{
									if (!requiredValue.includes (selectedValue))
									{
										displayConfirmation = false;
									}
								}
								else
								{
									if (selectedValue != requiredValue)
									{
										displayConfirmation = false;
									}
								}
							}

							if (displayConfirmation)
							{
								var confirmModal = new ConfirmationModal (
								{
									id      : "confirmModal",
									heading : formData[field].title,
									icon    : require ('./alertlarge.png').default,
									width   : "600px",
									type    : "message"
								});


								var confirmModalNotice = new NoticeManager (
								{
									id       : "confirmModalNotice",
									name     : "confirmModalNotice",
									iconSrc  : require ('./info.png').default,
									parentId : "confirmModalNoticeContainer",
									theme    : "information",
									phrases  :
									{
										headingText : formData[field].message.heading + ":",
										messageText : formData[field].message.text
									}
								});

								confirmModalNotice.DrawMessage();

								confirmModal.AddButton (
								{
									theme    : "groupButton",
									id       : 'modalConfirm',
									value    : formData[field].phrases.confirm,
									enabled  : true,
									callback : function()
									{
										for (let state in Object.keys (setState))
										{
											var stateFieldName = Object.keys (setState)[state];
											var stateFieldValue = setState[Object.keys (setState)[state]];

											if (document.querySelector ("[name='" + stateFieldName + "']").hasAttribute ("type"))
											{
												if (document.querySelector ("[name='" + stateFieldName + "']").getAttribute ("type") == "radio")
												{
													for (let radio in document.querySelectorAll ("[name='" + stateFieldName + "']"))
													{
														if (document.querySelectorAll ("[name='" + stateFieldName + "']")[radio].value == stateFieldValue)
														{
															document.querySelectorAll ("[name='" + stateFieldName + "']")[radio].click();
														}
													}
												}
											}
											else
											{
												document.querySelector ("[name='" + stateFieldName + "']").value = stateFieldValue;
											}
										}

										$(this).CloseModalWindow ("#confirmModal");
									}
								});

								confirmModal.AddButton (
								{
									theme    : "groupButton",
									id       : 'confirmModalCloseCancel',
									value    : formData[field].phrases.cancel,
									enabled  : true,
									callback : function()
									{
										if (document.querySelector ("[name='" + fieldName + "']").hasAttribute ("type"))
										{
											if (document.querySelector ("[name='" + fieldName + "']").getAttribute ("type") == "radio")
											{
												for (let radio in document.querySelectorAll ("[name='" + fieldName + "']"))
												{
													if (document.querySelectorAll ("[name='" + fieldName + "']")[radio].value == fieldInitialValue)
													{
														document.querySelectorAll ("[name='" + fieldName + "']")[radio].click();
													}
												}
											}
										}
										else
										{
											document.querySelector ("[name='" + fieldName + "']").value = fieldInitialValue;
										}

										$(this).CloseModalWindow ("#confirmModal");
									}
								});
							}
						}
					}
				}
			}
		}
		else
		{
			var phrases = $.extend (formData[field].phrases, formOptions.phrases);
			var parentElementId = currentFormContent.id ? currentFormContent.id : parentElement;
			var formElementValue = $('<textarea />').html (formData[field].value).text();

			var fieldConfiguration = {
				id                  : formData[field].id || formData[field].name,
				name                : formData[field].name,
				value               : formElementValue,
				inlineGroup         : formData[field].inlineGroup,
				buttonGroup         : formData[field].buttonGroup,
				type                : formData[field].type,
				validationRule      : formData[field].validationRule || "",
				validationErrorText : formData[field].validationErrorText,
				allowedExtensions   : formData[field].allowedExtensions,
				buttonState         : formData[field].buttonState,
				selectOptions       : formData[field].selectOptions,
				description         : formData[field].label,
				placeholderText     : formData[field].placeholderText,
				helpText            : formData[field].helpText,
				disabled            : formOptions.readonly ? true : formData[field].disabled || false,
				hideDisabled        : formData[field].hideDisabled || false,
				cssStyle            : formData[field].cssStyle,
				attributes          : formData[field].attributes,
				conditions          : formData[field].conditions,
				manditory           : formData[field].required || false,
				parentId            : parentElementId,
				parent              : parentElement,
				greedy              : formData[field].greedy || false,
				hidable             : formData[field].hidable || false,
				options             : formData[field].options || [],
				hideEditButton      : formData[field].hideEditButton,
				extraPostData       : formData[field].extraPostData,
				inputOptions        : formData[field].inputOptions,
				phrases             : phrases,
				successCallBack     : formData[field].successCallBack || function(){},
				changeCallBack      : formData[field].changeCallBack || function(){},
				onLoad              : formData[field].onLoad || function(){}
			};

			if (Array.isArray (formData[field].value))
			{
				for (let index in formData[field].value)
				{
					var selected = false;

					if (Array.isArray (formData[field].selectedValues))
					{
						for (let selection in formData[field].selectedValues)
						{
							if (formData[field].selectedValues[selection] && formData[field].selectedValues[selection] == formData[field].value[index].constant)
							{
								selected = true;
							}
						}
					}
					else
					{
						if ((formData[field].selectedValue || formData[field].selectedValue == 0) && formData[field].selectedValue == formData[field].value[index].constant)
						{
							selected = true;
						}
					}

					var valueObject = {
						value    : formData[field].value[index].constant,
						label    : formData[field].value[index].text,
						disabled : formOptions.readonly || formData[field].value[index].disabled,
						selected : selected
					};

					fieldConfiguration.options.push (valueObject);
				}
			}
			else
			{
				if (formData[field].selectedValue == formData[field].value && Array.isArray (fieldConfiguration.options))
				{
					valueObject = {
						selected : true
					};

					fieldConfiguration.options.push (valueObject);
				}
			}

			generatedForm.AddField (fieldConfiguration);
		}
	}

	Generate (generatedForm, formData, formOptions);
	HandleHiddenSections (hiddenSections, formOptions);

	function ParseFormElement (postData, formElement)
	{
		var elementName = formElement.name || formElement.getAttribute ("name");
		var elementValue = formElement.value || formElement.getAttribute ("value");

		if (formElement.nodeName && elementName && elementValue)
		{
			var elementType = formElement.getAttribute ("type");

			if (formElement.getAttribute ("calendar") == "true")
			{
				var calendarValue = elementValue;

				if (formElement.hasAttribute ("allowsubst") && calendarValue.match (/^\s*((\$subst\('.+?'\)))/g))
				{
					postData[elementName] = calendarValue;
				}
				else
				{
					try
					{
						postData[elementName] = ConvertDateStringToTimeStamp (calendarValue);
					}
					catch (error)
					{
						return error;
					}
				}
			}
			else if (elementType == "radio")
			{
				if (formElement.checked == true)
				{
					postData[elementName] = elementValue;
				}
			}
			else if (elementType == "checkbox")
			{
				postData[elementName] = formElement.checked;
			}
			else if (elementType == "tags")
			{
				var tagName = elementName;
				if (formElement.getAttribute ("tagname")){ tagName = formElement.getAttribute ("tagname") }

				postData.tagValues = {
					name  : tagName,
					value : formElement.getAttribute ("value")
				};
			}
			else if (elementType != "submit")
			{
				postData[elementName] = elementValue;
			}
		}
		else if (formElement.nodeName == "SELECT" && elementName)
		{
			postData[elementName] = formElement.options[formElement.selectedIndex].value;
		}

		return postData;
	}



	function Generate (generatedForm, formData, formOptions)
	{
		if (generatedForm)
		{
			var postUrl = undefined;
			var buttonGroup = undefined;

			for (let i = 0; i < formData.length; ++i)
			{
				if (formData[i].name == generatedForm.formAttributes.name)
				{
					if (formData[i].postUrl)
					{
						postUrl = formData[i].postUrl;
						buttonGroup = formData[i].buttonGroup;
					}
				}
			}

			currentFormContent = generatedForm.panels[generatedForm.panels.length - 1];

			if (postUrl)
			{
				var generatedSubmitButton = generatedForm.AddField (
				{
					id          : generatedForm.formAttributes.name + "SubmitButton",
					name        : generatedForm.formAttributes.name + "SubmitButton",
					buttonGroup : buttonGroup,
					parentId    : currentFormContent.id,
					attributes  : generatedForm.formAttributes.attributes,
					type        : "submit",
					value       : formOptions.phrases.save
				});

				BindClickEvent (generatedSubmitButton);

				for (let button in generatedForm.formButtons)
				{
					var addSubmitButton = generatedForm.AddField (
					{
						id          : generatedForm.formButtons[button].name + "SubmitButton",
						name        : generatedForm.formButtons[button].name + "SubmitButton",
						buttonState : generatedForm.formButtons[button].buttonState,
						buttonGroup : generatedForm.formButtons[button].buttonGroup,
						parentId    : currentFormContent.id,
						attributes  : generatedForm.formButtons[button].attributes,
						type        : "submit",
						value       : generatedForm.formButtons[button].value,
					});

					BindClickEvent (addSubmitButton, generatedForm.formButtons[button].extraPostData);
				}

				function BindClickEvent (submitButton, extraPostData)
				{
					submitButton.onclick = function (event)
					{
						var submitForm = document.querySelector ("form[id='" + generatedForm.formAttributes.name + "']");
						var canSubmitForm = true;
						var parsedFormData = {};

						var submitFormElements = Array.from (submitForm.querySelectorAll ("[name]"));

						for (let elementValue in submitFormElements)
						{
							if (submitFormElements[elementValue] instanceof HTMLCollection)
							{
								for (var index = 0; index < submitFormElements[elementValue].length; ++index)
								{
									parsedFormData = ParseFormElement (formOptions.postData, submitFormElements[elementValue][index]);
								}
							}
							else if (submitFormElements[elementValue] instanceof NodeList)
							{
								for (index = 0; index < submitFormElements[elementValue].length; ++index)
								{
									parsedFormData = ParseFormElement (formOptions.postData, submitFormElements[elementValue][index]);
								}
							}

							parsedFormData = ParseFormElement (formOptions.postData, submitFormElements[elementValue]);
						}

						var tagValues = parsedFormData.tagValues;

						var submitFormData = $(submitForm).serializeArray().reduce (function (object, item)
						{
							if (document.getElementsByName (item.name)[0].getAttribute ("type") == "checkbox")
							{
								var elementValue = document.getElementsByName (item.name)[0].getAttribute ("value");
								item.value = item.value || item.value == "true" || item.value == "on" ? elementValue ? elementValue : "true" : "false";
							}

							object[item.name] = item.value;
							return object;
						}, {});

						var postData = {...parsedFormData, ...submitFormData};

						if (tagValues)
						{
							postData[tagValues.name] = tagValues.value;
							if (tagValues.name != "tagValues"){ delete postData.tagValues }
						}

						var thisPostData = {};
						thisPostData = Object.assign (thisPostData, postData, extraPostData);

						if (formOptions.beforePost)
						{
							var beforePostResult = formOptions.beforePost (thisPostData);

							if (beforePostResult === false)
							{
								canSubmitForm = false;
							}
						}

						if (canSubmitForm)
						{
							formDetails.SubmitForm (
							{
								data        : thisPostData,
								submitEvent : event,
								success     : function (responseData)
								{
									$.fn.ThrobberHide();

									var error = new StriataError();
									error.PopulateFromJson (responseData);

									var handleAsWarning = false;

									if (error.errorMessages.length == 1 && error.errorMessages[0].errorCode == "-3373") // file size warning.
									{
										handleAsWarning = true;
									}

									if (error.numErrors > 0 && !handleAsWarning)
									{
										formOptions.errorCallBack (responseData);
									}
									else
									{
										formOptions.successCallBack (responseData, postData);
									}
								},
								error       : function (responseData)
								{
									$.fn.ThrobberHide();

									var error = new StriataError();
									error.PopulateFromJson (responseData);
									formOptions.errorCallBack (responseData);
								}
							});
						}
					};
				}
			}

			generatedForm.Generate();

			var formDetails = new FormInteractionManager (
			{
				hasEditPermission       : 1,
				enableButtonStateChange : true,
				formId                  : generatedForm.formElement.id,
				formSubmitButtonId      : generatedForm.formAttributes.name + "SubmitButton",
				notificationContainerId : generatedForm.formAttributes.name + "SubmitButtonNotification",
				systemErrorId           : generatedForm.formAttributes.name + "RequestFailure",
				systemErrorPhrase       : formOptions.phrases.systemError,
				discardPhrase           : formOptions.phrases.discardChanges,
				postUrl                 : postUrl
			});

			if (generatedForm.formAttributes.useXhr == false)
			{
				formDetails.SetUseIframe (true);
			}
			else
			{
				formDetails.SetUseIframe (false);
			}
			generatedForm.formOptions = formOptions;
			if (!generatedForm.formInteractionManager)
			{
				generatedForm.formInteractionManager = [formDetails];
			}
			else
			{
				generatedForm.formInteractionmanager.push (formDetails);
			}

			generatedForms[generatedForm.formElement.id] = generatedForm;
		}
	}

	if (formOptions.init)
	{
		formOptions.init (options);
	}

	return generatedForms;
}

function HandleHiddenSections (hiddenSections, options)
{
	for (let sectionIndex in hiddenSections)
	{
		var conditions = Object.keys (hiddenSections[sectionIndex].conditions);

		for (let conditionIndex in conditions)
		{
			var requiredElements = document.querySelectorAll ("[name='" + conditions[conditionIndex] + "']");

			for (let requiredElement in requiredElements)
			{
				if (requiredElements[requiredElement].nodeName)
				{
					HideSections (
					{
						name           : requiredElements[requiredElement].name,
						value          : requiredElements[requiredElement].value,
						parentElement  : hiddenSections[sectionIndex].section,
						options        : options,
						hiddenSections : hiddenSections
					});

					requiredElements[requiredElement].onchange = function (event)
					{
						HideSections (
						{
							name           : event.target.name,
							value          : event.target.value,
							parentElement  : hiddenSections[sectionIndex].section,
							options        : options,
							hiddenSections : hiddenSections
						});
					};

					function HideSectionsObserverCallBack (mutationsList)
					{
						for (const mutation of mutationsList)
						{
							if (mutation.type === 'childList' || mutation.type === 'attributes')
							{
								HideSections (
								{
									name           : mutation.target.name,
									value          : mutation.target.value,
									parentElement  : hiddenSections[sectionIndex].section,
									options        : options,
									hiddenSections : hiddenSections
								});
							}
						}
					}

					var HideSectionsObserver = new MutationObserver (HideSectionsObserverCallBack);

					HideSectionsObserver.observe (requiredElements[requiredElement], {attributes: true, childList: true, subtree: true});
				}
			}
		}
	}

	function HideSections (object)
	{
		var requiredObject = {
			name           : null,
			value          : null,
			parentElement  : null,
			options        : {},
			hiddenSections : hiddenSections
		};

		$.extend (requiredObject, object);

		var selectedElement;
		var requiredElement = requiredObject.options.parent.querySelector ("input[name='" + requiredObject.name + "']");

		if (requiredElement && requiredElement.type == "radio")
		{
			selectedElement = requiredObject.options.parent.querySelector ("input[name='" + requiredObject.name + "']:checked");
		}
		else
		{
			selectedElement = requiredElement;
		}

		if (selectedElement)
		{
			for (var sectionIndex in requiredObject.hiddenSections)
			{
				var section = requiredObject.hiddenSections[sectionIndex].section;

				var hideConditionValue = requiredObject.hiddenSections[sectionIndex].conditions[requiredObject.name];
				var nameMatches = requiredObject.name == selectedElement.name;
				var valueMatches = hideConditionValue == selectedElement.value || false;

				if (!valueMatches && Array.isArray (hideConditionValue))
				{
					for (let valueIndex in hideConditionValue)
					{
						if (hideConditionValue[valueIndex] == selectedElement.value && valueMatches == false)
						{
							valueMatches = hideConditionValue[valueIndex] == selectedElement.value;
						}
					}
				}

				if (section)
				{
					if (!section.nodeType)
					{
						section = document.querySelectorAll ("#" + section)[0];
					}
				}
				else
				{
					section = document;
				}

				if (section)
				{
					if (valueMatches && nameMatches)
					{
						section.style.display = "block";
					}
					else
					{
						section.style.display = "none";
					}
				}
			}
		}

		if (requiredObject.options.mutationCallback) { requiredObject.options.mutationCallback() }
	}
}

function ReplaceRecurrentFormElements (formData, lookupKeyword, replacementObject)
{
	for (let formDataIndex = 0; formDataIndex < formData.length; ++formDataIndex)
	{
		if (formData[formDataIndex].type == lookupKeyword)
		{
			var generateElementType = replacementObject.type;
			replacementObject = {...replacementObject, ...formData[formDataIndex]};
			replacementObject.type = generateElementType;
			formData[formDataIndex] = replacementObject;
		}
	}

	return formData;
}



// Export
module.exports = GenerateForms;
module.exports.ReplaceRecurrentFormElements = ReplaceRecurrentFormElements;
module.exports.HandleHiddenSections = HandleHiddenSections;
